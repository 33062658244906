import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/ReferenceBrandType';

export interface TireBrandType {
  brandId: string;
  brand: ReferenceBrandType;
  name: string;
  image: string;
  selected: boolean;
  isDefaultBrand: boolean;
}

/**
 * For performance reason, keep the data sorted by attribute 'name'
 */
export const tireBrandsData: TireBrandType[] = [
  {
    brandId: 'PB21',
    name: 'BARUM',
    brand: 'BARUM' as ReferenceBrandType,
    image: 'tires/brands/barum.jpg',
    selected: false,
    isDefaultBrand: true,
  },
  {
    brandId: 'PB32',
    name: 'BF GOODRICH',
    brand: 'BF_GOODRICH' as ReferenceBrandType,
    image: 'tires/brands/bf-goodrich.jpg',
    selected: false,
    isDefaultBrand: false,
  },
  {
    brandId: 'PB24',
    name: 'BRIDGESTONE',
    brand: 'BRIDGESTONE' as ReferenceBrandType,
    image: 'tires/brands/bridgestone.jpg',
    selected: false,
    isDefaultBrand: true,
  },
  {
    brandId: 'PB19',
    name: 'CONTINENTAL',
    brand: 'CONTINENTAL' as ReferenceBrandType,
    image: 'tires/brands/continental.jpg',
    selected: false,
    isDefaultBrand: true,
  },
  {
    brandId: 'PB16',
    name: 'DUNLOP',
    brand: 'DUNLOP' as ReferenceBrandType,
    image: 'tires/brands/dunlop.jpg',
    selected: false,
    isDefaultBrand: true,
  },
  {
    brandId: 'PB25',
    name: 'FIRESTONE',
    brand: 'FIRESTONE' as ReferenceBrandType,
    image: 'tires/brands/firestone.jpg',
    selected: false,
    isDefaultBrand: true,
  },
  {
    brandId: 'PB39',
    name: 'FORMULA',
    brand: 'FORMULA' as ReferenceBrandType,
    image: 'tires/brands/formula.jpg',
    selected: false,
    isDefaultBrand: false,
  },
  {
    brandId: 'PB17',
    name: 'FULDA',
    brand: 'FULDA' as ReferenceBrandType,
    image: 'tires/brands/fulda.jpg',
    selected: false,
    isDefaultBrand: true,
  },
  {
    brandId: 'PB48',
    name: 'GITI',
    brand: 'GITI' as ReferenceBrandType,
    image: 'tires/brands/giti.jpg',
    selected: false,
    isDefaultBrand: false,
  },
  {
    brandId: 'PB15',
    name: 'GOODYEAR',
    brand: 'GOODYEAR' as ReferenceBrandType,
    image: 'tires/brands/goodyear.jpg',
    selected: false,
    isDefaultBrand: true,
  },
  {
    brandId: 'PB49',
    name: 'GT RADIAL',
    brand: 'GT_RADIAL' as ReferenceBrandType,
    image: 'tires/brands/gt-radial.jpg',
    selected: false,
    isDefaultBrand: false,
  },
  {
    brandId: 'PB27',
    name: 'HANKOOK',
    brand: 'HANKOOK' as ReferenceBrandType,
    image: 'tires/brands/hankook.jpg',
    selected: false,
    isDefaultBrand: false,
  },
  {
    brandId: 'PB13',
    name: 'KLEBER',
    brand: 'KLEBER' as ReferenceBrandType,
    image: 'tires/brands/kleber.jpg',
    selected: false,
    isDefaultBrand: true,
  },
  {
    brandId: 'PB14',
    name: 'KORMORAN',
    brand: 'KORMORAN' as ReferenceBrandType,
    image: 'tires/brands/kormoran.jpg',
    selected: false,
    isDefaultBrand: false,
  },
  {
    brandId: 'PB40',
    name: 'KUMHO',
    brand: 'KUMHO' as ReferenceBrandType,
    image: 'tires/brands/kumho.jpg',
    selected: false,
    isDefaultBrand: false,
  },
  {
    brandId: 'PB60',
    name: 'LEONARD',
    brand: 'LEONARD' as ReferenceBrandType,
    image: 'tires/brands/leonard.jpg',
    selected: false,
    isDefaultBrand: true,
  },
  {
    brandId: 'PB56',
    name: 'LINGLONG',
    brand: 'LINGLONG' as ReferenceBrandType,
    image: 'tires/brands/linglong.png',
    selected: false,
    isDefaultBrand: false,
  },
  {
    brandId: 'PB12',
    name: 'MICHELIN',
    brand: 'MICHELIN' as ReferenceBrandType,
    image: 'tires/brands/michelin.jpg',
    selected: false,
    isDefaultBrand: true,
  },
  {
    brandId: 'PB36',
    name: 'MOTRIO PNEU',
    brand: 'MOTRIO_PNEU' as ReferenceBrandType,
    image: 'tires/brands/motrio-pneu.jpg',
    selected: false,
    isDefaultBrand: true,
  },
  {
    brandId: 'PB50',
    name: 'NEXEN',
    brand: 'NEXEN' as ReferenceBrandType,
    image: 'tires/brands/nexen.jpg',
    selected: false,
    isDefaultBrand: false,
  },
  {
    brandId: 'PB51',
    name: 'NOKIAN',
    brand: 'NOKIAN' as ReferenceBrandType,
    image: 'tires/brands/nokian.jpg',
    selected: false,
    isDefaultBrand: false,
  },
  {
    brandId: 'PB22',
    name: 'PIRELLI',
    brand: 'PIRELLI' as ReferenceBrandType,
    image: 'tires/brands/pirelli.jpg',
    selected: false,
    isDefaultBrand: true,
  },
  {
    brandId: 'PB18',
    name: 'SAVA',
    brand: 'SAVA' as ReferenceBrandType,
    image: 'tires/brands/sava.jpg',
    selected: false,
    isDefaultBrand: true,
  },
  {
    brandId: 'PB52',
    name: 'TAURUS',
    brand: 'TAURUS' as ReferenceBrandType,
    image: 'tires/brands/taurus.jpg',
    selected: false,
    isDefaultBrand: false,
  },
  {
    brandId: 'PB20',
    name: 'UNIROYAL',
    brand: 'UNIROYAL' as ReferenceBrandType,
    image: 'tires/brands/uniroyal.jpg',
    selected: false,
    isDefaultBrand: true,
  },
  {
    brandId: 'PB53',
    name: 'VREDESTEIN',
    brand: 'VREDESTEIN' as ReferenceBrandType,
    image: 'tires/brands/vredestein.jpg',
    selected: false,
    isDefaultBrand: false,
  },
  {
    brandId: 'PB46',
    name: 'YOKOHAMA',
    brand: 'YOKOHAMA' as ReferenceBrandType,
    image: 'tires/brands/yokohama.jpg',
    selected: false,
    isDefaultBrand: false,
  },
];
