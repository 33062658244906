import React from 'react';
import { Table } from 'antd';
import { ManageCookiesButton } from 'components/Page/Footer/Footer.styled';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';

export const CookiesIT = () => {
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <Flex direction={'column'}>
          <MarginBox mt={15} />
          <Flex justify={'center'}>
            <Text type={'h5_bold'}>COOKIE POLICY</Text>
          </Flex>
          <MarginBox mt={30} />
        </Flex>
        <Paragraph>
          La protezione è una priorità per il Gruppo Renault. Il nostro sito web utilizza tecnologie come i cookie per
          offrire un’esperienza di qualità, fluida e personalizzata.
        </Paragraph>
        <Paragraph>
          Potete riconsiderare in qualsiasi momento le vostre scelte sui cookie utilizzando il nostro strumento di
          gestione dei cookie:{' '}
          <ManageCookiesButton $color={'brand_alt'}>
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              GESTISCI LE MIE SCELTE
            </button>
          </ManageCookiesButton>
        </Paragraph>
        <Section>
          <SectionHeader title={'1. CHI SIAMO?'} />
          <Paragraph>
            Benvenuti sul sito{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              {'https://rpartstore.renault.com'}
            </Link>
            , pubblicato da Renault SAS. Renault SAS è il titolare autonomo dei dati raccolti dai cookie di altri
            rilevatori letti o presenti sul vostro dispositivo (di seguito “noi”, “ci”, “nostro”).
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'2. COSA SONO I COOKIE?'} />
          <Paragraph>
            Un cookie è un piccolo file informatico, un rilevatore, presente all’interno del web e di cui si prende
            visione quando si visita il sito web, si utilizza un’applicazione mobile, si legge un’e-mail, si installa o
            si utilizza un software, indipendentemente dal tipo di terminale utilizzato (computer, smartphone, lettore
            digitale, console per videogiochi connessa a Internet, ecc.)
          </Paragraph>
          <Paragraph>
            Quando si consulta un sito web, i cookie possono inviare informazioni al browser dell’utente e consentire al
            browser di inviare informazioni al sito web originale (ad esempio, un ID di sessione o la scelta della
            lingua).
            <br />
            I cookie non ci permettono di identificare direttamente l’utente ma solo di raccogliere informazioni
            specifiche sul suo dispositivo, dal browser, di rilevare interazioni che l’utente stesso ha con il sito o
            con l’applicazione che sta utilizzando e, in alcuni casi, consente di localizzare la sua posizione
            geografica.
            <br />
            Le tecnologie utilizzate possono collegare diversi dispositivi che l’utente ha utilizzato per determinare,
            con sufficiente probabilità, che tali dispositivi appartengono all’utente stesso.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'3. PER COSA VENGONO UTILIZZATI I COOKIE IN QUESTO SITO WEB?'} />
          <Paragraph>Questo sito utilizza diversi tipi di cookie.</Paragraph>
          <SectionHeader title={'Cookie tecnici'} mb={0} />
          <Paragraph>
            I cookie tecnici hanno lo scopo di consentire o facilitare la comunicazione elettronica (rilevamento di
            errori di connessione, identificazione dei punti di connessione, sicurezza del sito web, ecc.) o di fornire
            servizi di comunicazione online eventualmente richiesti dall’utente (preferenze di visualizzazione dello
            schermo, salvataggio delle informazioni inserite nei moduli, carrello della spesa (se applicabile), accesso
            al proprio account utente, chat o chatbot sul nostro sito web, ecc.)
          </Paragraph>
          <Paragraph>I cookie tecnici sono necessari per il corretto funzionamento del nostro sito web.</Paragraph>
          <Paragraph>
            L’utente non può opporsi all’uso di questi cookie e il suo consenso non è necessario, poiché i servizi del
            nostro sito web dipendono da questi cookie.
          </Paragraph>
          <SectionHeader title={'Cookie analitici'} mb={0} />
          <Paragraph>
            I cookie analitici hanno lo scopo di analizzare il traffico del sito web e l’esperienza dell’utente o
            semplicemente di raccogliere segnali aggregati sul flusso delle visite al sito (ad esempio, la scelta della
            lingua, la pagina precedente visitata, l’orario della visita, la scelta dei cookie).
          </Paragraph>
          <Paragraph>
            Questi cookie possono elaborare dati relativi alle pagine visitate, al tempo trascorso sulle pagine
            visitate, al browser utilizzato per visitare tali pagine, all’utilizzo della chat o della chatbot sul nostro
            sito web, ecc.
          </Paragraph>
          <Paragraph>
            I dati raccolti dai cookie analitici possono essere utilizzati anche per creare algoritmi e profili per
            analizzare i vostri potenziali interessi e preferenze per i nostri prodotti e servizi. Potremmo combinare
            questi dati con altre informazioni, come quelle provenienti dal nostro database clienti.
          </Paragraph>
          <Paragraph>
            L’uso e la memorizzazione di questi cookie sono generalmente soggetti al consenso dell’utente.
          </Paragraph>
          <Paragraph>
            In determinate condizioni, questi cookie possono essere inseriti/letti senza il vostro consenso, ad esempio
            se lo scopo degli stessi è esclusivamente quello di analizzare i contenuti che avete visitato.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'4. QUALI COOKIE SONO UTILIZZATI IN QUESTO SITO WEB?'} />
          <Paragraph>
            La tabella allegata contiene l’elenco di tutti i cookie memorizzati e/o utilizzati da questo sito web e le
            loro caratteristiche principali. Questi cookie sono ospitati su server situati nello Spazio economico
            europeo (SEE) o nel Regno Unito. Tuttavia, poiché alcuni dei nostri fornitori di servizi sono situati in
            paesi al di fuori del SEE o del Regno Unito, i vostri dati personali vengono elaborati in questi paesi, che
            potrebbero avere normative sui dati personali diverse da quelle dell’Unione Europea o del Regno Unito. In
            tal caso, (i) prestiamo molta attenzione a garantire che questo trasferimento avvenga in conformità con la
            normativa applicabile e (ii) mettiamo in atto misure di salvaguardia che garantiscano un livello adeguato di
            protezione dei vostri dati (in particolare utilizzando le clausole contrattuali standard della Commissione
            Europea e/o qualsiasi clausola contrattuale standard del Regno Unito (a seconda dei casi)). Gli utenti
            potranno ottenere ulteriori dettagli su tali trasferimenti (in particolare, la lista dei paesi e le già
            menzionate Clausole contrattuali standard della Commissione Europea) inviando una richiesta scritta
            all’indirizzo e-mail{' '}
            <Link to={'mailto:dpo@renault.com'} external newTab>
              dpo@renault.com
            </Link>
            .
          </Paragraph>
          <Paragraph>
            Il nostro sito web può contenere cookie di terze parti (agenzie di comunicazione, servizi di analisi, ecc.)
            che consentono loro, durante la vita dei cookie, di raccogliere informazioni di navigazione relative al
            nostro sito web. La memorizzazione e l’utilizzo di questi cookie da parte di terzi sono soggetti alle
            politiche sulla privacy di tali terzi.Vi forniamo tutte le informazioni necessarie relative allo scopo di
            tali cookie presenti sul nostro sito web, nonché sui mezzi a vostra disposizione per effettuare scelte in
            merito a tali cookie.
          </Paragraph>
          <Table
            pagination={false}
            rowKey={(record) => record.id}
            columns={[
              {
                title: 'Categoria',
                dataIndex: 'guideline',
                key: 'guideline',
                render: (guideline) => (
                  <Link to={guideline} external newTab>
                    ISSUED BY RENAULT
                  </Link>
                ),
              },
              {
                title: 'Scopo',
                dataIndex: 'type',
                key: 'type',
              },
              { title: 'Proprietario del cookie', dataIndex: 'site', key: 'site' },
            ]}
            dataSource={[
              {
                id: 0,
                site: 'ONE TRUST',
                type: 'TECHNICAL',
                guideline: 'https://www.onetrust.com/it/cookie-policy/',
              },
              {
                id: 1,
                site: 'RENAULT',
                type: 'TECHNICAL',
                guideline: 'https://www.renault.fr/cookies.html',
              },
              {
                id: 2,
                site: 'Matomo',
                type: 'ANALYTICS',
                guideline: 'https://matomo.org/privacy-policy/',
              },
              {
                id: 3,
                site: 'DYNATRACE',
                type: 'ANALYTICS',
                guideline: 'https://www.dynatrace.com/company/trust-center/privacy/',
              },
              {
                id: 4,
                site: 'CONTENSQUARE',
                type: 'ANALYTICS',
                guideline: 'https://contentsquare.com/fr-fr/privacy-center/cookie-policy/',
              },
            ]}
          />
        </Section>
        <Section>
          <SectionHeader title={'5. COME SI OTTIENE IL CONSENSO?'} />
          <Paragraph>
            Quando visitate per la prima volta il nostro sito web, vi viene chiesto di acconsentire, rifiutare o fare
            determinate scelte sull’uso dei cookie, visualizzando un banner informativo dedicato.
          </Paragraph>
          <Paragraph>
            Potete revocare il vostro consenso in qualsiasi momento o modificare le vostre scelte (ad esempio accettando
            solo alcuni tipi di cookie rispetto ad altri) con il nostro strumento dedicato:{' '}
            <ManageCookiesButton $color={'brand_alt'}>
              <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                GESTISCI LE MIE SCELTE
              </button>
            </ManageCookiesButton>
          </Paragraph>
          <Paragraph>
            In conformità alla normativa applicabile, i cookie necessari al funzionamento del sito non sono soggetti al
            consenso dell’utente e non possono quindi essere disattivati nel nostro strumento{' '}
            <ManageCookiesButton $color={'brand_alt'}>
              <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                GESTISCI LE MIE SCELTE
              </button>
            </ManageCookiesButton>
            . È possibile impedirne l’utilizzo modificando le impostazioni del browser (seguendo la sezione “come
            rifiutare i cookie” di seguito), ma la qualità della vostra esperienza sul sito web potrebbe risentirne.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'6. PER QUANTO TEMPO VIENE CONSERVATO IL VOSTRO CONSENSO?'} />
          <Paragraph>
            Quando accettate o rifiutate tutti o parte dei cookie sul nostro sito web, la vostra scelta viene salvata e
            memorizzata (e noi terremo traccia delle vostre preferenze) per un periodo massimo di 6 mesi. Ciò significa
            che quando consulterete il nostro sito web in un secondo momento, durante questo periodo, non vi chiederemo
            alcuna azione da parte vostra in merito ai cookies per cui avete già effettuato una scelta, poiché le vostre
            preferenze saranno già state memorizzate.
          </Paragraph>
          <Paragraph>
            Al termine di questo periodo, vi chiederemo nuovamente di acconsentire o rifiutare l’uso dei cookies
            presenti sul nostro sito web.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'7. COME POTETE GESTIRE I COOKIE?'} />
          <Paragraph>
            <Text type={'text'} decoration={'underline'}>
              Come esercitare il diritto di opposizione?
            </Text>
            <br />
            <Text type={'text'}>
              Se non desiderate che i cookie vengano memorizzati o letti sul vostro dispositivo, un cookie di rifiuto
              verrà memorizzato sul vostro apparecchio per consentirci di registrare il fatto che vi siete opposti
              all’uso dei cookie. Se cancellate questo cookie di rifiuto, non saremo più in grado di riconoscere che ne
              avete rifiutato l’uso e la memorizzazione dei cookie.
            </Text>
          </Paragraph>
          <Paragraph>
            L’utente ha a disposizione diverse opzioni per gestire i cookie, tra cui il blocco del loro utilizzo e/o la
            loro cancellazione.
          </Paragraph>
          <Paragraph>
            <Text type={'text'} decoration={'underline'}>
              Gestione dei cookie
            </Text>
            <br />
            Per consentire all’utente di gestire i cookie memorizzati e/o utilizzati sul nostro sito, è disponibile un
            cookie manager. In qualsiasi momento, cliccando sul seguente{' '}
            <ManageCookiesButton $color={'brand_alt'}>
              <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                link
              </button>
            </ManageCookiesButton>
            , è possibile accedere al configuratore e modificare le proprie preferenze, a seconda del tipo di cookie in
            questione.
          </Paragraph>
          <Paragraph>
            <Text type={'text'} decoration={'underline'}>
              Impostazioni del browser
            </Text>
            <br />È possibile gestire i cookie anche tramite il browser.
          </Paragraph>
          <Paragraph>
            La maggior parte dei browser è impostata di default per accettare i cookie, ma se lo desiderate, potete
            scegliere di accettare tutti i cookie, rifiutarli sistematicamente o scegliere quali accettare a seconda
            della loro provenienza. È inoltre possibile eliminare regolarmente i cookie dal proprio dispositivo
            utilizzando il browser.
          </Paragraph>
          <Paragraph>
            Tuttavia, non dimenticate di configurare tutti i browser sui vostri vari dispositivi (tablet, smartphone,
            computer, ecc.).
          </Paragraph>
          <Paragraph align={'start'}>
            Ogni browser ha una configurazione diversa per la gestione dei cookie e delle vostre scelte. È descritta nel
            menu di aiuto del browser, che vi indicherà come modificare le impostazioni dei cookie:
            <Ul>
              <Li>
                <Text type={'text'}>
                  per Internet Explorer™:{' '}
                  <Link to={'http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies'} external newTab>
                    http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
                  </Link>{' '}
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  per Safari™:{' '}
                  <Link to={'https://support.apple.com/fr-fr/safari'} external newTab>
                    https://support.apple.com/fr-fr/safari
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  per Chrome™:{' '}
                  <Link to={'https://support.google.com/chrome/answer/95647?hl=en&hlrm=en'} external newTab>
                    https://support.google.com/chrome/answer/95647?hl=en&hlrm=en
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  per Firefox™:{' '}
                  <Link
                    to={'https://support.mozilla.org/en-US/kb/clear-cookies-and-website-data-single-domain-android'}
                    external
                    newTab
                  >
                    https://support.mozilla.org/en-US/kb/clear-cookies-and-website-data-single-domain-android
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  per Opera™:{' '}
                  <Link to={'http://help.opera.com/Windows/10.20/en/cookies.html'} external newTab>
                    http://help.opera.com/Windows/10.20/en/cookies.html
                  </Link>
                  .
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph align={'start'}>
            <Text type={'text'} decoration={'underline'}>
              Moduli degli editori per la formulazione di obiezioni
            </Text>
            <br />È possibile scegliere di disabilitare alcuni cookie di terze parti accedendo direttamente alla pagina
            dell’emittente del cookie. Come segue:
            <Ul>
              <Li>
                <Text type={'text'}>
                  per disabilitare i cookie di Google Analytics, andare alla pagina:{' '}
                  <Link to={'https://tools.google.com/dlpage/gaoptout?hl=fr'} external newTab>
                    https://tools.google.com/dlpage/gaoptout?hl=fr
                  </Link>
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  per disabilitare i cookie di intelliAd, visitare la pagina:{' '}
                  <Link to={'https://login.intelliad.com/optout.php'} external newTab>
                    https://login.intelliad.com/optout.php
                  </Link>
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  per disabilitare i cookie di Adobe, andare alla pagina:{' '}
                  <Link to={'https://www.adobe.com/uk/privacy/opt-out.html'} external newTab>
                    https://www.adobe.com/uk/privacy/opt-out.html
                  </Link>
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            <Text type={'text'} decoration={'underline'}>
              Piattaforme per obiezioni di marketing
            </Text>
            <br />
            Diverse piattaforme pubblicitarie professionali consentono anche di rifiutare o accettare i cookie
            utilizzati dalle aziende aderenti a tali piattaforme. Questi meccanismi centralizzati non impediscono la
            visualizzazione di annunci pubblicitari, ma bloccano solo l’installazione dei cookie che consentono di
            adattare gli annunci ai vostri interessi.
          </Paragraph>
          <Paragraph>
            Ad esempio, potete visitare il sito{' '}
            <Link to={'www.youronlinechoices.com'} external newTab>
              www.youronlinechoices.com
            </Link>{' '}
            per impedire l’installazione di cookie sul vostro dispositivo. Questo sito è ospitato dai professionisti
            della pubblicità digitale dell’associazione europea EDAA (European Digital Advertising Alliance) e gestito
            in Francia dall’Interactive Advertising Bureau France.
          </Paragraph>
          <Paragraph>
            Ciò consente di scoprire quali aziende sono registrate su questa piattaforma e che permettono di rifiutare o
            accettare i cookie utilizzati da queste aziende per adattare gli annunci che possono essere visualizzati sul
            vostro dispositivo in base alle informazioni del vostro browser:{' '}
            <Link to={'https://www.youronlinechoices.com/uk/your-ad-choices'} external newTab>
              https://www.youronlinechoices.com/uk/your-ad-choices
            </Link>
            .
          </Paragraph>
          <Paragraph>
            Questa piattaforma europea è condivisa da centinaia di professionisti della pubblicità su Internet e
            fornisce un’interfaccia centralizzata per rifiutare o accettare i cookie che possono essere utilizzati per
            personalizzare gli annunci che possono essere visualizzati sul dispositivo dell’utente in base alla sua
            esperienza di navigazione.{' '}
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'8. COME POTETE ESERCITARE GLI ALTRI DIRITTI?'} />
          <Paragraph>
            In conformità alla legislazione applicabile in materia di protezione dei dati personali, l’utente ha il
            diritto di accedere, rettificare, opporsi, limitare e cancellare i propri dati personali. Per esercitare gli
            altri diritti, l’utente può consultare la nostra informativa sulla protezione dei dati personali
            contattandoci al seguente indirizzo e-mail:{' '}
            <Link to={'mailto:dpo@renault.com'} external newTab>
              dpo@renault.com
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'9. MODIFICHE'} />
          <Paragraph>
            La presente Informativa può essere suscettibile di modifiche. Vi informeremo e/o chiederemo il vostro
            consenso ogni volta che sarà necessario o richiesto. Si consiglia pertanto di consultare la presente
            Informativa ogni volta che si visita il nostro sito web per prendere visione della versione più recente.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Ultimo aggiornamento: gennaio 2024</Text>
        </Flex>
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
