import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OrderReferenceItem } from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import { Bell } from 'assets/icons';
import { DocumentationAlertForm } from 'components/DocumentationAlertButton/DocumentaryAlertForm';
import {
  getEmailDocumentationAlertMotrioIsModalOpen,
  resetEmailDocumentationAlertMotrioData,
  setEmailDocumentationAlertMotrioModalIsOpen,
} from 'domains/email/Email.store';
import { DHReferenceLocal, IAMReferenceLocal } from 'domains/references';
import { BoxSizeType, IconButton, Modal, WithTooltip } from 'UI';

export const DocumentationAlertButton = ({
  inverted,
  size = 16,
  boxSize,
  reference,
  vehicleRegistrationNumber,
}: {
  inverted?: boolean;
  size?: number;
  boxSize?: BoxSizeType;
  reference: DHReferenceLocal | IAMReferenceLocal | OrderReferenceItem | undefined;
  vehicleRegistrationNumber: string | undefined;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const open = useSelector(getEmailDocumentationAlertMotrioIsModalOpen);

  function onCancel() {
    dispatch(resetEmailDocumentationAlertMotrioData());
  }

  return (
    <>
      <WithTooltip title={t('motrio.documentation_alert.raise_error', 'Raise a documentation error')}>
        <div>
          <IconButton
            IconComponent={Bell}
            round
            size={size}
            color={inverted ? 'white' : 'red'}
            bgColor={inverted ? 'red' : 'white'}
            onClick={() => dispatch(setEmailDocumentationAlertMotrioModalIsOpen(true))}
            boxSize={boxSize}
            hoverFill={inverted ? 'red' : 'white'}
            dataCy={'icon-raise-documentation-error'}
          />
        </div>
      </WithTooltip>
      <Modal zIndex={1000} width={1200} footer={''} open={open} onCancel={onCancel}>
        <DocumentationAlertForm
          onCancel={onCancel}
          reference={reference}
          vehicleRegistrationNumber={vehicleRegistrationNumber}
        />
      </Modal>
    </>
  );
};
