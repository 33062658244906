/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import {
  ROUTER_CATALOG_DETAILS,
  ROUTER_CATALOG_MAINTENANCE_PLAN,
  ROUTER_CATALOG_TIRES,
  ROUTER_CATALOG_VEHICLE,
  ROUTER_REPAIR_METHOD,
  ROUTER_TECHNICAL_DATA,
} from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { ExternalLinkIcon, InfoCircleIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import { getTechnicalDocumentationLink } from 'domains/appContext/AppContext.store';
import { useFetchOtsType } from 'domains/catalog/Catalog.requests';
import {
  getLastSearchVehicleTechnicalData,
  getLastSearchVehicleVersionCode,
  getMaintenancePlan,
  getOtsType,
} from 'domains/catalog/Catalog.store';
import { FLUIDS_ID, INTERVALS_ID, TECHNICAL_DATA_TAB, TechnicalDataIdParam } from 'domains/catalog/Catalog.types';
import { getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { getIAMAvailableModules } from 'domains/maintenancePlan/MaintenancePlan.store';
import { getIAMTires, getVehicleTires } from 'domains/tires/Tire.store';
import { getUserRights, UserRole } from 'domains/user';
import { VehicleTabContent, VehicleTabTitle } from 'pages/CatalogPage/common/CatalogBanner/CatalogVehicleTab';
import Maintenance from 'pages/CatalogPage/DH/Maintenance';
import { VehicleTechnicalDetails as DHVehicleTechnicalDetails } from 'pages/CatalogPage/DH/VehicleTechnicalDetails/VehicleTechnicalDetails';
import MaintenancePlan from 'pages/CatalogPage/IAM/MaintenancePlan';
import { RepairMethod } from 'pages/CatalogPage/IAM/RepairMethod/RepairMethod';
import { VehicleTechnicalData } from 'pages/CatalogPage/IAM/VehicleTechnicalData/VehicleTechnicalData';
import { VehicleTechnicalDetails as IAMVehicleTechnicalDetails } from 'pages/CatalogPage/IAM/VehicleTechnicalDetails/VehicleTechnicalDetails';
import { theme } from 'styles';
import { Container, Flex, Icon, LinkTabs, StickyBox, Text, WithTooltip } from 'UI';
import {
  ERROR,
  getCondArrayItem,
  getData,
  hasData,
  hasUserAnyRight,
  hasUserR3WithSubscriptionL234,
  isQueryBMM,
  LOADING,
} from 'utils';
import { useIsScrolled } from 'utils/hooks/useIsScrolled';
import { OFFSET_LINK_TABS, OFFSET_PADDING, OFFSET_SUB_HEADER, useOffsetTop } from 'utils/hooks/useOffsetTop';
/* eslint-enable max-len */

export const renderVehicleTabContentSticky = (
  offsetTop: number,
  result: VehicleDetail,
  vehicleImage: string | undefined,
  isBMM: boolean,
  query: string,
  isScrolled: boolean,
  setIsChangeVehicle?: (b: boolean) => void,
) => {
  return (
    <StickyBox offsetTop={offsetTop + OFFSET_SUB_HEADER + OFFSET_LINK_TABS + OFFSET_PADDING} zIndex={898}>
      <VehicleTabContent
        result={result}
        vehicleImage={vehicleImage}
        isBMM={isBMM}
        query={query}
        isSticky={isScrolled}
        setIsChangeVehicle={setIsChangeVehicle}
      />
    </StickyBox>
  );
};

export const CatalogTabs = ({
  result,
  vehicleImage,
  query,
  setCatalogShowMenu,
  setIsChangeVehicle,
}: {
  result: VehicleDetail;
  vehicleImage: string | undefined;
  query: string;
  setCatalogShowMenu: (v: boolean) => void;
  setIsChangeVehicle?: (b: boolean) => void;
}) => {
  const { t } = useTranslation();
  const maintenancePlanData = useSelector(getMaintenancePlan);
  const technicalData = useSelector(getLastSearchVehicleTechnicalData);
  const otsType = useSelector(getOtsType);
  const availableDataModule = getData(useSelector(getIAMAvailableModules));
  const intervals = technicalData?.find((d) => d.id === INTERVALS_ID);
  const isMaintenancePlanError = maintenancePlanData === ERROR;
  const isBMM = isQueryBMM(result.vehicleKey);
  const technicalDocumentationUrl = useSelector(getTechnicalDocumentationLink);
  const versionCode = useSelector((state: RootState) => getLastSearchVehicleVersionCode(state));
  const technicalDocumentationVersionUrl =
    (versionCode && technicalDocumentationUrl?.replace('{vehicleId}', versionCode)) ?? '';
  const vehicleTechnicalData = useSelector((state: RootState) => getLastSearchVehicleTechnicalData(state));
  const hasTechnicalData = vehicleTechnicalData && vehicleTechnicalData.length > 0;
  const uparams = new URLSearchParams(location.search);
  uparams.set(TechnicalDataIdParam, FLUIDS_ID);
  const userRights = getData(useSelector(getUserRights));
  const offsetTop = useOffsetTop('RENAULT_CATALOG');
  const isScrolled = useIsScrolled();
  const tires = useSelector(getVehicleTires);
  const iamTires = useSelector(getIAMTires);
  const iamVehicleTires = getData(iamTires);
  const isIam = result.catalogSource === 'IAM';

  useFetchOtsType();

  const renderVehicleTabContent = renderVehicleTabContentSticky(
    offsetTop,
    result,
    vehicleImage,
    isBMM,
    query,
    isScrolled,
    setIsChangeVehicle,
  );

  const getHasAccessToTechnicalLinks = (): boolean => {
    if (isIam) {
      return hasUserAnyRight(
        [UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS],
        userRights,
      );
    }
    return Boolean(versionCode) && hasUserR3WithSubscriptionL234(userRights);
  };

  return (
    <Flex direction={'column'}>
      <LinkTabs
        sticky
        variant={'blackHalf'}
        urlParam={TechnicalDataIdParam}
        absolute
        gap={1}
        scrollable
        items={[
          {
            key: 1,
            label: (
              <VehicleTabTitle
                result={result}
                isBMM={isBMM}
                link={`${getCatalogSourceUrl(result.catalogSource)}/${result.vehicleKey}${ROUTER_CATALOG_VEHICLE}`}
                countryName={result.country}
              />
            ),
            component: renderVehicleTabContent,
            link: `${getCatalogSourceUrl(result.catalogSource)}/${result.vehicleKey}${ROUTER_CATALOG_VEHICLE}`,
            dataCy: 'tab-vehicle',
          },
          ...getCondArrayItem(
            !isBMM && {
              key: 2,
              label: (
                <Text type={'lead_text_white'} noWrap>
                  {t('catalog.parts.banner.vehicle_details.title', 'Vehicle details')}
                </Text>
              ),
              component: (
                <>
                  {renderVehicleTabContent}
                  {result.catalogSource === 'IAM' ? <IAMVehicleTechnicalDetails /> : <DHVehicleTechnicalDetails />}
                </>
              ),
              link: `${getCatalogSourceUrl(result.catalogSource)}/${result.vehicleKey}${ROUTER_CATALOG_DETAILS}`,
              dataCy: 'tab-vehicle-details',
            },
          ),
          ...getCondArrayItem(
            intervals &&
              !getData(maintenancePlanData) && {
                key: 4,
                label: (
                  <Text type={'lead_text_white'} noWrap>
                    {t('catalog.parts.banner.maintenance_plan.title', 'Maintenance plan')}
                  </Text>
                ),
                component: (
                  <>
                    {renderVehicleTabContent}
                    <Container>
                      <MaintenancePlan setCatalogShowMenu={setCatalogShowMenu} />
                    </Container>
                  </>
                ),
                link: `${getCatalogSourceUrl(result.catalogSource)}/${
                  result.vehicleKey
                }${ROUTER_CATALOG_MAINTENANCE_PLAN}`,
                dataCy: 'tab-maintenance-plan',
              },
          ),
          ...getCondArrayItem(
            (getData(maintenancePlanData) || isMaintenancePlanError || maintenancePlanData === LOADING) && {
              key: 5,
              label: isMaintenancePlanError ? (
                <div>
                  <WithTooltip
                    title={t('common.backend_error', 'Data temporarily unavailable, please try again later.')}
                  >
                    <Text type={'lead_text_white'} noWrap>
                      {t('catalog.parts.banner.maintenance_plan.title', 'Maintenance plan')}
                    </Text>
                  </WithTooltip>
                </div>
              ) : (
                <DataContainer data={maintenancePlanData}>
                  <Text type={'lead_text_white'} noWrap>
                    {t('catalog.parts.banner.maintenance_plan.title', 'Maintenance plan')}
                  </Text>
                </DataContainer>
              ),
              component: (
                <>
                  {renderVehicleTabContent}
                  <Maintenance />
                </>
              ),
              link: `${getCatalogSourceUrl(result.catalogSource)}/${
                result.vehicleKey
              }${ROUTER_CATALOG_MAINTENANCE_PLAN}`,
              isDisabled: isMaintenancePlanError,
            },
          ),
          ...getCondArrayItem(
            hasData(tires) && {
              key: 6,
              label: null,
              component: null,
              link: `${getCatalogSourceUrl(result.catalogSource)}/${result.vehicleKey}${ROUTER_CATALOG_TIRES}`,
            },
          ),
          ...getCondArrayItem(
            result?.catalogSource === 'IAM' &&
              hasUserAnyRight(
                [UserRole.IAM_ACCESS, UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS],
                userRights,
              ) &&
              hasData(iamVehicleTires) && {
                key: 7,
                label: null,
                component: null,
                link: `${getCatalogSourceUrl(result.catalogSource)}/${result.vehicleKey}${ROUTER_CATALOG_TIRES}`,
              },
          ),
          ...getCondArrayItem(
            otsType?.data &&
              otsType?.data !== 'NONE' && {
                key: 8,
                label: (
                  <Flex align={'center'}>
                    <Icon IconComponent={InfoCircleIcon} color={theme.color.white} size={23} mr={7} />
                    <Text type={'lead_text_white'} noWrap>
                      {t('catalog.parts.banner.ots.title', 'Vehicle under one or several OTS.')}
                    </Text>
                  </Flex>
                ),
                link: '',
                staticWithColor: theme.color.red,
                dataCy: 'tab-ots',
              },
          ),
          ...getCondArrayItem(
            hasTechnicalData &&
              getHasAccessToTechnicalLinks() && {
                key: 9,
                label: (
                  <Text type={'lead_text_white'} noWrap>
                    {t('catalog.parts.category.technical_data', 'Technical data')}
                  </Text>
                ),
                component: (
                  <>
                    {renderVehicleTabContent}
                    <VehicleTechnicalData tabKey={TECHNICAL_DATA_TAB} />
                  </>
                ),
                link: `${getCatalogSourceUrl(result.catalogSource)}/${result.vehicleKey}${ROUTER_TECHNICAL_DATA}`,
                dataCy: 'tab-technidal-data',
              },
          ),
          ...getCondArrayItem(
            hasUserAnyRight(
              [UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS],
              userRights,
            ) &&
              isIam &&
              availableDataModule?.availableData.find((d) => d.name === 'manuals')?.dataAvailable && {
                key: 10,
                label: (
                  <Text type={'lead_text_white'} noWrap>
                    {t('catalog.parts.banner.repair_method.title', 'Repair Method')}
                  </Text>
                ),
                component: (
                  <>
                    {renderVehicleTabContent}
                    <RepairMethod />
                  </>
                ),
                link: `${getCatalogSourceUrl(result.catalogSource)}/${result.vehicleKey}${ROUTER_REPAIR_METHOD}`,
                dataCy: 'tab-repair-method',
              },
          ),
          ...getCondArrayItem(
            getHasAccessToTechnicalLinks() && {
              key: 11,
              label: (
                <a href={technicalDocumentationVersionUrl} target={'_blank'} rel={'noreferrer'}>
                  <Text type={'lead_text_white'} noWrap>
                    {t('catalog.parts.category.documentation', 'Documentation')}
                    <Icon ml={5} size={14} IconComponent={ExternalLinkIcon} color={theme.color.white} />
                  </Text>
                </a>
              ),
              link: '',
              staticWithColor: theme.color.grey25,
              dataCy: 'tab-technidal-data',
            },
          ),
        ]}
      />
    </Flex>
  );
};
