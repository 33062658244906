/* eslint-disable max-len */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VehiclePartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehiclePartCategoryTreeItem';
import { IAMRepairMethod } from '@1po/1po-bff-fe-spec/generated/catalog/repair_methods/response/GetIAMRepairMethodsResponse';
import { Level3Detail } from '@1po/1po-bff-fe-spec/generated/catalog/tree/request/GetDetailsOfLevels3Request';
import { UniversalProductsCategory } from '@1po/1po-bff-fe-spec/generated/catalog/universal_products/response/GetUniversalProductsTreeResponse';
import { CatalogSource, VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'app/AppStore';
import {
  fetchDHTireRequestSaga,
  fetchIAMLaborTimesRequestSaga,
  fetchIAMReferencesRequestSaga,
  fetchIAMRepairMethodsRequestSaga,
  fetchIAMRepairMethodsTreeRequestSaga,
  fetchIAMTechnicalDataDetailsRequestSaga,
  fetchIAMTechnicalDataTreeRequestSaga,
  fetchIAMVehicleTechnicalCriteriaRequestSaga,
  fetchLevels3DetailsRequestSaga,
  fetchMaintenancePlanRequestSaga,
  fetchMarketingReferencesRequestSaga,
  fetchOtsInformationRequestSaga,
  fetchPlateReferencesRequestSaga,
  fetchSearchVehicleByBMMRequestSaga,
  fetchSearchVehicleRequestSaga,
  fetchSingleReferenceRequestSaga,
  fetchTextSearchRequestSaga,
  fetchUniversalProductsRequestSaga,
  fetchUniversalProductsTreeRequestSaga,
  fetchVehicleTechnicalCriteriaRequestSaga,
  getIAMRepairMethodsTree,
  getLastVehicleDetail,
  getMaintenancePlan,
  getOtsType,
  getPlate,
  getPlateReferences,
  getUniversalProducts,
  getUniversalProductsTree,
  setUniversalProductSelection,
} from 'domains/catalog/Catalog.store';
import {
  ExplodedTree,
  FulltextSearchResult,
  IAMFulltextSearchResult,
  IAMLaborTimesWrapper,
  IAMReferencesWrapper,
  MarketingReferencesWrapper,
  MultiPlate,
  PlateImage,
  SearchStatus,
  UniversalProductsSelection,
  VehicleCountry,
  VehicleItemState,
} from 'domains/catalog/Catalog.types';
import { getDHReference } from 'domains/references';
import { ItemElement } from 'pages/CatalogPage/common/TechnicalCriteria/TechnicalCriteriaMenuAndContent';
import {
  FOUND,
  getData,
  hasData,
  hasUserAnyRight,
  hasUserR3WithLimitedAccessSubscriptionL01,
  hasUserR3WithSubscriptionL234,
  isLoading,
  isQueryBMM,
  isQueryIAMVIN,
  isQueryIAMVRN,
  isVIN,
  LOADING,
  NO_DATA,
  normalize,
  normalizeVIN,
  normalizeVRN,
  SEARCH_STATUS,
  validateVIN,
  validateVINVRNGeneral,
  validateVRN,
} from 'utils';
import { getCurrentEstimateVehicle } from '../estimate/Estimate.store';
import { DEFAULT_COUNTRY, fetchAddVehicleToSearchHistoryRequestSaga, getUserRights, UserRole } from '../user';

const fetchVehicleByVIN = (
  dispatch: Dispatch,
  vin: string,
  country: string,
  includeLatestEstimate: boolean,
  catalogSource?: CatalogSource,
  versionCode?: string | undefined,
  handleValidatedVIN?: (vin: string) => void,
  handleFailedValidation?: (vin: string) => void,
) => {
  const normalizedVIN = normalizeVIN(vin);
  if (validateVIN(normalizedVIN)) {
    handleValidatedVIN && handleValidatedVIN(normalizedVIN);
    dispatch(
      fetchSearchVehicleRequestSaga({
        value: normalizedVIN,
        queryType: 'VIN',
        countryCode: country,
        includeLatestEstimate,
        catalogSource,
        versionCode,
      }),
    );
  } else {
    handleFailedValidation && handleFailedValidation(vin);
  }
};

const fetchVehicleByVRN = (
  dispatch: Dispatch,
  vrn: string,
  country: string,
  includeLatestEstimate: boolean,
  catalogSource?: CatalogSource,
  versionCode?: string | undefined,
  handleValidatedVRN?: (vrn: string) => void,
  handleFailedValidation?: (vrn: string) => void,
) => {
  const normalizedVRN = normalizeVRN(vrn);
  if (validateVRN(normalizedVRN)) {
    handleValidatedVRN && handleValidatedVRN(normalizedVRN);
    dispatch(
      fetchSearchVehicleRequestSaga({
        value: normalizedVRN,
        queryType: 'VRN',
        countryCode: country,
        includeLatestEstimate,
        catalogSource,
        versionCode,
      }),
    );
  } else {
    handleFailedValidation && handleFailedValidation(vrn);
  }
};

export const fetchVehicleByVINVRN = (
  dispatch: Dispatch,
  query: string,
  country: string,
  includeLatestEstimate: boolean,
  catalogSource?: CatalogSource,
  versionCode?: string | undefined,
  handleValidatedQuery?: (query: string) => void,
  handleFailedValidation?: (query: string) => void,
) => {
  const normalizedQuery = normalize(query);
  if (validateVINVRNGeneral(normalizedQuery)) {
    if (isVIN(normalizedQuery)) {
      fetchVehicleByVIN(
        dispatch,
        normalizedQuery,
        country,
        includeLatestEstimate,
        catalogSource,
        versionCode,
        handleValidatedQuery,
        handleFailedValidation,
      );
    } else {
      fetchVehicleByVRN(
        dispatch,
        normalizedQuery,
        country,
        includeLatestEstimate,
        catalogSource,
        versionCode,
        handleValidatedQuery,
        handleFailedValidation,
      );
    }
  } else {
    handleFailedValidation && handleFailedValidation(normalizedQuery);
  }
};

export const fetchVehicleByBMM = (
  dispatch: Dispatch,
  brandCode: string,
  modelCode: string,
  modelTypeCode: string,
  engineCode: string,
  gearboxCode: string,
  iamVersionCode: string,
  includeLatestEstimate: boolean,
  catalogSource?: CatalogSource,
) => {
  const requestId =
    iamVersionCode == undefined
      ? `${brandCode}_${modelCode}_${modelTypeCode}_${engineCode}_${gearboxCode}`
      : `${brandCode}_${modelCode}_${iamVersionCode}`;
  dispatch(
    fetchSearchVehicleByBMMRequestSaga({
      brandCode,
      engineCode,
      modelTypeCode,
      modelCode,
      gearboxCode,
      requestId,
      iamVersionCode,
      includeLatestEstimate,
      catalogSource,
    }),
  );
};

export const fetchVehicleByBMMQuery = (
  dispatch: Dispatch,
  query: string,
  includeLatestEstimate: boolean,
  catalogSource?: CatalogSource,
) => {
  const modelParameters = query.split('_');
  const isDH = modelParameters.length === 5;
  fetchVehicleByBMM(
    dispatch,
    modelParameters[0],
    modelParameters[1],
    isDH ? modelParameters[2] : '',
    isDH ? modelParameters[3] : '',
    isDH ? modelParameters[4] : '',
    isDH ? '' : modelParameters[2],
    includeLatestEstimate,
    catalogSource,
  );
};

export const fetchVehicleFunction = (
  dispatch: Dispatch,
  query: string,
  includeLatestEstimate: boolean,
  country?: string,
  catalogSource?: CatalogSource,
  searchStatus?: SEARCH_STATUS,
  versionCode?: string | undefined,
) => {
  if (searchStatus || !query) {
    return;
  }
  if (isQueryBMM(query)) {
    fetchVehicleByBMMQuery(dispatch, query, includeLatestEstimate, catalogSource);
  } else {
    //ONEPORTAL-3692 - vehicle key may be VRN_Country

    const isIAMNonBMMQuery = isQueryIAMVIN(query) || isQueryIAMVRN(query);
    const searchQuery = isIAMNonBMMQuery ? query.split('_')[0] : query;
    const versionCodeQuery = getVersionCode(query, versionCode, isIAMNonBMMQuery);
    fetchVehicleByVINVRN(
      dispatch,
      searchQuery,
      country ?? DEFAULT_COUNTRY.key,
      includeLatestEstimate,
      catalogSource,
      versionCodeQuery,
    );
  }
};

const getVersionCode = (query: string, versionCode: string | undefined, isIAMNonBMMQuery: boolean) => {
  if (versionCode || !isIAMNonBMMQuery) {
    return versionCode;
  }
  const countryOrVersionCode = query.split('_')[1];
  return countryOrVersionCode.length === 2 ? undefined : countryOrVersionCode;
};

export const useFetchVehicle = (query: string, searchStatus?: SEARCH_STATUS) => {
  const dispatch = useDispatch();

  const params = new URLSearchParams(location.search);
  const country = params.get(VehicleCountry) ?? undefined;

  useEffect(() => {
    if (!query) {
      return;
    }

    fetchVehicleFunction(dispatch, query, true, country, undefined, searchStatus);
  }, [dispatch, query, searchStatus, country]);
};

export const useFetchMarketingReferences = (
  subcategory: VehiclePartCategoryTreeItem | undefined,
  marketingReferenceNumbers: MarketingReferencesWrapper | undefined,
  vehicleKey: string | undefined,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      vehicleKey &&
      subcategory?.nodeId &&
      subcategory?.containsMarketingReferences === true &&
      marketingReferenceNumbers?.status !== LOADING &&
      !marketingReferenceNumbers?.referenceNumbers
    ) {
      dispatch(
        fetchMarketingReferencesRequestSaga({
          nodeId: subcategory.nodeId,
          vehicleKey,
        }),
      );
    }
  }, [dispatch, marketingReferenceNumbers, subcategory, vehicleKey]);
};

export const useFetchIAMReferences = (
  versionCode: string | undefined,
  vehicleKey: string | undefined,
  nodeId: string | undefined,
  referenceNumbersOem: IAMReferencesWrapper | undefined,
  referenceNumbersOther: IAMReferencesWrapper | undefined,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      versionCode &&
      vehicleKey &&
      nodeId &&
      ((referenceNumbersOem !== LOADING && !referenceNumbersOem) ||
        (referenceNumbersOther !== LOADING && !referenceNumbersOther))
    ) {
      dispatch(
        fetchIAMReferencesRequestSaga({
          versionCode,
          vehicleKey,
          nodeId,
        }),
      );
    }
  }, [dispatch, versionCode, vehicleKey, referenceNumbersOther, referenceNumbersOem, nodeId]);
};

export const useFetchPlates = (
  subcategories: ExplodedTree | undefined,
  plates: PlateImage[],
  query: string,
  vehicleResult: VehicleItemState | undefined,
  subcategoriesMultiplates: Map<string, NO_DATA | MultiPlate[]>,
) => {
  const dispatch = useDispatch();
  const vehicleKey = vehicleResult?.vehicleDetail?.vehicleKey;

  useEffect(() => {
    const lv3Details = subcategories
      ?.filter((subcategory) => subcategory.plateIds.length > 0)
      .map((subcategory) => {
        return {
          nodeId: subcategory.nodeId,
          plateIds: subcategory.plateIds,
        } as Level3Detail;
      });

    if (lv3Details && vehicleKey) {
      const lvsToFetch = lv3Details.filter((lv3) => {
        const multiplate = subcategoriesMultiplates.get(lv3.nodeId);
        return multiplate === undefined;
      });

      if (lvsToFetch?.length > 0) {
        dispatch(
          fetchLevels3DetailsRequestSaga({
            level3Details: lvsToFetch,
            plateIds: lvsToFetch.reduce((acc: string[], next) => [...acc, ...next.plateIds], []),
            vehicleKey,
          }),
        );
      }
    }
  }, [dispatch, subcategories, plates, query, vehicleKey, subcategoriesMultiplates]);
};

export const useUpdateVehicleSearchHistory = (catalogVehicle?: VehicleDetail) => {
  const dispatch = useDispatch();
  const estimateVehicleKey = useSelector(getCurrentEstimateVehicle)?.vehicleKey;

  useEffect(() => {
    if (catalogVehicle?.vehicleKey && catalogVehicle?.vehicleKey !== estimateVehicleKey) {
      dispatch(fetchAddVehicleToSearchHistoryRequestSaga({ vehicle: catalogVehicle }));
    }
  }, [dispatch, catalogVehicle, estimateVehicleKey]);
};

export const useFetchIAMLaborTimes = (
  vehicleKey: string | undefined,
  nodeId: string,
  versionCode: string | undefined,
  laborTimesWrapper: IAMLaborTimesWrapper | NO_DATA,
  userRights?: UserRole[],
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      vehicleKey &&
      versionCode &&
      !laborTimesWrapper &&
      hasUserAnyRight([UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS], userRights)
    ) {
      dispatch(
        fetchIAMLaborTimesRequestSaga({
          vehicleKey,
          nodeId,
          versionCode,
        }),
      );
    }
  }, [dispatch, vehicleKey, nodeId, versionCode, laborTimesWrapper, userRights]);
};

//TODO smazat
export const useFetchVehicleTechnicalCriteria = (items: ItemElement[], isIAM: boolean) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (items.length === 0) {
      if (isIAM) {
        dispatch(fetchIAMVehicleTechnicalCriteriaRequestSaga());
      } else {
        dispatch(fetchVehicleTechnicalCriteriaRequestSaga());
      }
    }
  }, [dispatch, items, isIAM]);
};

export const useUniversalProductsTree = (): UniversalProductsCategory[] | NO_DATA => {
  const universalProductsTree = useSelector(getUniversalProductsTree);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!universalProductsTree) {
      dispatch(fetchUniversalProductsTreeRequestSaga());
    }
  }, [dispatch, universalProductsTree]);
  return universalProductsTree;
};

export const useFetchUniversalProductsOld = (references: string[] | typeof LOADING) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!references || references.length === 0 || references === LOADING) {
      return;
    }
    dispatch(fetchUniversalProductsRequestSaga(references.filter((ref) => !isLoading(ref))));
  }, [dispatch, references]);
};
export const useFetchUniversalProducts = (references: string[]) => {
  const dispatch = useDispatch();
  const products = useSelector((state: RootState) => getUniversalProducts(state, references));
  const isAnyReferenceLoading = Array.from(products.values()).some((r) => isLoading(r));

  useEffect(() => {
    if (references.length === 0 || isAnyReferenceLoading) {
      return;
    }
    dispatch(fetchUniversalProductsRequestSaga(references));
  }, [references]); //  eslint-disable-line
};

export const useReloadUniversalProducts = (
  level1: string | undefined,
  level2: string | undefined,
  level3: string | undefined,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const selection: UniversalProductsSelection = { level1, level2, level3 };
    dispatch(setUniversalProductSelection(selection));
  }, []); //  eslint-disable-line
};

export const fetchTextSearch = (
  dispatch: Dispatch,
  searchText: string,
  searchId: string,
  vehicleKey: string | undefined,
  catalogSource: CatalogSource | undefined,
) => {
  dispatch(
    fetchTextSearchRequestSaga({
      vehicleKey,
      searchId,
      searchText,
      queryType: 'REFERENCE',
      catalogSource,
    }),
  );
};

export const useFetchTextSearch = (
  searchText: string,
  vehicleKey: string,
  searchResult: FulltextSearchResult | IAMFulltextSearchResult[] | undefined,
  searchStatus: SearchStatus | undefined,
  catalogSource: CatalogSource | undefined,
) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!searchStatus && !hasData(searchResult)) {
      fetchTextSearch(dispatch, searchText, uuidv4(), vehicleKey, catalogSource);
    }
  }, [dispatch, searchText, vehicleKey, searchStatus, searchResult, catalogSource]);
};

export const fetchSingleReference = (dispatch: Dispatch, reference: string, vehicleKey: string | undefined) => {
  dispatch(
    fetchSingleReferenceRequestSaga({
      reference,
      vehicleKey,
    }),
  );
};

export const useFetchPlateReferences = (plateId: string | undefined, vehicleKey: string | undefined) => {
  const dispatch = useDispatch();
  const plateReferences = useSelector((state: RootState) => getPlateReferences(state, { plateId, vehicleKey }));
  const plate = useSelector((state: RootState) => getPlate(state, { plateId: plateId ?? '', vehicleKey }));
  const detail = plate?.plateDetail;

  useEffect(() => {
    if (plateId && hasData(detail) && !plateReferences && vehicleKey) {
      dispatch(
        fetchPlateReferencesRequestSaga({
          plateId,
          vehicleKey,
        }),
      );
    }
  }, [dispatch, plateId, detail, vehicleKey, plateReferences]);
};

export const useFetchSingleReference = (
  referenceNumber: string | undefined,
  plateId: string | null,
  vehicleKey: string | undefined,
) => {
  const dispatch = useDispatch();
  const reference = useSelector((state: RootState) => getDHReference(state, { vehicleKey, referenceNumber }));
  const plate = useSelector((state: RootState) => getPlate(state, { plateId: plateId ?? '', vehicleKey }));
  const shouldFetchDetail = hasData(reference) && !getData(reference)?.detailStatus;

  useEffect(() => {
    if (!referenceNumber || isLoading(reference) || isLoading(plate?.references)) {
      return;
    }
    if (referenceNumber && (!reference || shouldFetchDetail)) {
      if (plateId && vehicleKey && !plate?.references) {
        dispatch(
          fetchPlateReferencesRequestSaga({
            plateId,
            vehicleKey,
          }),
        );
      } else {
        fetchSingleReference(dispatch, referenceNumber, vehicleKey);
      }
    }
  }, [dispatch, reference, plate, referenceNumber, vehicleKey, plateId, shouldFetchDetail]);
};

export const useFetchIAMTechnicalDataTree = (catalogVehicle?: VehicleDetail) => {
  const dispatch = useDispatch();
  const userRights = getData(useSelector(getUserRights));

  useEffect(() => {
    if (
      hasUserAnyRight([UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS], userRights)
    ) {
      dispatch(fetchIAMTechnicalDataTreeRequestSaga());
    }
  }, [dispatch, catalogVehicle, userRights]);
};

export const useFetchMaintenancePlan = () => {
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const vin = vehicleDetail?.vin;
  const maintenancePlan = useSelector(getMaintenancePlan);
  const dispatch = useDispatch();
  const userRights = getData(useSelector(getUserRights));

  useEffect(() => {
    if (hasUserR3WithLimitedAccessSubscriptionL01(userRights)) {
      // no Ren maintenance plan for R3 and R3_MOT (for those with subscription use IAM data above)
      return;
    }

    if (vin && !maintenancePlan) {
      dispatch(fetchMaintenancePlanRequestSaga()); //R/D maintenance plan
    }
    // eslint-disable-next-line
  }, [dispatch, vin, userRights]);
};

export const useFetchKadensisTechnicalData = () => {
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const vin = vehicleDetail?.vin;
  const dispatch = useDispatch();
  const userRights = getData(useSelector(getUserRights));

  useEffect(() => {
    if (hasUserR3WithSubscriptionL234(userRights)) {
      dispatch(fetchIAMTechnicalDataTreeRequestSaga());
    }

    // eslint-disable-next-line
  }, [dispatch, vin, userRights]);
};

export const useFetchIAMTechnicalDataDetails = (categoryL3Ids: string[], categoryId: string | undefined) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (categoryL3Ids && categoryL3Ids.length > 0) {
      dispatch(fetchIAMTechnicalDataDetailsRequestSaga({ categoryIds: categoryL3Ids }));
    }
    // eslint-disable-next-line
  }, [dispatch, categoryId]);
};

export const useFetchOtsType = () => {
  const dispatch = useDispatch();
  const vehicle = useSelector(getLastVehicleDetail);
  const isOtsTypeLoaded = useSelector(getOtsType)?.searchStatus === FOUND;

  useEffect(() => {
    if (!isOtsTypeLoaded && vehicle?.catalogSource === 'DATAHUB' && vehicle.vin) {
      dispatch(fetchOtsInformationRequestSaga({ vin: vehicle.vin }));
    }
  }, [dispatch, isOtsTypeLoaded, vehicle]);
};

export const useFetchDHTire = (vehicleItemState?: VehicleItemState) => {
  const dispatch = useDispatch();
  const isFound = vehicleItemState?.searchVehicleStatus === FOUND;
  const vehicleDetail = vehicleItemState?.vehicleDetail;

  useEffect(() => {
    if (isFound && vehicleDetail !== undefined && vehicleDetail.catalogSource === 'DATAHUB') {
      dispatch(fetchDHTireRequestSaga(vehicleDetail));
    }
  }, [dispatch, vehicleDetail, isFound]);
};

export const useFetchIAMRepairMethods = (
  vehicleKey: string | undefined,
  nodeId: string,
  versionCode: string | undefined,
  repairMethodWrapper: IAMRepairMethod[] | NO_DATA,
  userRights?: UserRole[],
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      vehicleKey &&
      versionCode &&
      !repairMethodWrapper &&
      hasUserAnyRight([UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS], userRights)
    ) {
      dispatch(
        fetchIAMRepairMethodsRequestSaga({
          vehicleKey,
          nodeId,
          versionCode,
        }),
      );
    }
  }, [dispatch, vehicleKey, nodeId, versionCode, repairMethodWrapper, userRights]);
};

export const useFetchIAMRepairMethodsTree = (catalogVehicle?: VehicleDetail) => {
  const dispatch = useDispatch();
  const repairMethods = useSelector((state: RootState) =>
    getIAMRepairMethodsTree(state, { query: catalogVehicle?.vehicleKey || '' }),
  );
  const userRights = getData(useSelector(getUserRights));

  useEffect(() => {
    if (
      catalogVehicle?.vehicleKey &&
      catalogVehicle?.iamVehicle?.versionCode &&
      !repairMethods &&
      hasUserAnyRight([UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS], userRights)
    ) {
      dispatch(
        fetchIAMRepairMethodsTreeRequestSaga({
          vehicleKey: catalogVehicle.vehicleKey,
          versionCode: catalogVehicle.iamVehicle.versionCode,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, catalogVehicle, userRights]);
  return repairMethods;
};
