/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { DataContainer } from 'components/DataContainer';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { IAMLaborTimesWrapper, OEM_BRAND_KEY } from 'domains/catalog/Catalog.types';
import { mapIamOEMPrice } from 'domains/catalog/Catalog.utils';
import {
  ADDITIONAL_INFORMATION_ID_P100,
  getIAMReference,
  getPrice,
  IAMReferenceLocal as Reference,
} from 'domains/references';
import { useFetchPrices } from 'domains/references/References.requests';
import { getIamCatalogBrandsView, SparePartsViewType } from 'domains/user';
import IAMReferenceCard from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard';
import { MarginBox } from 'UI';
import { getData, NO_DATA } from 'utils';

export function getFirstImageUrl(reference: Reference | undefined): string | undefined {
  return reference?.documents?.find((d) => d.url && d.order !== undefined && d.documentType === 'IMAGE')?.url;
}

export interface PlateReferenceCardProps {
  referenceNumber: string;
  sparePartsView: SparePartsViewType;
  laborTimesWrapper?: NO_DATA | IAMLaborTimesWrapper;
  isIamSearchResult?: boolean;
  selected?: boolean;
  isMaintenance?: boolean;
  onSelect?: (referenceNumber: string) => void;
}

const IAMPlateReferenceCard = ({
  referenceNumber,
  sparePartsView,
  laborTimesWrapper,
  isIamSearchResult,
  selected,
  isMaintenance,
  onSelect,
}: PlateReferenceCardProps) => {
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const referenceRaw = useSelector((state: RootState) =>
    getIAMReference(state, {
      referenceNumber,
      vehicleKey: isIamSearchResult ? undefined : vehicleKey,
    }),
  );
  const reference = getData(referenceRaw);
  const price = getData(useSelector((state: RootState) => getPrice(state, referenceNumber)));
  const imageUrl = getFirstImageUrl(reference);
  const iamCatalogView = useSelector(getIamCatalogBrandsView);
  const isOEM = iamCatalogView === OEM_BRAND_KEY && !isIamSearchResult;

  useFetchPrices([referenceNumber], isIamSearchResult ? undefined : vehicleKey);

  if (!reference) {
    return null;
  }

  const refPosition = reference.additionalInformation?.find((i) => i.id === ADDITIONAL_INFORMATION_ID_P100)
    ?.description;

  const rawSupplier = reference.supplierReferences?.filter((ref) => ref.type === 'RAW');
  const rawLabels =
    rawSupplier && rawSupplier.length > 0 ? rawSupplier?.map((supplier) => (supplier ? supplier?.id : '')) : [];
  const tradeNumberSupplier = reference.supplierReferences?.filter((ref) => ref.type === 'TRADE_NUMBER');
  const tradeNumberLabels =
    tradeNumberSupplier && tradeNumberSupplier.length > 0
      ? tradeNumberSupplier?.map((supplier) => (supplier ? supplier?.id : ''))
      : [];
  const gtinSupplier = reference.supplierReferences?.filter((ref) => ref.type === 'GTIN');
  const gtinLabels =
    gtinSupplier && gtinSupplier.length > 0 ? gtinSupplier?.map((supplier) => (supplier ? supplier?.id : '')) : [];

  const referencePrice = isOEM ? mapIamOEMPrice(reference?.price, reference?.currencyCode) : price;
  const displayName = reference.name ?? reference.designation;

  return (
    <DataContainer data={referenceRaw}>
      <MarginBox mb={15}>
        <IAMReferenceCard
          referenceNumber={reference.referenceNumber}
          name={displayName}
          supplier={reference.supplier}
          rawLabels={rawLabels}
          secondaryName={[...tradeNumberLabels, ...gtinLabels].filter(Boolean).join(' / ')}
          brand={reference.brand}
          price={referencePrice}
          sparePartsView={sparePartsView}
          additionalInformation={reference.additionalInformation ?? []}
          vehicleKey={vehicleKey}
          brandImage={reference?.brandImageUrl}
          referenceImage={imageUrl}
          laborTimesWrapper={laborTimesWrapper}
          position={refPosition}
          documents={reference.documents ?? []}
          isIamSearchResult={isIamSearchResult}
          selected={selected}
          isMaintenance={isMaintenance}
          onSelect={onSelect}
          replacingReferences={reference.replacingReferences ?? []}
          substitutedReferences={reference.substitutedReferences ?? []}
          kitContent={reference.kitContent ?? []}
          kitsContainingThisPart={reference.kitsContainingThisPart ?? []}
          origin={reference.origin}
          supplierCode={reference.supplierCode}
          referenceSource={reference.referenceSource}
          quantityPerUnit={reference.quantityPerUnit}
        />
      </MarginBox>
    </DataContainer>
  );
};

export default IAMPlateReferenceCard;
