/* eslint-disable max-len */
import { useEffect } from 'react';
import { PlateReferencesDetail } from 'domains/catalog/Catalog.types';
import { hasData, NO_DATA } from 'utils/dataStatus';
import { scrollToTopSmooth } from 'utils/hooks/useResetScroll';
import { getElementAndSiblings, getSvgTextElementsFromDocument, IndexType, svgTextPosition } from 'utils/svg/common';

const addClassIntoElementWithLines = (indexElement: Element, className: string) => {
  getElementAndSiblings(indexElement).forEach((e) => {
    e.classList.add(className);
  });
};

const removeClassFromElementWithLines = (indexElement: Element, className: string) => {
  getElementAndSiblings(indexElement).forEach((e) => {
    e.classList.remove(className);
  });
};

const resolveHighlight = (partIndex: Element, highlightedIndex: IndexType) => {
  if (highlightedIndex === partIndex.innerHTML) {
    addClassIntoElementWithLines(partIndex, 'highlightedIndexLine');
  } else {
    removeClassFromElementWithLines(partIndex, 'highlightedIndexLine');
  }
};

export const useAddListenersToPlateSvg = (
  plateId: string,
  setHighlightedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void,
  setSelectedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void,
  scrollOffset: number,
  svgElement: string | JSX.Element | undefined,
  svgElementPlateId: string,
): void => {
  useEffect(() => {
    getSvgTextElementsFromDocument(svgElementPlateId, plateId, svgTextPosition)?.forEach((indexElement) => {
      addListeners(indexElement, setHighlightedIndex, setSelectedIndex, scrollOffset);
    });
  }, [plateId, setHighlightedIndex, setSelectedIndex, scrollOffset, svgElement, svgElementPlateId]);
};

function addListeners(
  indexElement: Element,
  setHighlightedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void,
  setSelectedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void,
  scrollOffset: number,
): () => void {
  const indexNumber = indexElement.innerHTML;

  const clickOnIndex = () => {
    const targetElement = document.getElementById('plate-reference-index-' + indexNumber);
    if (targetElement) {
      setSelectedIndex(indexNumber);
      scrollToTopSmooth(targetElement.offsetTop - scrollOffset);
    }
  };

  const mouseOnIndex = () => {
    document.getElementById(`part-list-reference-${indexNumber}`)?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
    setHighlightedIndex(indexNumber);
  };

  const mouseOffIndex = () => {
    setHighlightedIndex(null);
  };

  getElementAndSiblings(indexElement).forEach((e) => {
    e.addEventListener('click', clickOnIndex as EventListener);
    e.addEventListener('mouseover', mouseOnIndex as EventListener);
    e.addEventListener('mouseleave', mouseOffIndex);
  });

  return () => {
    getElementAndSiblings(indexElement).forEach((e) => {
      e.removeEventListener('click', clickOnIndex as EventListener);
      e.removeEventListener('mouseover', mouseOnIndex as EventListener);
      e.removeEventListener('mouseleave', mouseOffIndex);
    });
  };
}

const getIndexes = (plateReferences: PlateReferencesDetail[] | NO_DATA): Set<number> | undefined =>
  plateReferences === undefined ? undefined : getRealIndexes(plateReferences);

const getRealIndexes = (plateReferences: PlateReferencesDetail[] | NO_DATA): Set<number> =>
  hasData(plateReferences) ? new Set(plateReferences.map((p) => p.index)) : new Set();

export const manipulateLinesInSvg = (
  plateId: string | undefined,
  plateReferences: PlateReferencesDetail[] | NO_DATA,
  highlightedIndex: IndexType,
  svgElementPlateId: string,
): void => {
  if (plateId) {
    const indexSet = getIndexes(plateReferences);

    getSvgTextElementsFromDocument(svgElementPlateId, plateId, svgTextPosition)?.forEach((indexElement) => {
      if (indexSet !== undefined && !indexSet.has(Number(indexElement.innerHTML))) {
        addClassIntoElementWithLines(indexElement, 'hiddenIndexLine');
        return;
      }
      removeClassFromElementWithLines(indexElement, 'hiddenIndexLine');
      resolveHighlight(indexElement, highlightedIndex);
    });
  }
};
