import { useSelector } from 'react-redux';
import { Garage } from '@1po/1po-bff-fe-spec/generated/garage/Garage';
import { OrderReferenceItem } from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import { getEmailDocumentationAlertMotrioData } from 'domains/email/Email.store';
import { SendDocumentationAlertMotrioLocal } from 'domains/email/Email.types';
import { getFirstValidAddress } from 'domains/garage/Garage.utils';
import { DHReferenceLocal, IAMReferenceLocal } from 'domains/references';
import { getUserCommercialLink } from 'domains/user';

export function useFillDocumentationAlertState(
  garageInfo: Garage | undefined,
  reference: DHReferenceLocal | IAMReferenceLocal | OrderReferenceItem | undefined,
  vehicleRegistrationNumber: string | undefined,
): SendDocumentationAlertMotrioLocal {
  const emailData = useSelector(getEmailDocumentationAlertMotrioData);
  const commercialLink = useSelector(getUserCommercialLink);
  const address = garageInfo ? getFirstValidAddress(garageInfo) : undefined;

  return {
    ...emailData,
    companyRegistrationNumber:
      emailData.companyRegistrationNumber ??
      garageInfo?.registrationNumber ??
      (commercialLink?.repository === 'TRESOR' ? commercialLink?.tresorCode : commercialLink?.birCode ?? ''),
    email: emailData.email ?? address?.email ?? '',
    socialReason: emailData.socialReason ?? garageInfo?.name ?? '',
    subject: (reference?.referenceNumber ?? '') + (reference?.name !== undefined ? ' ' + reference?.name : ''),
    vehicleRegistrationNumber: vehicleRegistrationNumber ?? '',
  };
}
