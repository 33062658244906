import React, { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { AbsoluteThemeColorType, StatusType, ThemeType } from 'styles';
import { Box, CenterFlex, Flex, Icon, IconType, MarginBox, Modal, Text, WhiteButton, YellowButton } from 'UI';

const colorSelector = (theme: ThemeType, status?: StatusType): AbsoluteThemeColorType =>
  status ? theme.color[status] : theme.color.info;

export interface GeneralDialogProps {
  title: string;
  description?: string | string[];
  content?: ReactNode;
  icon?: IconType;
  handleConfirm: () => void;
  handleCancel?: () => void;
  disclosure: Disclosure;
  closable?: boolean;
  maskClosable?: boolean;
  buttonsText?: {
    cancel?: string;
    confirm?: string;
  };
  isConfirmButtonDisabled?: boolean;
}

interface DialogImplProps extends GeneralDialogProps {
  color?: AbsoluteThemeColorType;
  width: number;
}

const ConfirmationDialog = ({
  handleConfirm,
  handleCancel,
  title,
  description,
  content,
  icon,
  color,
  closable,
  maskClosable,
  disclosure,
  buttonsText,
  width,
  isConfirmButtonDisabled,
}: DialogImplProps) => {
  const { t } = useTranslation();
  const { isOpen, onClose } = disclosure;

  const handleReject = useCallback(() => {
    if (handleCancel) {
      handleCancel();
    }
    onClose();
  }, [handleCancel, onClose]);

  const handleAccept = useCallback(() => {
    handleConfirm();
    onClose();
  }, [handleConfirm, onClose]);

  const descriptionLines = Array.isArray(description) ? description : [description];

  // TODO width compute from breakpoint for screen size < 1000px
  return (
    <Modal
      title={
        <CenterFlex>
          <MarginBox mt={20}>
            <Text type={'h1'} disableGutter>
              {title}
            </Text>
          </MarginBox>
        </CenterFlex>
      }
      open={isOpen}
      closable={closable}
      maskClosable={maskClosable}
      onCancel={handleReject}
      width={width}
      footer={
        <>
          <MarginBox mb={30} mt={6} mx={15}>
            <Flex>
              <Flex />
              <Flex>
                <WhiteButton stretch onClick={handleReject}>
                  {buttonsText?.cancel ? buttonsText.cancel : t('common.action.cancel', 'Cancel')}
                </WhiteButton>
                <Box width={30} />
                <YellowButton stretch onClick={handleAccept} disabled={isConfirmButtonDisabled}>
                  {buttonsText?.confirm ? buttonsText.confirm : t('common.action.confirm', 'Confirm')}
                </YellowButton>
              </Flex>
            </Flex>
          </MarginBox>
        </>
      }
    >
      <MarginBox mt={15}>
        <Flex direction={'column'} align={'center'} justify={'center'}>
          {icon && (
            <>
              <MarginBox mt={15} key={'icon'}>
                <Icon IconComponent={icon} height={64} width={64} color={color} />
              </MarginBox>
            </>
          )}
          {description && (
            <MarginBox mt={0} key={'description'}>
              {descriptionLines.map((line, index) => (
                <Flex direction={'column'} align={'center'} justify={'center'} key={`description-line-${index}`}>
                  <Text type={'lead'}>{`${line}`}</Text>
                </Flex>
              ))}
            </MarginBox>
          )}
          {content && (
            <MarginBox mt={15} key={'content'}>
              {content}
            </MarginBox>
          )}
        </Flex>
      </MarginBox>
    </Modal>
  );
};

const InformationDialog = ({
  handleCancel,
  title,
  description,
  content,
  icon,
  color,
  disclosure,
  width,
}: DialogImplProps) => {
  const { isOpen, onClose } = disclosure;

  const handleReject = useCallback(() => {
    if (handleCancel) {
      handleCancel();
    }
    onClose();
  }, [handleCancel, onClose]);

  const descriptionLines = Array.isArray(description) ? description : [description];

  // TODO width compute from breakpoint for screen size < 1000px
  return (
    <Modal
      title={
        <CenterFlex>
          <MarginBox mt={20}>
            <Text type={'h2'} disableGutter>
              {title}
            </Text>
          </MarginBox>
        </CenterFlex>
      }
      open={isOpen}
      closable
      onCancel={handleReject}
      width={width}
      footer={<></>}
    >
      <MarginBox mt={15}>
        <Flex direction={'column'} align={'center'} justify={'center'}>
          {icon && (
            <>
              <MarginBox mt={15} key={'icon'}>
                <Icon IconComponent={icon} height={64} width={64} color={color} />
              </MarginBox>
            </>
          )}
          {description && (
            <MarginBox mt={15} key={'description'}>
              {descriptionLines.map((line, index) => (
                <Flex direction={'column'} align={'center'} justify={'center'} key={`description-line-${index}`}>
                  <Text type={'lead'}>{`${line}`}</Text>
                </Flex>
              ))}
            </MarginBox>
          )}
          {content && (
            <MarginBox mt={15} key={'content'}>
              {content}
            </MarginBox>
          )}
        </Flex>
      </MarginBox>
    </Modal>
  );
};

export type Disclosure = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
};

export function useDisclosure(): Disclosure {
  const [isOpen, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const onToggle = () => setOpen(!isOpen);
  return { isOpen, onOpen, onClose, onToggle };
}

export interface DialogProps extends GeneralDialogProps {
  status?: StatusType;
  width?: number;
}

export function Dialog({
  handleConfirm,
  handleCancel,
  title,
  description,
  content,
  icon,
  status,
  maskClosable,
  closable,
  disclosure,
  buttonsText,
  width = 700,
  isConfirmButtonDisabled,
}: DialogProps) {
  const theme = useTheme();
  const color = colorSelector(theme, status);

  return (
    <>
      {disclosure.isOpen && (
        <ConfirmationDialog
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          title={title}
          description={description}
          icon={icon}
          content={content}
          color={color}
          closable={closable}
          maskClosable={maskClosable}
          disclosure={disclosure}
          buttonsText={buttonsText}
          width={width}
          isConfirmButtonDisabled={isConfirmButtonDisabled}
        />
      )}
    </>
  );
}

export function InfoDialog({
  handleConfirm,
  handleCancel,
  title,
  description,
  content,
  icon,
  status,
  disclosure,
  width = 700,
}: DialogProps) {
  const theme = useTheme();
  const color = colorSelector(theme, status);

  return (
    <>
      {disclosure.isOpen && (
        <InformationDialog
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          title={title}
          description={description}
          icon={icon}
          content={content}
          color={color}
          disclosure={disclosure}
          width={width}
        />
      )}
    </>
  );
}
