import { Table } from 'antd';
import styled, { css } from 'styled-components';
import { theme } from 'styles';
import { CleverInput, Flex, InputNumber, InputNumberProps, InputPrice, InputPriceProps, Select } from 'UI';

export const STable = styled(Table)`
  thead > tr > th {
    text-align: center;
    white-space: nowrap;
  }

  tbody > tr.ant-table-row {
    :hover {
      box-shadow: 0 3px 10px rgb(0 0 0 / 30%);
    }
  }
` as typeof Table;

const checkEmptyInput = css<{ $isEmpty?: boolean }>`
  border: ${({ theme, $isEmpty }) => ($isEmpty ? `1px solid ${theme.color.red}` : '')};
  background-color: ${({ theme, $isEmpty }) => ($isEmpty ? theme.color.very_light_pink : '')};
`;

const commonSInvalidInputBorderColor = css<{ $isInvalid?: boolean }>`
  border: ${({ theme, $isInvalid }) => ($isInvalid ? `1px solid ${theme.color.red}` : '')};
`;

const commonSInvalidInputBackgroundColor = css<{ $isInvalid?: boolean }>`
  background-color: ${({ theme, $isInvalid }) => ($isInvalid ? theme.color.very_light_pink : '')};
`;
const commonSInvalidInputColor = css<{ $isInvalid?: boolean }>`
  color: ${({ theme, $isInvalid }) => ($isInvalid ? theme.color.red : '')};
`;

const commonSInvalidInput = css<{ $isInvalid?: boolean }>`
  ${commonSInvalidInputBorderColor}
  ${commonSInvalidInputBackgroundColor}
  ${commonSInvalidInputColor}
`;

export const commonSInput = css<{ $isInvalid?: boolean }>`
  text-align: center;

  .ant-input,
  .ant-input-group-addon {
    ${commonSInvalidInput}
  }

  .ant-input::placeholder {
    color: ${({ theme, $isInvalid }) => ($isInvalid ? theme.color.red : '')};
  }
`;

export const SInputNumber = styled(InputNumber)<InputNumberProps & { $isInvalid?: boolean }>`
  ${commonSInput}
`;

export const SInputPrice = styled(InputPrice)<InputPriceProps & { $isInvalid?: boolean }>`
  ${commonSInput}
`;

export const SQuantityModuleFlex = styled(Flex)<{ $isInvalid?: boolean }>`
  ${commonSInput}
`;

export const SDiscountInputNumber = styled(InputNumber)<InputNumberProps & { $isInvalid?: boolean }>`
  text-align: center;
  ${commonSInvalidInput}
`;

export const SInput = styled(CleverInput)<{ checkEmpty: boolean; $isEmpty?: boolean; $isInvalid?: boolean }>`
  ${({ readOnly, $isInvalid, $isEmpty }) => {
    if (readOnly && $isInvalid) {
      return css`
        color: ${theme.color.red};
      `;
    }
    if (!readOnly) {
      if ($isInvalid) {
        if ($isEmpty) {
          return css`
            ${commonSInvalidInput}
          `;
        } else {
          return css`
            ${commonSInvalidInputColor}
            :focus {
              border: 1px solid ${({ theme }) => theme.color.brand_light};
              box-shadow: 0 0 0 2px ${({ theme }) => theme.color.brand_background};
              background-color: white;
            }
          `;
        }
      } else {
        return css`
          :focus {
            border: 1px solid ${({ theme }) => theme.color.brand_light};
            box-shadow: 0 0 0 2px ${({ theme }) => theme.color.brand_background};
            background-color: white;
          }
        `;
      }
    }
  }};
  ${({ checkEmpty }) => checkEmpty && checkEmptyInput};

  .ant-input,
  .ant-input-group-addon {
    ${commonSInvalidInput}
`;

export const TooltipContent = styled.div`
  display: flex;
  flex: 1;
`;

export const SSelect = styled(Select)<{ $isInvalid?: boolean }>`
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  ${commonSInvalidInput}
  .ant-select-arrow {
    color: ${({ theme, $isInvalid }) => ($isInvalid ? theme.color.red : '')};
  }
`;

export const ExpandedLaborTimeWrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.color.grey95};
  padding: 15px 60px;
`;

export const ExpandedReferenceWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.grey95};
  padding: 25px 15px;
`;

export const ExpandedLaborTimeDotCircleWrapper = styled.div`
  position: relative;
  left: -4px;
  z-index: 1;
`;

export const ExpandedReferenceDotCircleWrapper = styled.div`
  position: relative;
  left: -4.5px;
  top: -13px;
  z-index: 1;
`;

export const LinkedReferencesBackgroundWrapper = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  left: 45px;
  z-index: 1;
  padding-bottom: 40px;
`;
