import { useEffect, useState } from 'react';

export type IndexType = string | null;
export const svgElementPlateId = 'svg-plate-detail-';
export const svgElementPlateModalId = 'svg-plate-detail-modal-';
export const svgTextPosition = 'svg g g text';

export function useDuplicateSvgLinesAndNumber(
  svgElement: string | JSX.Element | undefined,
  svgId: string,
  elementId: string,
  svgTextPosition: string,
  getSiblingsById: boolean,
  indexesLength: number,
): void {
  const [lastPlateId, setLastPlateId] = useState('');

  useEffect(() => {
    if (elementId !== lastPlateId && typeof svgElement === 'object') {
      addInvisibleLinesAndNumber(svgId, elementId, svgTextPosition, getSiblingsById, indexesLength);
      setLastPlateId(elementId);
    }
  }, [lastPlateId, getSiblingsById, elementId, svgElement, svgId, svgTextPosition, indexesLength]);
}

export function getElementAndSiblingsById(elementId: string): Element[] {
  try {
    const l = document.querySelectorAll(`[id^=${elementId}]`);
    return Array.from(l);
  } catch (err) {
    return [];
  }
}

export function getElementAndSiblings(element: Element): Element[] {
  let e = element;
  const list = [e];

  while (e.nextElementSibling !== null) {
    e = e.nextElementSibling;
    list.push(e);
  }

  return list;
}

export const getSvgTextElementsFromDocument = (
  svgId: string,
  plateId: string,
  query: string,
): NodeListOf<Element> | undefined => document.getElementById(`${svgId}${plateId}`)?.querySelectorAll(query);

export const addInvisibleLinesAndNumber = (
  svgId: string,
  plateId: string,
  svgTextPosition: string,
  getSiblingsById: boolean,
  indexesLength: number,
): void => {
  const svgTextElements = getSvgTextElementsFromDocument(svgId, plateId, svgTextPosition);
  if (!svgTextElements || svgTextElements.length > indexesLength + 1) {
    return;
  }
  svgTextElements?.forEach((indexElement) => {
    const linesAndNumber = getSiblingsById
      ? getElementAndSiblingsById(indexElement.id)
      : getElementAndSiblings(indexElement);
    const lines = linesAndNumber.slice(1);

    const indexParent = indexElement.parentElement;
    const clonedIndex = indexElement.cloneNode(true) as Element;
    clonedIndex.classList.add('invisible-index');
    changeNumberAttribute(clonedIndex, indexElement, 'x', -1);
    changeNumberAttribute(clonedIndex, indexElement, 'y', 0);
    indexParent?.append(clonedIndex);

    lines.forEach((elem) => {
      const clonedElem = elem.cloneNode(true) as Element;
      clonedElem.classList.add('invisible-line');
      indexParent?.append(clonedElem);
    });
  });
};

function changeNumberAttribute(toElement: Element, fromElement: Element, attribute: string, change: number) {
  const newValue = Number(fromElement.getAttribute(attribute)) + change;
  toElement.setAttribute(attribute, newValue.toString());
}
