import { TextFacetItem } from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/response/GetIAMReferencesResponse';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { TireItem } from '@1po/1po-bff-fe-spec/generated/tire/response/TireSearchResponse';
import { TFunction } from 'i18next';
import {
  ENTRY_BRAND_LABEL,
  EXTRA_LOAD_LABEL,
  FILTER_BRAND,
  FILTER_DELIVERY,
  FILTER_FEATURES,
  FILTER_FUEL_EFFICIENCY,
  FILTER_NOISE_LEVEL,
  FILTER_PRODUCT_RANGE,
  FILTER_SEASON,
  FILTER_WET_GRIP,
  Filters,
  isFilteredStocks,
  isNotInRange,
  MEDIUM_BRAND_LABEL,
  PMSF_LABEL,
  POSITIVE_FEATURE_VALUES,
  PREMIUM_BRAND_LABEL,
  RangeFacetLocal,
  RUN_FLAT_LABEL,
  SELF_SEAL_LABEL,
  TextFacetLocal,
} from 'components/Filter/Filter.types';
import { ReferencePriceType } from 'domains/references';
import { TireBrandDetailLocal } from 'domains/tires/Tire.types';
import { GarageView, SparePartsViewType } from 'domains/user';
import { tireBrandsData } from 'pages/TiresPage/TireSearch/TiresSearchbar/TireBrandsData';
import { FOUND, getData, hasData, NO_DATA, SearchData } from 'utils';

const handleFeaturesFilter = (tfi: TextFacetItem, tire: TireItem): boolean => {
  switch (tfi.label) {
    case SELF_SEAL_LABEL:
      return tire.selfSeal ? POSITIVE_FEATURE_VALUES.includes(tire.selfSeal) : false;
    case PMSF_LABEL:
      return tire.pmsf ? POSITIVE_FEATURE_VALUES.includes(tire.pmsf) : false;
    case EXTRA_LOAD_LABEL:
      return tire.extraLoad ? tire.extraLoad.length > 0 : false;
    case RUN_FLAT_LABEL:
      return tire.runFlat ? tire.runFlat.length > 0 : false;
    default:
      return false;
  }
};

const handleProductRangeFilter = (
  tfi: TextFacetItem,
  tire: TireItem,
  brandCategoriesMap: SearchData<Map<string, TireBrandDetailLocal>>,
): boolean => {
  if (tire.brandId && brandCategoriesMap.searchStatus === FOUND) {
    const tireBrandDetail = brandCategoriesMap.data?.get(tire.brandId);
    switch (tfi.label) {
      case PREMIUM_BRAND_LABEL:
        return tireBrandDetail?.category === PREMIUM_BRAND_LABEL;
      case MEDIUM_BRAND_LABEL:
        return tireBrandDetail?.category === MEDIUM_BRAND_LABEL;
      case ENTRY_BRAND_LABEL:
        return tireBrandDetail?.category === ENTRY_BRAND_LABEL;
      default:
        return false;
    }
  }
  return false;
};
const getResultItems = (
  tf: TextFacetLocal,
  tfi: TextFacetItem,
  filteredTires: TireItem[],
  stocks: Map<string, SearchData<ReferenceStock> | undefined>,
  brandCategoriesMap: SearchData<Map<string, TireBrandDetailLocal>>,
  t: TFunction,
): TireItem[] => {
  return filteredTires.filter((tire) => {
    const stock = stocks.get(tire.partNumber);
    switch (tf.id) {
      case FILTER_BRAND: {
        const b = tireBrandsData.find((tireBrand) => tire.brandId === tireBrand.brandId);
        return b ? b.name === tfi.label : tfi.label === tire?.brandName;
      }
      case FILTER_SEASON:
        return tfi.label === tire?.season;
      case FILTER_DELIVERY: {
        const tfiTextFilter = new Map();
        tfiTextFilter.set(FILTER_DELIVERY, [tfi.label]);
        return isFilteredStocks(stock, { textFilters: tfiTextFilter, rangeFilters: new Map() }, t);
      }
      case FILTER_FUEL_EFFICIENCY:
        return tfi.label === tire?.fuelConsumption;
      case FILTER_WET_GRIP:
        return tfi.label === tire?.wetGrip;
      case FILTER_NOISE_LEVEL:
        return tfi.label === tire?.exteriorSound;
      case FILTER_FEATURES:
        return handleFeaturesFilter(tfi, tire);
      case FILTER_PRODUCT_RANGE:
        return handleProductRangeFilter(tfi, tire, brandCategoriesMap);
      default:
        return false;
    }
  });
};

export const getUpdatedTireTextFilters = (
  textFilters: TextFacetLocal[],
  stocks: Map<string, SearchData<ReferenceStock> | undefined>,
  filteredTires: TireItem[],
  brandCategoriesMap: SearchData<Map<string, TireBrandDetailLocal>>,
  t: TFunction,
): TextFacetLocal[] =>
  textFilters.map((tf) => {
    return {
      ...tf,
      items: tf.items.map((tfi) => {
        const resultItems = getResultItems(tf, tfi, filteredTires, stocks, brandCategoriesMap, t);
        return { ...tfi, numberOfItems: resultItems.length };
      }),
    };
  });

export const getUpdatedTireRangeFilters = (
  rangeFilters: RangeFacetLocal[],
  filteredTires: TireItem[],
): RangeFacetLocal[] => {
  return rangeFilters.map((rf) => {
    if (rf.id === 'prices') {
      return { ...rf, numberOfItems: filteredTires.length };
    } else {
      return rf;
    }
  });
};

export const getTireDeliveryFilterOptions = (
  stocksFilterOptions: TextFacetLocal,
  stocks: Map<string, SearchData<ReferenceStock> | undefined>,
  filteredTires: TireItem[],
  t: TFunction,
): TextFacetLocal => {
  const getItems: TextFacetItem[] = stocksFilterOptions.items.filter((soi) => {
    return [...stocks.values()].some((s) => {
      const soiTextFilter = new Map();
      soiTextFilter.set('delivery', [soi.label]);
      return isFilteredStocks(s, { textFilters: soiTextFilter, rangeFilters: new Map() }, t);
    });
  });
  const newItemsMap = new Map<string, TextFacetItem>();
  getItems.forEach((tfi) => {
    const resultItems = filteredTires.filter((reference) => {
      const stock = stocks.get(reference.partNumber);
      const tfiTextFilter: Map<string, string[]> = new Map();
      tfiTextFilter.set('delivery', [tfi.label]);
      return isFilteredStocks(stock, { textFilters: tfiTextFilter, rangeFilters: new Map() }, t);
    });
    newItemsMap.set(tfi.label, { label: tfi.label, numberOfItems: resultItems.length });
  });

  return {
    ...stocksFilterOptions,
    items: Array.from([...newItemsMap], ([label, value]) => ({
      numberOfItems: value.numberOfItems,
      label,
    })),
  };
};

const refPrice = (
  sparePartsView: SparePartsViewType,
  item: { reference: string; prices?: ReferencePriceType | NO_DATA },
) => {
  return Number(
    sparePartsView === GarageView
      ? getData(item.prices)?.garageView?.vatExcludedPrice
      : getData(item.prices)?.clientView?.recommendedPriceVatIncluded,
  );
};

const handleFeaturesFilterArray = (filterItems: string[], tire: TireItem): boolean => {
  return filterItems.every((filter) => {
    if (filter === SELF_SEAL_LABEL) {
      return tire.selfSeal ? !POSITIVE_FEATURE_VALUES.includes(tire.selfSeal) : true;
    }
    if (filter === PMSF_LABEL) {
      return tire.pmsf ? !POSITIVE_FEATURE_VALUES.includes(tire.pmsf) : true;
    }
    if (filter === EXTRA_LOAD_LABEL) {
      return tire.extraLoad ? tire.extraLoad.length === 0 : true;
    }
    if (filter === RUN_FLAT_LABEL) {
      return tire.runFlat ? tire.runFlat.length === 0 : true;
    }
    return true;
  });
};

const isFilterApplicableToTire = (
  filters: Filters,
  tire: TireItem,
  stocks: Map<string, SearchData<ReferenceStock> | undefined>,
  brandCategoriesMap: SearchData<Map<string, TireBrandDetailLocal>>,
  t: TFunction,
): boolean => {
  const stock = stocks.get(tire.partNumber);
  const isTextFiltered = [...filters.textFilters.keys()].some((facetId) => {
    const filterItems = filters.textFilters.get(facetId) ?? [];
    switch (facetId) {
      case FILTER_BRAND: {
        const currTireBrand = tireBrandsData.find((tireBrand) => tire.brandId === tireBrand.brandId);
        return currTireBrand && !filterItems.includes(currTireBrand.name);
      }
      case FILTER_SEASON:
        return !filterItems.includes(tire?.season ?? '');
      case FILTER_DELIVERY:
        return !isFilteredStocks(stock, filters, t);
      case FILTER_FUEL_EFFICIENCY:
        return !filterItems.includes(tire?.fuelConsumption ?? '');
      case FILTER_WET_GRIP:
        return !filterItems.includes(tire?.wetGrip ?? '');
      case FILTER_NOISE_LEVEL:
        return !filterItems.includes(tire?.exteriorSound ?? '');
      case FILTER_FEATURES:
        return handleFeaturesFilterArray(filterItems, tire);
      case FILTER_PRODUCT_RANGE: {
        if (brandCategoriesMap.searchStatus === FOUND && tire.brandId) {
          const tireBrandDetail = brandCategoriesMap.data?.get(tire.brandId)?.category;
          return !filterItems.includes(tireBrandDetail ?? '');
        }
        return false;
      }
      default:
        return false;
    }
  });
  return !isTextFiltered;
};

export const getFilteredTires = (
  availableTires: TireItem[],
  filters: Filters,
  prices: { prices: NO_DATA | ReferencePriceType; reference: string }[],
  sparePartsView: SparePartsViewType,
  showPriceless: boolean,
  stocks: Map<string, SearchData<ReferenceStock> | undefined>,
  brandCategoriesMap: SearchData<Map<string, TireBrandDetailLocal>>,
  t: TFunction,
): TireItem[] => {
  const textFilteredReferences = availableTires.filter((tire) =>
    isFilterApplicableToTire(filters, tire, stocks, brandCategoriesMap, t),
  );
  const priceFilterRange = filters.rangeFilters.get('prices');
  const pricesInRange = prices.filter((price) => {
    if (!priceFilterRange) {
      return true;
    }
    return !(
      isNotInRange([String(refPrice(sparePartsView, price))], {
        min: priceFilterRange.min,
        max: priceFilterRange.max,
      }) ||
      (!hasData(price) && showPriceless)
    );
  });
  return textFilteredReferences.filter((i) =>
    pricesInRange.find((priceInRange) => priceInRange.reference === i.partNumber),
  );
};
