import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MinusIcon, PlusIcon } from 'assets/icons';
import { theme } from 'styles';
import { BadgeComponent, ClockButton, Icon, InfoCircleButton, WithTooltip } from 'UI';

const ButtonWrapper = styled.div`
  width: fit-content;
  height: fit-content;
`;

interface ReferenceSectionButtonProps {
  isOpen: boolean;
  handleClick: () => void;
}

export const AdditionalInformationButton = ({ isOpen, handleClick }: ReferenceSectionButtonProps) => {
  const { t } = useTranslation();

  return (
    <WithTooltip title={t('catalog.details.additional_information', 'Additional information')} placement={'top'}>
      <ButtonWrapper>
        <BadgeComponent
          size={16}
          offset={[28, 25]}
          color={'none'}
          Component={
            <Icon
              IconComponent={isOpen ? MinusIcon : PlusIcon}
              size={14}
              color={theme.color.blue}
              hoverFill={theme.color.blue_dark}
              onClick={() => {
                handleClick();
              }}
            />
          }
        >
          <InfoCircleButton
            color={'brand_black'}
            bgColor={'grey95'}
            size={22}
            onClick={() => {
              handleClick();
            }}
            boxSize={'smd'}
          />
        </BadgeComponent>
      </ButtonWrapper>
    </WithTooltip>
  );
};
export const LaborTimesButton = ({ isOpen, handleClick }: ReferenceSectionButtonProps) => {
  const { t } = useTranslation();
  return (
    <WithTooltip title={t('catalog.parts.category.car_parts.labor_time', 'Labor time')} placement={'top'}>
      <ButtonWrapper>
        <BadgeComponent
          size={16}
          offset={[28, 25]}
          color={'none'}
          Component={
            <Icon
              IconComponent={isOpen ? MinusIcon : PlusIcon}
              size={14}
              color={theme.color.blue}
              hoverFill={theme.color.blue_dark}
              onClick={() => {
                handleClick();
              }}
            />
          }
        >
          <ClockButton
            color={'brand_black'}
            bgColor={'grey95'}
            size={22}
            onClick={() => {
              handleClick();
            }}
            boxSize={'smd'}
          />
        </BadgeComponent>
      </ButtonWrapper>
    </WithTooltip>
  );
};
