/* eslint-disable max-len */
import {
  LinkedReference,
  Reference as RQReference,
} from '@1po/1po-bff-fe-spec/generated/basket/request/model/Reference';
import { AddReferenceByRefNumber } from '@1po/1po-bff-fe-spec/generated/basket/response/AddReferenceByRefNumber';
import { BasketResponse } from '@1po/1po-bff-fe-spec/generated/basket/response/BasketResponse';
import { InvalidReferenceQuantityUpdateResponse } from '@1po/1po-bff-fe-spec/generated/basket/response/InvalidReferenceQuantityUpdateResponse';
import { Reference } from '@1po/1po-bff-fe-spec/generated/basket/response/model/Reference';
import { OtherReferenceMoveToVehicleConfirmResponse } from '@1po/1po-bff-fe-spec/generated/basket/response/OtherReferenceMoveToVehicleConfirmResponse';
import { OtherReferenceMoveToVehicleResponse } from '@1po/1po-bff-fe-spec/generated/basket/response/OtherReferenceMoveToVehicleResponse';
import { UploadReferencesFileResponse } from '@1po/1po-bff-fe-spec/generated/basket/response/UploadReferencesFileResponse';
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/model/Reference';
import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { all, call, put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { ROUTER_CART } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { calculateUrgentDeliveryAddition } from 'components/Discount';
import { NO_FILE } from 'components/FileUpload/paramsByState';
import {
  REF_ATTACH_CHECKING,
  REF_ATTACH_NON_COMPATIBLE,
  REF_ATTACH_OK,
  STATUS_MISSING_PRICE,
  STATUS_NOT_IN_STOCK,
  STATUS_SUCCESS,
  STATUS_UNKNOWN_REFERENCE,
} from 'domains/basket/Basket.types';
import { getLastVehicleDetail, getPlateReference, getVehicleContext } from 'domains/catalog/Catalog.store';
import { PlateReferencesDetail, STANDARD } from 'domains/catalog/Catalog.types';
import { disablePopins } from 'domains/firstHelp/FirstHelp.store';
import {
  DHReferenceLocal,
  getDHReference,
  getDHReferences,
  getDiscount,
  getIAMReference,
  getIAMReferences,
  getPrice,
  IAMReferenceLocal,
  ReferenceLocal,
} from 'domains/references';
import * as referenceActions from 'domains/references/References.store';
import { getTireSetParam } from 'domains/tires/Tire.store';
import { notifyTop } from 'UI';
import { AppTranslation, getData, LOADING, sagaGuard, select } from 'utils';
import {
  attachOtherNonApplicableReferenceToVehicle,
  attachOtherReferenceToVehicle,
  notifyUserAboutExpiredDiscounts,
  sendAddReferenceByRefNumber,
  sendAddReferences,
  sendGetBasket,
  sendRemoveReferencesRequest,
  sendRemoveSingleReference,
  sendRevertAttachOtherReference,
  sendRevertLastRemoval,
  sendSetReferenceMark,
  sendUpdateAllUrgencyFlags,
  sendUpdateOrderMark,
  sendUpdateReferenceQuantity,
  sendUpdateUrgencyFlag,
  sendUploadReferencesFileRequest,
} from './Basket.api';
import { getTradingDataMap, mapTire } from './Basket.mapper';
import * as actions from './Basket.store';
import {
  addOtherReference,
  addVehicleReferences,
  getBasketExternalSection,
  getBasketId,
  getBasketOtherSection,
  getBasketReferenceTradingData,
  getBasketVehicles,
  moveOtherReferenceToVehicle,
  removeReference,
  removeReferences,
  setActionStatus,
  setBasket,
  setBasketIdStatus,
  setFileReferencesUploadStatusErrorRows,
  setFileReferencesUploadStatusIsModalOpen,
  setFileReferencesUploadStatusModalStatus,
  setOtherReferenceAttachStatus,
  updateReferenceQuantity,
  updateTradingDataMap,
} from './Basket.store';
import { getUserContext } from '../user';

export function* fetchBasketRequest(): SagaIterator {
  yield put(setBasketIdStatus(LOADING));
  yield put(sendGetBasket());
}

export function* fetchBasketResponse(action: WsResponse<BasketResponse>): SagaIterator {
  const { basketId, vehicles, otherSection, expiredReferenceDiscounts, externalBaskets } = action.payload;
  const currentTradingData = yield* select(getBasketReferenceTradingData);

  const isBasketVehicles = vehicles.length > 0;
  const isBasketOthers = otherSection.references.length > 0;

  if (!isBasketVehicles && !isBasketOthers) {
    yield put(disablePopins([`${ROUTER_CART}_add_comment`, `${ROUTER_CART}_link_to_veh`]));
  }
  if (!isBasketOthers || (isBasketOthers && !isBasketVehicles)) {
    yield put(disablePopins([`${ROUTER_CART}_link_to_veh`]));
  }

  yield put(
    setBasket({
      basketId,
      vehicles,
      otherSection,
      expiredReferenceDiscounts,
      rememberIsSelected: true,
      externalBaskets,
    }),
  );

  yield put(updateTradingDataMap({ vehicles, otherSection, externalBaskets }));

  // fetch missing prices and stocks for basket
  const tradingDataMap = getTradingDataMap(vehicles, externalBaskets, otherSection);
  const missingReferences = [...tradingDataMap.values()].filter(
    (ref) => currentTradingData.get(ref.referenceNumber) === undefined,
  );

  const stockDiff = [...tradingDataMap.values()]
    .filter((ref) => {
      const previous = currentTradingData.get(ref.referenceNumber);
      return previous === undefined || previous.quantity !== ref.quantity;
    })
    .map((ref) => {
      return {
        referenceNumber: ref.referenceNumber,
        type: ref.type,
        quantity: ref.quantity,
        origin: ref.origin,
        supplierCode: ref.supplierCode,
        referenceSource: ref.referenceSource,
      };
    });
  if (missingReferences.length > 0) {
    yield put(
      referenceActions.fetchReferencesPriceRequestSaga({
        references: missingReferences.map((r) => {
          return {
            referenceNumber: r.referenceNumber,
            commercialFamily: r.commercialFamily,
            supplierCode: r.supplierCode,
            origin: r.origin,
            referenceSource: r.referenceSource,
          };
        }),
      }),
    );
  }
  if (stockDiff.length > 0) {
    yield put(
      referenceActions.fetchReferencesStocksRequestSaga({
        data: stockDiff,
        fetchEvenIfExists: true,
      }),
    );
  }
}

function* mapReference(reference: ReferenceLocal): Generator<any, RQReference, any> {
  const discount = yield* select((state: RootState) => getDiscount(state, reference.referenceNumber));
  const priceSelect = yield* select((state: RootState) => getPrice(state, reference.referenceNumber));
  const price = getData(priceSelect);
  const catalogSource = reference.source === 'dh' ? 'DATAHUB' : 'IAM';
  const name = reference.source === 'iam' ? mapIAMReferenceName(reference) : mapDHReferenceName(reference);
  const brand = reference.source === 'iam' ? mapIAMReferenceBrand(reference) : reference.brand;
  return {
    referenceNumber: reference.referenceNumber,
    name: name ?? '',
    price: {
      priceVatExcluded: price?.garageView?.vatExcludedPrice?.toString() ?? '0',
      priceVatIncluded: price?.garageView?.vatIncludedPrice?.toString() ?? '0',
      vatExcludedUrgentDeliveryAdditionalPrice: calculateUrgentDeliveryAddition(price, discount, false).toString(),
      vatIncludedUrgentDeliveryAdditionalPrice: calculateUrgentDeliveryAddition(price, discount, true).toString(),
    },
    quantity: 1,
    brand: brand ?? 'OTHER',
    familyCode: (reference.source === 'dh' ? reference.familyCode : reference.commercialFamily) ?? '',
    isCompatible: reference.source === 'dh' ? reference.isApplicableToCurrentVehicle ?? true : true,
    type: reference.type,
    origin: reference.source === 'iam' ? reference.origin : undefined,
    supplier: reference.source === 'iam' ? reference.supplier : undefined,
    supplierCode: reference.source === 'iam' ? reference.supplierCode : undefined,
    referenceSource: reference.source === 'iam' ? reference.referenceSource : undefined,
    tireDimensions: reference.source === 'dh' ? reference?.tireDetails?.designation : undefined,
    catalogSource,
  };
}

const mapIAMReferenceName = (ref: IAMReferenceLocal) => {
  const rawSupplier = ref.supplierReferences?.filter((r) => r.type === 'RAW');
  const designation = ref.designation ?? '';
  const displayName = ref.name ?? designation;
  const rawLabel = rawSupplier && rawSupplier.length > 0 ? rawSupplier[0].id : null;

  const displayLabel = ref.supplier && displayName ? `${displayName} - ${ref.supplier}` : displayName || ref.supplier;

  if (ref.supplier !== 'MOTRIO') {
    return `${displayLabel} ${rawLabel}`;
  }

  return displayLabel;
};

const mapDHReferenceName = (dhRef: DHReferenceLocal) => {
  return dhRef.type === STANDARD ? dhRef.name : dhRef?.tireDetails?.profile;
};

const mapIAMReferenceBrand = (ref: IAMReferenceLocal): ReferenceBrandType | undefined => {
  if (ref.supplier === 'MOTRIO') {
    return 'MOTRIO';
  }
  return undefined;
};

function* mapLinkedReference(reference: ReferenceLocal, quantityRatio: number): Generator<any, LinkedReference, any> {
  const discount = yield* select((state: RootState) => getDiscount(state, reference.referenceNumber));
  const priceSelect = yield* select((state: RootState) => getPrice(state, reference.referenceNumber));

  const price = getData(priceSelect);

  return {
    referenceNumber: reference.referenceNumber,
    name: reference.name ?? '',
    price: {
      priceVatExcluded: price?.garageView?.vatExcludedPrice?.toString() ?? '0',
      priceVatIncluded: price?.garageView?.vatIncludedPrice?.toString() ?? '0',
      vatExcludedUrgentDeliveryAdditionalPrice: calculateUrgentDeliveryAddition(price, discount, false).toString(),
      vatIncludedUrgentDeliveryAdditionalPrice: calculateUrgentDeliveryAddition(price, discount, true).toString(),
    },
    brand: reference.brand ?? 'OTHER',
    familyCode: reference.source === 'dh' ? reference.familyCode ?? '' : '',
    isCompatible: reference.source === 'dh' ? reference.isApplicableToCurrentVehicle ?? true : true,
    type: reference.type,
    quantityRatio,
  };
}

export function* mapReferences(
  ref: ReferenceLocal,
  plateRef: PlateReferencesDetail | undefined,
  vehicleKey: string | undefined,
): Generator<any, RQReference, any> {
  const linkedReferenceNumbers =
    ref.source === 'dh' ? (plateRef ?? ref).linkedReferences?.map((linkedRef) => linkedRef.referenceNumber) : [];
  const linkedRefs = yield* select((state: RootState) =>
    getDHReferences(state, {
      vehicleKey,
      referenceNumbers: linkedReferenceNumbers ?? [],
    }),
  );
  const reference: RQReference = yield call(mapReference, ref);

  reference.linkedReferences = yield all(
    linkedRefs.map((r) => {
      const linkedRefDetail =
        ref.source === 'dh'
          ? ref.linkedReferences?.find((linkedRef) => linkedRef.referenceNumber === r.referenceNumber)
          : undefined;

      const quantityRatio = linkedRefDetail?.ratio ?? 1;
      return call(mapLinkedReference, r, quantityRatio);
    }),
  );
  return reference;
}

export function* addReferenceRequest({ payload }: ReturnType<typeof actions.addReferenceRequest>): SagaIterator {
  const referenceNumbers = payload.referenceNumber;
  const dhPlateReference = payload.index ? payload : undefined;
  const vehicleDetail = yield* select(getLastVehicleDetail);

  const dhPlateReferencesData =
    dhPlateReference && vehicleDetail?.vehicleKey
      ? yield* select((state: RootState) =>
          getPlateReference(state, {
            plateId: dhPlateReference.plateId,
            vehicleKey: vehicleDetail.vehicleKey,
            index: dhPlateReference.index,
          }),
        )
      : undefined;

  // Search references for vehicle
  const vehicleRefsLocal =
    vehicleDetail?.vehicleKey === undefined
      ? []
      : yield* select((state: RootState) =>
          vehicleDetail?.catalogSource === 'DATAHUB'
            ? getDHReferences(state, { referenceNumbers: [referenceNumbers], vehicleKey: vehicleDetail.vehicleKey })
            : getIAMReferences(state, { referenceNumbers: [referenceNumbers], vehicleKey: vehicleDetail.vehicleKey }),
        );

  // If references are not present under vehicle, try search them in non-vehicle-specific section
  const otherRefsLocal =
    vehicleRefsLocal.length > 0
      ? undefined
      : yield* select((state: RootState) =>
          vehicleDetail?.catalogSource === 'DATAHUB'
            ? getDHReferences(state, { referenceNumbers: [referenceNumbers], vehicleKey: undefined })
            : getIAMReferences(state, { referenceNumbers: [referenceNumbers], vehicleKey: undefined }),
        );

  // Add to other section and do not continue
  if (otherRefsLocal !== undefined) {
    yield all(otherRefsLocal.map((reference) => put(actions.addOtherSectionReferenceRequest({ reference }))));
    return;
  }

  // Add to vehicle section
  if (!vehicleDetail || vehicleRefsLocal?.length === 0) return;
  const basketId = yield* select(getBasketId);
  const userContext = yield* select(getUserContext);
  const vehicleContext = yield* select((state: RootState) => getVehicleContext(state, vehicleDetail.vehicleKey));

  const references: Reference[] = yield all(
    vehicleRefsLocal.map((r) => call(mapReferences, r, getData(dhPlateReferencesData), vehicleDetail?.vehicleKey)),
  );

  yield put(addVehicleReferences({ vehicleDetail, references }));
  yield put(
    sendAddReferences({
      basketId,
      userContext,
      vehicleContext,
      references,
      vehicleDetail,
    }),
  );
}

export function* addTireRequest({ payload }: ReturnType<typeof actions.addTireRequest>): SagaIterator {
  const tire = payload;
  const basketId = yield* select(getBasketId);
  const tireSetParam = yield* select(getTireSetParam);
  const discount = yield* select((state: RootState) => getDiscount(state, tire.partNumber));
  const priceSelect = yield* select((state: RootState) => getPrice(state, tire.partNumber));
  const price = getData(priceSelect);
  const userContext = yield* select(getUserContext);

  if (!price) return;
  const reference = mapTire(tire, price, discount, tireSetParam);

  yield put(addOtherReference({ reference }));
  yield put(
    sendAddReferences({
      basketId,
      userContext,
      references: [reference],
    }),
  );
}

export function* addOtherReferenceRequest({
  payload,
}: ReturnType<typeof actions.addOtherReferenceRequest>): SagaIterator {
  const ref = yield* select((state: RootState) =>
    payload.isDH
      ? getDHReference(state, { referenceNumber: payload.reference, vehicleKey: undefined })
      : getIAMReference(state, { referenceNumber: payload.reference, vehicleKey: undefined }),
  );

  const reference = getData(ref);
  if (reference !== undefined) {
    yield put(actions.addOtherSectionReferenceRequest({ reference }));
  }
}

export function* addOtherSectionReferenceRequest({
  payload,
}: ReturnType<typeof actions.addOtherSectionReferenceRequest>): SagaIterator {
  const referenceLocal = payload.reference;
  if (!referenceLocal) return;

  const basketId = yield* select(getBasketId);
  const userContext = yield* select(getUserContext);

  const reference: RQReference = yield call(mapReferences, referenceLocal, undefined, undefined);

  yield put(addOtherReference({ reference }));
  yield put(
    sendAddReferences({
      basketId,
      userContext,
      references: [reference],
    }),
  );
}

export function* addReferenceByRefNumberRequest({
  payload,
}: ReturnType<typeof actions.addReferenceByRefNumberRequest>): SagaIterator {
  const { reference } = payload;
  const userContext = yield* select(getUserContext);
  const basketId = yield* select(getBasketId);
  yield put(sendAddReferenceByRefNumber(basketId, reference, userContext));

  yield put(
    setActionStatus({
      reference,
      status: LOADING,
    }),
  );
}

export function* addReferenceByRefNumberResponse(action: WsResponse<AddReferenceByRefNumber>): SagaIterator {
  const { referenceNumber, result } = action.payload;

  function mapStatus(result: 'OK' | 'MISSING_PRICE' | 'NOT_IN_STOCK' | 'UNKNOWN_REFERENCE') {
    switch (result) {
      case 'OK':
        return STATUS_SUCCESS;
      case 'UNKNOWN_REFERENCE':
        return STATUS_UNKNOWN_REFERENCE;
      case 'MISSING_PRICE':
        return STATUS_MISSING_PRICE;
      case 'NOT_IN_STOCK':
        return STATUS_NOT_IN_STOCK;
    }
  }

  const status = mapStatus(result);

  yield put(
    setActionStatus({
      reference: referenceNumber,
      status,
    }),
  );
}

export function* removeReferencesRequest({
  payload,
}: ReturnType<typeof actions.removeReferencesRequest>): SagaIterator {
  const { vehiclesWithReferences, otherReferences, externalBasketsWithReferences } = payload;
  const basketId = yield* select(getBasketId);

  if (!basketId) return;

  yield put(removeReferences({ vehiclesWithReferences, otherReferences, externalBasketsWithReferences }));
  yield put(
    sendRemoveReferencesRequest({
      externalBasketsWithReferences: [],
      vehiclesWithReferences: [],
      otherReferences: [],
      ...payload,
      basketId,
    }),
  );
}

export function* removeSingleReferenceRequest({
  payload,
}: ReturnType<typeof actions.removeSingleReferenceRequest>): SagaIterator {
  const { basketReferenceType, vehicleKey, reference, externalBasketId } = payload;
  const basketId = yield* select(getBasketId);

  if (!basketId) return;
  yield put(removeReference({ basketReferenceType, vehicleKey, reference, externalBasketId }));
  yield put(sendRemoveSingleReference({ ...payload, basketId }));
}

export function* revertLastRemovalRequest(): SagaIterator {
  const basketId = yield* select(getBasketId);
  if (!basketId) return;
  yield put(sendRevertLastRemoval({ basketId }));
}

export function* updateOrderMarkRequest({ payload }: ReturnType<typeof actions.updateOrderMarkRequest>): SagaIterator {
  const basketId = yield* select(getBasketId);
  if (!basketId) return;
  yield put(sendUpdateOrderMark({ ...payload, basketId }));
}

export function* updateReferenceMarkRequest({
  payload,
}: ReturnType<typeof actions.updateReferenceMarkRequest>): SagaIterator {
  const basketId = yield* select(getBasketId);
  if (!basketId) return;
  yield put(sendSetReferenceMark({ ...payload, basketId }));
}

export function* updateReferenceQuantityRequest({
  payload,
}: ReturnType<typeof actions.updateReferenceQuantityRequest>): SagaIterator {
  const { vehicleKey, referenceNumber, newQuantity, externalBasketId } = payload;
  const basketId = yield* select(getBasketId);
  if (!basketId) return;
  const vehicles = yield* select(getBasketVehicles);
  const otherSection = yield* select(getBasketOtherSection);
  const externalSections = yield* select(getBasketExternalSection);
  const externalBasket = externalBasketId
    ? externalSections.find((e) => e.externalBasketId === externalBasketId)
    : undefined;
  const reference = vehicleKey
    ? vehicles
        .find((veh) => veh.vehicleDetail.vehicleKey === vehicleKey)
        ?.references?.find((ref) => ref.referenceNumber === referenceNumber)
    : externalBasket
    ? externalBasket.references.find((ref) => ref.referenceNumber === referenceNumber)
    : otherSection.references.find((ref) => ref.referenceNumber === referenceNumber);

  const originalQuantity = reference?.quantity ?? 1;
  if (originalQuantity === newQuantity) {
    return;
  }
  const userContext = yield* select(getUserContext);
  yield put(updateReferenceQuantity({ vehicleKey, referenceNumber, newQuantity, externalBasketId }));
  yield put(
    sendUpdateReferenceQuantity({ ...payload, userContext, basketId, originalQuantity, type: payload.referenceType }),
  );
}

export function* uploadFileReferencesRequest({
  payload,
}: ReturnType<typeof actions.uploadFileReferencesRequest>): SagaIterator {
  const userContext = yield* select(getUserContext);
  const basketId = yield* select(getBasketId);
  yield put(sendUploadReferencesFileRequest({ ...payload, userContext, basketId }));
}

export function* uploadFileReferencesResponse(action: WsResponse<UploadReferencesFileResponse>): SagaIterator {
  const { errorRows } = action.payload;
  yield put(setFileReferencesUploadStatusErrorRows(errorRows));
  yield put(setFileReferencesUploadStatusIsModalOpen(false));
  yield put(setFileReferencesUploadStatusModalStatus(NO_FILE));
}

export function* updateUrgencyFlagRequest({
  payload,
}: ReturnType<typeof actions.updateUrgencyFlagRequest>): SagaIterator {
  const basketId = yield* select(getBasketId);
  if (!basketId) return;
  yield put(sendUpdateUrgencyFlag({ ...payload, basketId }));
}

export function* updateAllUrgencyFlagsRequest({
  payload,
}: ReturnType<typeof actions.updateAllUrgencyFlagsRequest>): SagaIterator {
  const basketId = yield* select(getBasketId);
  if (!basketId) return;
  yield put(sendUpdateAllUrgencyFlags({ ...payload, basketId }));
}

export function* confirmMoveNonCompatibleOtherSectionReferenceToVehicleResponse(
  action: WsResponse<OtherReferenceMoveToVehicleConfirmResponse>,
): SagaIterator {
  const { otherSectionReference, targetVehicleKey } = action.payload;

  if (otherSectionReference && targetVehicleKey) {
    yield put(
      setOtherReferenceAttachStatus({
        reference: otherSectionReference,
        vehicleKey: targetVehicleKey,
        type: REF_ATTACH_NON_COMPATIBLE,
      }),
    );
  }
}

export function* attachOtherReferenceToVehicleRequest({
  payload,
}: ReturnType<typeof actions.attachOtherReferenceToVehicleRequest>): SagaIterator {
  const { vehicleKey, referenceNumber } = payload;
  const basketId = yield* select(getBasketId);
  if (!basketId) return;
  const userContext = yield* select(getUserContext);
  yield put(setOtherReferenceAttachStatus({ reference: referenceNumber, vehicleKey, type: REF_ATTACH_CHECKING }));
  yield put(attachOtherReferenceToVehicle(basketId, vehicleKey, referenceNumber, userContext));
}

export function* attachOtherNonApplicableReferenceToVehicleRequest({
  payload,
}: ReturnType<typeof actions.attachOtherNonApplicableReferenceToVehicleRequest>): SagaIterator {
  const { vehicleKey, referenceNumber } = payload;
  if (!vehicleKey) return;
  const basketId = yield* select(getBasketId);
  if (!basketId) return;
  const userContext = yield* select(getUserContext);
  yield put(setOtherReferenceAttachStatus({ reference: referenceNumber, vehicleKey, type: REF_ATTACH_CHECKING }));
  yield put(attachOtherNonApplicableReferenceToVehicle(basketId, vehicleKey, referenceNumber, userContext));
}

export function* otherSectionReferenceMovedToVehicleResponse(
  action: WsResponse<OtherReferenceMoveToVehicleResponse>,
): SagaIterator {
  const payload = action.payload;
  const { otherSectionReference, targetVehicleKey, isCompatible } = payload;

  if (otherSectionReference) {
    yield put(
      moveOtherReferenceToVehicle({
        vehicleKey: targetVehicleKey,
        referenceNumber: otherSectionReference,
        isCompatible,
      }),
    );
    yield put(
      setOtherReferenceAttachStatus({
        reference: payload.otherSectionReference,
        vehicleKey: targetVehicleKey,
        type: REF_ATTACH_OK,
      }),
    );
  }
}

export function* revertMoveOtherReferenceToVehicle(): SagaIterator {
  const basketId = yield* select(getBasketId);
  if (!basketId) return;
  yield put(sendRevertAttachOtherReference({ basketId }));
}

export function* notifyUserAboutExpiredDiscountsRequest(): SagaIterator {
  const basketId = yield* select(getBasketId);
  if (!basketId) return;
  yield put(notifyUserAboutExpiredDiscounts({ basketId }));
}

export function* handleInvalidReferenceQuantityUpdate(
  action: WsResponse<InvalidReferenceQuantityUpdateResponse>,
): SagaIterator {
  const { referenceNumber, requestedQuantity, originalQuantity, externalBasketId, vehicleKey } = action.payload;
  const vehicles = yield* select(getBasketVehicles);
  const otherSection = yield* select(getBasketOtherSection);
  const externalSections = yield* select(getBasketExternalSection);
  const externalBasket = externalBasketId
    ? externalSections.find((e) => e.externalBasketId === externalBasketId)
    : undefined;
  const reference = vehicleKey
    ? vehicles
        .find((veh) => veh.vehicleDetail.vehicleKey === vehicleKey)
        ?.references?.find((ref) => ref.referenceNumber === referenceNumber)
    : externalBasket
    ? externalBasket.references.find((ref) => ref.referenceNumber === referenceNumber)
    : otherSection.references.find((ref) => ref.referenceNumber === referenceNumber);

  const currentQuantity = reference?.quantity ?? 0;

  if (currentQuantity === requestedQuantity) {
    yield put(
      updateReferenceQuantity({ vehicleKey, referenceNumber, externalBasketId, newQuantity: originalQuantity }),
    );
  }
  yield call(
    notifyTop,
    'error',
    AppTranslation.t(
      'cart.action.update_quantity.stock_validation_failed.notification.title',
      'Failed to update reference quantity',
    ),
    AppTranslation.t(
      'cart.action.update_quantity.stock_validation_failed.notification.description',
      'Failed to check stocks for reference {{ referenceNumber }}, please try again later ',
      {
        referenceNumber,
      },
    ) as string,
  );
}

export function* BasketSagas(): SagaIterator {
  yield takeEvery(actions.fetchBasketRequest.type, sagaGuard(fetchBasketRequest));
  yield takeEvery(actions.fetchBasketResponse.type, sagaGuard(fetchBasketResponse));
  yield takeEvery(actions.handleInvalidReferenceQuantityUpdate.type, sagaGuard(handleInvalidReferenceQuantityUpdate));
  yield takeEvery(actions.addReferenceRequest.type, sagaGuard(addReferenceRequest));
  yield takeEvery(actions.addTireRequest.type, sagaGuard(addTireRequest));
  yield takeEvery(actions.addOtherReferenceRequest.type, sagaGuard(addOtherReferenceRequest));
  yield takeEvery(actions.addOtherSectionReferenceRequest.type, sagaGuard(addOtherSectionReferenceRequest));
  yield takeEvery(actions.addReferenceByRefNumberRequest.type, sagaGuard(addReferenceByRefNumberRequest));
  yield takeEvery(actions.addReferenceByRefNumberResponse.type, sagaGuard(addReferenceByRefNumberResponse));
  yield takeEvery(actions.removeReferencesRequest.type, sagaGuard(removeReferencesRequest));
  yield takeEvery(actions.removeSingleReferenceRequest.type, sagaGuard(removeSingleReferenceRequest));
  yield takeEvery(actions.revertLastRemovalRequest.type, sagaGuard(revertLastRemovalRequest));
  yield takeEvery(actions.updateReferenceQuantityRequest.type, sagaGuard(updateReferenceQuantityRequest));
  yield takeEvery(actions.updateOrderMarkRequest.type, sagaGuard(updateOrderMarkRequest));
  yield takeEvery(actions.updateReferenceMarkRequest.type, sagaGuard(updateReferenceMarkRequest));
  yield takeEvery(actions.uploadFileReferencesRequest.type, sagaGuard(uploadFileReferencesRequest));
  yield takeEvery(actions.uploadFileReferencesResponse.type, sagaGuard(uploadFileReferencesResponse));
  yield takeEvery(actions.updateUrgencyFlagRequest.type, sagaGuard(updateUrgencyFlagRequest));
  yield takeEvery(actions.updateAllUrgencyFlagsRequest.type, sagaGuard(updateAllUrgencyFlagsRequest));
  yield takeEvery(actions.attachOtherReferenceToVehicleRequest.type, sagaGuard(attachOtherReferenceToVehicleRequest));
  yield takeEvery(
    actions.attachOtherNonApplicableReferenceToVehicleRequest.type,
    sagaGuard(attachOtherNonApplicableReferenceToVehicleRequest),
  );
  yield takeEvery(
    actions.confirmMoveNonCompatibleOtherSectionReferenceToVehicleResponse.type,
    sagaGuard(confirmMoveNonCompatibleOtherSectionReferenceToVehicleResponse),
  );
  yield takeEvery(
    actions.otherSectionReferenceMovedToVehicleResponse.type,
    sagaGuard(otherSectionReferenceMovedToVehicleResponse),
  );
  yield takeEvery(actions.revertMoveOtherReferenceToVehicle.type, sagaGuard(revertMoveOtherReferenceToVehicle));
  yield takeEvery(
    actions.notifyUserAboutExpiredDiscountsRequest.type,
    sagaGuard(notifyUserAboutExpiredDiscountsRequest),
  );
}
