import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { RootState } from 'app/AppStore';
import { BriefcaseIcon, CheckCircleIcon, ExclamationTriangleIcon, TimesCircleIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import {
  AccountListItem,
  fmt,
  ListAccounts,
} from 'components/Page/Header/TopHeader/Profile/DelegateSessionConnectionBox/ListAccounts';
import { ProfileCardItemProps } from 'components/Page/Header/TopHeader/Profile/ProfileCard';
import { SectionWrapper, SMenuItem } from 'components/Page/Header/TopHeader/TopHeader.styled';
import {
  connectAsUserSaga,
  DelegationSession,
  getDelegationSession,
  getDelegationSessionStatus,
  getPossibleDelegateSessionBuyers,
  getPossibleDelegateSessionSellers,
} from 'domains/user';
import {
  useFetchBuyersForSellersForDelegationSession,
  useFetchSellersForDelegationSession,
} from 'domains/user/User.requests';
import { theme } from 'styles';
import { Flex, Icon, IconType, MarginBox, Text, YellowButton } from 'UI';
import { ERROR, FOUND, LOADING, SEARCH_STATUS } from 'utils';

const DelegateSessionConnectionBox = (menuItem: ProfileCardItemProps) => {
  const delegationSession = useSelector(getDelegationSession);
  const [showConnectAs, setShowConnectAs] = useState<boolean>(Boolean(delegationSession));
  const [selectedSeller, setSelectedSeller] = useState<string | undefined>(undefined);
  const [selectedBuyer, setSelectedBuyer] = useState<string | undefined>(undefined);
  const [expandSellersSelector, setExpandSellersSelector] = useState(false);
  const [expandBuyersSelector, setExpandBuyersSelector] = useState(false);
  return (
    <>
      <div onClick={() => setShowConnectAs(!showConnectAs)}>
        <SMenuItem minHeight={50} align={'center'}>
          <MarginBox ml={21} mr={16}>
            <Icon IconComponent={menuItem.icon as IconType} size={32} display={'inline'} color={theme.color.grey00} />
          </MarginBox>
          <Text type={showConnectAs ? 'section_bold' : 'section'} cursor={'pointer'}>
            {menuItem.label}
          </Text>
        </SMenuItem>
      </div>
      {showConnectAs && (
        <DelegateSessionConnectionBoxBody
          selectedSeller={selectedSeller}
          setSelectedSeller={setSelectedSeller}
          selectedBuyer={selectedBuyer}
          setSelectedBuyer={setSelectedBuyer}
          expandSellersSelector={expandSellersSelector}
          setExpandSellersSelector={setExpandSellersSelector}
          expandBuyersSelector={expandBuyersSelector}
          setExpandBuyersSelector={setExpandBuyersSelector}
        />
      )}
    </>
  );
};

const getItemSectionTitle = (
  t: TFunction,
  delegationSession: DelegationSession | undefined,
  itemType: 'BUYER' | 'SELLER',
  selectedItem: string | undefined,
  items: AccountListItem[],
) => {
  if (delegationSession) {
    const { commercialLink } = delegationSession;
    return itemType === 'BUYER'
      ? fmt(commercialLink.name, commercialLink.tresorCode)
      : fmt(commercialLink.seller?.name, commercialLink.seller?.tresorCode);
  }

  const currItem = selectedItem ? items.find((s) => s.tresorCode === selectedItem) : undefined;
  if (currItem) {
    return fmt(currItem.name, currItem.tresorCode, currItem.primaryClientCode);
  }
  return itemType === 'BUYER'
    ? t('connect_as.client', 'Select Client here')
    : t('connect_as.dealer', 'Select Dealer here');
};

interface SectionProps {
  itemType: 'BUYER' | 'SELLER';
  dataStatus: SEARCH_STATUS;
  data: AccountListItem[];
  selectedItem: string | undefined;
  setSelectedItem: (val: string) => void;
  expandDataSelector: boolean;
  setExpandDataSelector: (val: boolean) => void;
}

const Section = ({
  itemType,
  dataStatus,
  data,
  selectedItem,
  setSelectedItem,
  expandDataSelector,
  setExpandDataSelector,
}: SectionProps) => {
  const { t } = useTranslation();
  const delegationSession = useSelector(getDelegationSession);
  const titleStatus = delegationSession ? FOUND : dataStatus;
  const tresorCodes = useMemo(() => data.map((s) => s.tresorCode), [data]);
  const title = getItemSectionTitle(t, delegationSession, itemType, selectedItem, data);
  const [initialExpandDone, setInitialExpandDone] = useState(false);
  useEffect(() => {
    if (selectedItem || initialExpandDone || tresorCodes.length === 0) {
      return;
    }

    if (tresorCodes.length === 1) {
      setSelectedItem(tresorCodes[0]);
      setExpandDataSelector(false);
    } else {
      setExpandDataSelector(true);
    }

    setInitialExpandDone(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tresorCodes]);

  return (
    <>
      <SectionWrapper
        minHeight={50}
        align={'center'}
        onClick={() => data.length > 1 && !delegationSession && setExpandDataSelector(!expandDataSelector)}
        pointer
      >
        <MarginBox ml={21} mr={16}>
          <Icon IconComponent={BriefcaseIcon} size={32} display={'inline'} color={theme.color.grey00} />
        </MarginBox>
        <Flex minWidth={225} maxWidth={225}>
          <DataContainer
            data={titleStatus}
            Skeleton={() => (
              <Text type={'light_14_black_65'} displayStyle={'disabled'}>
                {title}
              </Text>
            )}
          >
            <Text type={'light_14_black_65'}>{title}</Text>
          </DataContainer>
        </Flex>
        {!delegationSession && tresorCodes.length > 1 && (
          <ValidationIcon input={selectedItem} error={titleStatus === ERROR} />
        )}
      </SectionWrapper>
      {expandDataSelector && (
        <ListAccounts
          data={data}
          key={`${itemType}-clientList`}
          labelInputPlaceholder={
            itemType === 'BUYER'
              ? t('connect_as.client_search', `Search for client here...`)
              : t('connect_as.dealer_search', `Search for dealer here...`)
          }
          onSelect={(item) => {
            setSelectedItem(item.tresorCode);
            setExpandDataSelector(false);
          }}
          warningFilter={
            itemType === 'BUYER'
              ? t(
                  'connect_as.client_list.empty_list_warning',
                  `Please check the spelling or try to search for another client.`,
                )
              : t(
                  'connect_as.dealer_list.empty_list_warning',
                  `Please check the spelling or try to search for another dealer.`,
                )
          }
        />
      )}
    </>
  );
};

interface BuyerSelectorProps {
  selectedSeller: string | undefined;
  selectedBuyer: string | undefined;
  setSelectedBuyer: (val: string) => void;
  expandBuyersSelector: boolean;
  setExpandBuyersSelector: (val: boolean) => void;
}

const BuyerSelector = ({
  selectedSeller,
  selectedBuyer,
  setSelectedBuyer,
  expandBuyersSelector,
  setExpandBuyersSelector,
}: BuyerSelectorProps) => {
  const buyersSearchData = useSelector((state: RootState) => getPossibleDelegateSessionBuyers(state, selectedSeller));
  const buyers = buyersSearchData?.data ?? [];

  useFetchBuyersForSellersForDelegationSession(selectedSeller);

  return (
    <Section
      itemType={'BUYER'}
      dataStatus={buyersSearchData?.searchStatus}
      data={buyers}
      selectedItem={selectedBuyer}
      setSelectedItem={setSelectedBuyer}
      expandDataSelector={expandBuyersSelector}
      setExpandDataSelector={setExpandBuyersSelector}
    />
  );
};

/**
 * Display connect as button if session is empty (not found or undefined). Hide it if active.
 */
const resolveConnectAsButtonDisplayStatus = (delegationSessionStatus: SEARCH_STATUS) => {
  switch (delegationSessionStatus) {
    case FOUND:
      return undefined;
    case LOADING:
      return LOADING;
    default:
      return FOUND;
  }
};

interface DelegateSessionConnectionBoxBodyProps {
  selectedSeller: string | undefined;
  setSelectedSeller: (val: string | undefined) => void;
  selectedBuyer: string | undefined;
  setSelectedBuyer: (val: string | undefined) => void;
  expandSellersSelector: boolean;
  setExpandSellersSelector: (val: boolean) => void;
  expandBuyersSelector: boolean;
  setExpandBuyersSelector: (val: boolean) => void;
}

const DelegateSessionConnectionBoxBody = ({
  selectedSeller,
  setSelectedSeller,
  selectedBuyer,
  setSelectedBuyer,
  expandSellersSelector,
  setExpandSellersSelector,
  expandBuyersSelector,
  setExpandBuyersSelector,
}: DelegateSessionConnectionBoxBodyProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sellersSearchData = useSelector(getPossibleDelegateSessionSellers);
  const sellers = sellersSearchData?.data ?? [];
  const delegationSessionStatus = useSelector(getDelegationSessionStatus);

  useFetchSellersForDelegationSession();

  const selectSeller = (newSeller: string) => {
    if (selectedSeller !== newSeller) {
      setSelectedBuyer(undefined);
    }
    setSelectedSeller(newSeller);
  };

  const handleConnectToDelegateSession = (sellerId: string, buyerId: string) => {
    dispatch(connectAsUserSaga({ buyerId, sellerId }));
    setExpandSellersSelector(false);
    setExpandBuyersSelector(false);
  };

  return (
    <>
      <Section
        itemType={'SELLER'}
        dataStatus={sellersSearchData?.searchStatus}
        data={sellers}
        selectedItem={selectedSeller}
        setSelectedItem={selectSeller}
        expandDataSelector={expandSellersSelector}
        setExpandDataSelector={setExpandSellersSelector}
      />
      <BuyerSelector
        selectedSeller={selectedSeller}
        selectedBuyer={selectedBuyer}
        setSelectedBuyer={setSelectedBuyer}
        expandBuyersSelector={expandBuyersSelector}
        setExpandBuyersSelector={setExpandBuyersSelector}
      />

      {selectedSeller && selectedBuyer && (
        <DataContainer data={resolveConnectAsButtonDisplayStatus(delegationSessionStatus)}>
          <Flex minHeight={50} align={'center'} justify={'center'}>
            <MarginBox mt={20} mb={20}>
              <YellowButton onClick={() => handleConnectToDelegateSession(selectedSeller, selectedBuyer)}>
                {t('connect_as.confirm_connect_as', 'Connect as')}
              </YellowButton>
            </MarginBox>
          </Flex>
        </DataContainer>
      )}
    </>
  );
};

const validInput = (input: string | undefined) => input && input.length >= 4;

const ValidationIcon = ({ input, error }: { input: string | undefined; error: boolean }) => {
  if (error) {
    return (
      <Flex direction={'column'} align={'flex-end'}>
        <MarginBox ml={6} mr={12}>
          <Icon IconComponent={ExclamationTriangleIcon} size={13} display={'inline'} color={theme.color.warning} />
        </MarginBox>
      </Flex>
    );
  }

  return validInput(input) ? (
    <Flex direction={'column'} align={'flex-end'}>
      <MarginBox ml={6} mr={12}>
        <Icon IconComponent={CheckCircleIcon} size={13} display={'inline'} color={theme.color.green_light} />
      </MarginBox>
    </Flex>
  ) : (
    <Flex direction={'column'} align={'flex-end'}>
      <MarginBox ml={6} mr={12}>
        <Icon IconComponent={TimesCircleIcon} size={13} display={'inline'} color={theme.color.grey00} />
      </MarginBox>
    </Flex>
  );
};
export default DelegateSessionConnectionBox;
