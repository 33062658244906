import React from 'react';
import { Table } from 'antd';
import { ManageCookiesButton } from 'components/Page/Footer/Footer.styled';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';

export const CookiesNL = () => {
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <Flex direction={'column'}>
          <MarginBox mt={15} />
          <Flex justify={'center'}>
            <Text type={'h5_bold'}>1. COOKIEBANNER</Text>
          </Flex>
          <MarginBox mt={30} />
        </Flex>
        <Section>
          <Paragraph>
            Er moet een banner worden weergegeven wanneer een gebruiker voor het eerst op een Renault domeinnaam
            terechtkomt, ongeacht op welke pagina hij/zij terechtkomt. Deze banner moet regelmatig worden weergegeven op
            basis van aanbevelingen van lokale autoriteiten (bijv. voor Nederland elke 6 maanden). Deze banner moet de
            volgende tekst bevatten :
          </Paragraph>
          <Paragraph>
            <b>De bescherming van uw online gegevens is een prioriteit voor Renault.</b>
          </Paragraph>
          <Paragraph>
            Onze website en onze partners [
            <Link to={'https://www.renault.nl/cookies.html'} external newTab>
              BEKIJK HET COOKIEBELEID VAN RENAULT: HELDER EN DUIDELIJK
            </Link>
            ] willen cookies gebruiken om de bezoekers en de prestaties van de website te meten, om u gepersonaliseerde
            en/of op uw locatie gebaseerde reclame en inhoud te tonen en om u in staat te stellen via sociale netwerken
            met onze inhoud te communiceren.
          </Paragraph>
          <Paragraph>
            U kunt uw keuzes op elk gewenst moment heroverwegen door naar het gedeelte &quot;Cookies&quot; op onze
            website te gaan.
          </Paragraph>
          <Paragraph>
            Een volgend informatieniveau moet toegankelijk zijn via een knop &quot;<b>Lees ons cookiebeleid</b>&quot; of
            &quot;<b>Meer informatie</b>&quot; die de gebruiker doorverwijst naar een speciale pagina met informatie
            over cookies in overeenstemming met de AVG, de e-Privacyrichtlijn en andere toepasselijke
            transparantievereisten en -richtlijnen (&quot;<b>Cookiebeleid</b>&quot;) en hen de mogelijkheid biedt het
            platform voor toestemmingsbeheer te openen om hun keuzes te beheren - zie onderstaande sjabloon.
          </Paragraph>
        </Section>
        <Flex direction={'column'}>
          <MarginBox mt={15} />
          <Flex justify={'center'}>
            <Text type={'h5_bold'}>COOKIEBELEID</Text>
          </Flex>
          <MarginBox mt={30} />
        </Flex>
        <Paragraph>
          Via de RpartStore wordt gebruik gemaakt van technologieën zoals cookies en vergelijkbare technologieën om u,
          als gebruiker van het platform, een vloeiende en gepersonaliseerde ervaring van hoge kwaliteit te bieden. De
          informatie in dit cookiebeleid is een aanvulling op de informatie over de bescherming van persoonsgegevens dat
          te vinden is in onze privacyverklaring die te vinden is via de volgende link: [
          <Link
            to={'https://group.renault.com/en/our-commitments/the-groupe-renault-and-your-personal-data/'}
            external
            newTab
          >
            GROUP PRIVACYBELEID PERSOONSGEGEVENS.
          </Link>
          ]
        </Paragraph>
        <Paragraph>
          U kunt uw keuzes met betrekking tot cookies op elk gewenst moment heroverwegen met behulp van onze tool voor
          cookiebeheer:{' '}
          <ManageCookiesButton $color={'brand_alt'}>
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              Beheer mijn keuzes
            </button>
          </ManageCookiesButton>
          .
        </Paragraph>
        <Section>
          <SectionHeader title={'WIE ZIJN WIJ?'} />
          <Paragraph>
            Renault Nederland N.V. en Renault SAS zijn onafhankelijke verwerkingsverantwoordelijken voor eventuele
            persoonsgegevens die worden verzameld door de cookies en andere trackers die op uw apparaat worden gelezen
            of geplaatst (Renault Nederland N.V. wordt hierna aangeduid als &quot;wij&quot;, &quot;ons&quot;,
            &quot;onze&quot;).
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'WAT ZIJN COOKIES?'} />
          <Paragraph>
            Een cookie is een klein computerbestand, een tracker, dat wordt opgeslagen en uitgelezen bij het bezoeken
            van een website, het gebruik van een mobiele app, het lezen van een e-mail, het installeren of gebruiken van
            software, ongeacht het type communicatiemiddel dat wordt gebruikt (computer, smartphone, digitale reader,
            videospelconsole aangesloten op het internet, enz.)
          </Paragraph>
          <Paragraph>
            Bij het raadplegen van een website kunnen cookies informatie naar de browser van de gebruiker sturen en de
            browser in staat stellen informatie terug te sturen naar de oorspronkelijke website (bijvoorbeeld een
            sessie-ID of taalkeuze).
          </Paragraph>
          <Paragraph>
            Cookies stellen ons meestal niet in staat om u rechtstreeks te identificeren, maar alleen om specifieke
            informatie te verzamelen over uw apparaat, browser, interacties met de website of app die u gebruikt en, in
            sommige gevallen, uw geografische positie.
          </Paragraph>
          <Paragraph>
            De gebruikte technologieën kunnen verschillende apparaten die u hebt gebruikt met elkaar in verband brengen
            om met voldoende waarschijnlijkheid vast te stellen dat deze apparaten bij u horen.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'WAARVOOR WORDEN COOKIES OP DEZE WEBSITE GEBRUIKT?'} />
          <Paragraph>
            Deze website maakt gebruik van verschillende soorten cookies. Deze worden hierna toegelicht.
          </Paragraph>
          <Section>
            <SectionHeader title={'Technische cookies'} underline />
            <Paragraph>
              Deze cookies zijn bedoeld om elektronische communicatie mogelijk te maken of te vergemakkelijken (opsporen
              van verbindingsfouten, identificatie van verbindingspunten, beveiliging van de website, enz.) of om online
              communicatiediensten aan te bieden waar u specifiek om vraagt (voorkeuren voor schermweergave, opslaan van
              informatie die is ingevoerd in de formulieren, winkelwagentje (indien van toepassing), toegang tot uw
              gebruikersaccount, enz.)
            </Paragraph>
            <Paragraph>Deze cookies zijn noodzakelijk voor een goede werking van onze website.</Paragraph>
            <Paragraph>
              U kunt geen bezwaar maken tegen het gebruik van deze cookies en uw toestemming is niet vereist, aangezien
              de diensten op onze website afhankelijk zijn van deze cookies.
            </Paragraph>
          </Section>
          <Section>
            <SectionHeader title={'Analytische cookies'} underline />
            <Paragraph>
              Deze cookies zijn bedoeld om websiteverkeer te analyseren, om de gebruikerservaring te analyseren of
              gewoon om geaggregeerde signalen te verzamelen over de stroom van bezoekers op de site (bijv. taalkeuze,
              vorige bezochte pagina, tijdstempel van het bezoek, keuze voor cookies).
            </Paragraph>
            <Paragraph>
              Deze cookies kunnen gegevens verwerken met betrekking tot de bezochte pagina&apos;s, de tijd die u
              doorbrengt op de bezochte pagina&apos;s, de browser die u gebruikt om deze pagina&apos;s te bezoeken, uw
              gebruik van de chat of chatbot op onze website, enz.
            </Paragraph>
            <Paragraph>
              De gegevens die door deze cookies worden verzameld, kunnen ook worden gebruikt om algoritmen en profielen
              op te stellen om uw mogelijke interesses en voorkeuren voor onze producten en diensten te analyseren. We
              kunnen deze gegevens combineren met andere informatie, zoals informatie uit onze klantendatabase (zie voor
              meer informatie over profilering en hoe u bezwaar kunt maken tegen dergelijke profilering ons privacy
              beleid: [
              <Link
                to={'https://group.renault.com/en/our-commitments/the-groupe-renault-and-your-personal-data/'}
                external
                newTab
              >
                GROUP PRIVACYBELEID PERSOONSGEGEVENS.
              </Link>
              ].
            </Paragraph>
            <Paragraph>
              Het gebruik en de opslag van deze cookies is over het algemeen afhankelijk van uw toestemming.{' '}
            </Paragraph>
            <Paragraph>
              Onder bepaalde omstandigheden kunnen sommige technologieën alleen technische signalen verzamelen zonder u
              persoonlijk te identificeren of uw browsegeschiedenis te volgen, in veel gevallen zonder dat daarvoor uw
              toestemming nodig is, omdat ze alleen worden gebruikt om anonieme statistieken te produceren voor
              publieksmeting en geen of geringe impact vormen op uw persoonlijke levenssfeer.
            </Paragraph>
          </Section>
        </Section>
        <Section>
          <SectionHeader title={'WELKE COOKIES WORDEN OP DEZE WEBSITE GEBRUIKT?'} />
          <Paragraph>
            De bijgevoegde tabel bevat de lijst van alle cookies die door deze website worden opgeslagen en/of gebruikt
            en hun belangrijkste kenmerken. Waar mogelijk worden deze cookies gehost op servers in de Europese
            Economische Ruimte (EER). Aangezien sommige van onze serviceproviders zich echter in landen buiten de EER
            bevinden, worden uw persoonsgegevens verwerkt in deze landen, die mogelijk een andere regelgeving inzake
            persoonsgegevens hebben dan de Europese Unie. In een dergelijk geval (i) zien wij er nauwlettend op toe dat
            deze overdracht wordt uitgevoerd in overeenstemming met de toepasselijke regelgeving en (ii) treffen wij
            waarborgen die een adequaat niveau van bescherming van uw gegevens waarborgen (in het bijzonder door gebruik
            te maken van de modelcontractbepalingen van de Europese Commissie).
          </Paragraph>
          <Paragraph>
            Onze website kan cookies van derden bevatten (communicatiebureau, analysediensten, enz.) waardoor zij,
            gedurende de levensduur van de cookies, browse-informatie met betrekking tot onze website kunnen verzamelen.
            De opslag en het gebruik van deze cookies door derden is onderworpen aan het privacy beleid van deze derden.
            Wij informeren u over het doel van cookies van derden op onze website en over de middelen waarover u
            beschikt om keuzes te maken met betrekking tot deze cookies.
          </Paragraph>
          <Table
            pagination={false}
            rowKey={(record) => record.id}
            columns={[
              {
                title: 'Partner-eigenaar van de cookie',
                dataIndex: 'firm',
                key: 'firm',
              },
              { title: 'Doel cookie', dataIndex: 'utility', key: 'utility' },
              {
                title: 'Cookiebeleid ',
                dataIndex: 'terms',
                key: 'terms',
                render: (to: string) => (
                  <Link to={to} external newTab>
                    {'Cookiebeleid'}
                  </Link>
                ),
              },
            ]}
            dataSource={[
              {
                id: 0,
                firm: 'Inhoud vierkant',
                utility: 'Analytics',
                terms: 'https://contentsquare.com/fr-fr/privacy-center/cookie-policy/',
              },
              {
                id: 1,
                firm: 'Dynatrace',
                utility: 'Analytics',
                terms: 'https://www.dynatrace.com/company/trust-center/cookie-policy/',
              },
              {
                id: 2,
                firm: 'Matomo',
                utility: 'Analytics',
                terms: 'https://matomo.org/privacy-policy/',
              },
              {
                id: 3,
                firm: 'Renault',
                utility: 'Technisch',
                terms: 'https://www.renault.nl/cookies.html',
              },
            ]}
          />
        </Section>
        <Section>
          <SectionHeader title={'HOE WORDT UW TOESTEMMING VERKREGEN?'} />
          <Paragraph>
            Wanneer u onze website voor het eerst bezoekt, wordt u gevraagd om in te stemmen met het gebruik van cookies
            (voor zover daarvoor toestemming nodig is) of om dit te weigeren of om bepaalde keuzes te maken over het
            gebruik van cookies, door een speciale informatiebanner weer te geven.
          </Paragraph>
          <Paragraph>
            U kunt uw toestemming op elk gewenst moment intrekken of uw keuzes wijzigen (bijvoorbeeld door alleen
            bepaalde soorten cookies te accepteren) met onze speciale tool voor cookiebeheer:{' '}
            <ManageCookiesButton $color={'brand_alt'}>
              <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                Beheer mijn keuzes
              </button>
            </ManageCookiesButton>
            .
          </Paragraph>
          <Paragraph>
            Houd er rekening mee dat, in overeenstemming met de regelgeving, cookies die noodzakelijk zijn voor het
            functioneren van de website niet onderhevig zijn aan uw toestemming en daarom niet kunnen worden
            uitgeschakeld in onze tool voor cookiebeheer. U kunt het gebruik ervan voorkomen door uw browserinstellingen
            te wijzigen (volg de paragraaf &quot;hoe cookies weigeren&quot; hieronder), maar de kwaliteit van uw
            ervaring op de website kan daaronder lijden.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'HOE LANG WORDT UW TOESTEMMING BEWAARD?'} />
          <Paragraph>
            Wanneer u cookies op onze website geheel of gedeeltelijk accepteert of weigert, wordt uw keuze opgeslagen en
            bewaard (en houden wij uw voorkeuren bij) voor een periode van maximaal 6 maanden . Dit betekent dat wanneer
            u onze website later, tijdens deze periode, raadpleegt, wij u niet zullen vragen om actie te ondernemen met
            betrekking tot cookies, aangezien wij een registratie hebben van uw opgeslagen voorkeuren.
          </Paragraph>
          <Paragraph>
            Na afloop van deze periode vragen we je opnieuw om toestemming of om het gebruik van cookies te weigeren.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'HOE KUN JIJ COOKIES BEHEREN?'} />
          <SectionHeader title={'Hoe weiger je cookies?'} underline />
          <Paragraph>
            Als u niet wilt dat er cookies op uw apparaat worden opgeslagen of gelezen, wordt er een weigeringscookie op
            uw apparatuur opgeslagen zodat we kunnen registreren dat u bezwaar hebt gemaakt tegen het gebruik van
            cookies. Als u deze cookie-weigering verwijdert, kunnen wij niet langer herkennen dat u het gebruik en de
            opslag van cookies hebt geweigerd (wat betekent dat u bij een volgend bezoek aan onze website uw voorkeuren
            opnieuw moet instellen).
          </Paragraph>
          <Paragraph>
            U hebt verschillende opties om cookies te beheren, waaronder het blokkeren en/of verwijderen ervan.
          </Paragraph>
          <SectionHeader title={'Hulpmiddel voor cookiebeheer'} underline />
          <Paragraph>
            Er wordt een cookietool aangeboden waarmee u uw cookievoorkeuren kunt beheren, zodat u de cookies kunt
            beheren die op deze website worden opgeslagen en/of gebruikt. U kunt op elk gewenst moment, door op deze
            link hieronder te klikken, toegang krijgen tot de configurator en uw voorkeuren wijzigen, afhankelijk van
            het type cookie in kwestie.
          </Paragraph>
        </Section>
        <Flex justify={'center'}>
          <Text type={'h5_bold'}>DE COOKIES BEHEREN</Text>
        </Flex>
        <Section>
          <SectionHeader title={'Browserinstellingen'} underline />
          <Paragraph>U kunt cookies ook beheren via uw browser.</Paragraph>
          <Paragraph>
            De meeste browsers zijn standaard ingesteld om cookies te accepteren, maar als u wilt, kunt u ervoor kiezen
            om alle cookies te accepteren, cookies systematisch te weigeren of te kiezen welke cookies u accepteert
            afhankelijk van waar ze vandaan komen. U kunt ook regelmatig cookies van uw apparaat verwijderen met behulp
            van uw browser.
          </Paragraph>
          <Paragraph>
            Vergeet echter niet om alle browsers op je verschillende apparaten (tablets, smartphones, computers, enz.)
            in te stellen.
          </Paragraph>
          <Paragraph>
            Elke browser heeft een andere instelling voor het beheren van cookies en uw keuzes. Dit wordt beschreven in
            het helpmenu van uw browser, waarin staat hoe u uw cookievoorkeuren kunt wijzigen. Bijvoorbeeld:
            <Ul>
              <Li>
                <Text type={'text'}>
                  voor Internet Explorer™:{' '}
                  <Link to={'http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies'} external newTab>
                    http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
                  </Link>{' '}
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  voor Safari™:{' '}
                  <Link to={'https://support.apple.com/fr-fr/safari'} external newTab>
                    https://support.apple.com/fr-fr/safari
                  </Link>{' '}
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  voor Chrome™:{' '}
                  <Link
                    to={'http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647'}
                    external
                    newTab
                  >
                    http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647
                  </Link>{' '}
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  voor Firefox™:{' '}
                  <Link
                    to={'http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies'}
                    external
                    newTab
                  >
                    http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies
                  </Link>{' '}
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  voor Opera™:{' '}
                  <Link to={'http://help.opera.com/Windows/10.20/fr/cookies.html'} external newTab>
                    http://help.opera.com/Windows/10.20/fr/cookies.html
                  </Link>
                  .
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <SectionHeader title={'Uitgeversmodules voor het maken van bezwaren'} underline />
          <Paragraph>
            U kunt ervoor kiezen om sommige cookies van derden uit te schakelen door rechtstreeks naar de pagina van de
            uitgever van de cookie te gaan. Op deze manier :
          </Paragraph>
          <Paragraph align={'start'}>
            <Ul>
              <Li>
                Om Google Analytics-cookies uit te schakelen, gaat u naar de pagina :{' '}
                <Link to={'https://tools.google.com/dlpage/gaoptout?hl=fr'} external newTab>
                  https://tools.google.com/dlpage/gaoptout?hl=fr
                </Link>
              </Li>
              <Li>
                Om intelliAd cookies uit te schakelen, ga naar de pagina :{' '}
                <Link to={'https://login.intelliad.com/optout.php'} external newTab>
                  https://login.intelliad.com/optout.php
                </Link>
              </Li>
              <Li>
                Ga naar de pagina om Adobe-cookies uit te schakelen :{' '}
                <Link to={'https://www.adobe.com/uk/privacy/opt-out.html'} external newTab>
                  https://www.adobe.com/uk/privacy/opt-out.html
                </Link>
              </Li>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'HOE KUNT U UW ANDERE RECHTEN UITOEFENEN?'} />
          <Paragraph>
            In overeenstemming met de toepasselijke wetgeving inzake de bescherming van persoonsgegevens hebt u het
            recht op toegang tot, rectificatie van, bezwaar tegen, beperking van en verwijdering van uw
            persoonsgegevens. Sommige informatie die wordt verwerkt via cookies, kan gekwalificeerd worden als
            persoonsgegevens. Om uw andere rechten uit te oefenen, kunt u onze informatie over de bescherming van uw
            persoonsgegevens raadplegen door op de volgende link te klikken [
            <Link to={'https://www.renault.nl/cookies.html'} external newTab>
              https://www.renault.nl/cookies.html
            </Link>
            ].
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'VERANDERINGEN'} />
          <Paragraph>
            We kunnen dit beleid van tijd tot tijd wijzigen. We zullen u informeren en/of om uw toestemming vragen
            wanneer dat nodig of vereist is. We raden u daarom aan dit beleid te raadplegen telkens wanneer u onze
            website bezoekt om de meest recente versie te bekijken.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Laatste update: 23 oktober 2023</Text>
        </Flex>
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
