import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Radio, RadioGroup, Select, SelectOptionSingle, Text, Space } from 'UI';

interface BillingAccountSectionProps {
  updateBillingAccount: (value: string) => void;
  billingOptions?: SelectOptionSingle[];
  selectedBillingAccount?: string;
}

const BillingAccountSection = ({
  updateBillingAccount,
  billingOptions,
  selectedBillingAccount,
}: BillingAccountSectionProps) => {
  const { t } = useTranslation();

  return (
    <Flex direction={'column'} dataCy={'section-payment-method'}>
      <Text type={'h1_banner_light'} dataCy={'title-payment-method'}>
        {`2. ${t('order.validation.payment', 'Payment Method')}`}
      </Text>
      <Box height={20} />
      <Text type={'section'}>
        {t(
          'order.validation.payment.description',
          'The selected payment options will apply by default to your entire order.',
        )}
      </Text>
      <Box height={30} />
      <RadioGroup>
        <Space direction={'vertical'}>
          <Radio
            checked
            label={
              <Text type={'light_14_black_65'}>{t('order.validation.payment.options.account', 'Account billing')}</Text>
            }
          />
          <Box width={250}>
            <Select
              onChange={(value) => {
                updateBillingAccount(String(value));
              }}
              initialValue={selectedBillingAccount}
              placeholder={t('order.validation.payment.options.placeholder', 'Select billing account')}
              options={billingOptions}
              bordered
              disabled={billingOptions && billingOptions.length <= 1}
            />
          </Box>
        </Space>
      </RadioGroup>
    </Flex>
  );
};

export default BillingAccountSection;
