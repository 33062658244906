import { ListItemProps } from 'antd/lib/list';
import styled, { css } from 'styled-components';
import { defaultBoxShadow, Panel } from 'UI';

export const SBox = styled.div`
  padding: 0 15px !important;
  overflow: auto;
  max-height: 150px;
`;

export const SListItem = styled.div<ListItemProps>`
  margin-top: 15px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex: 0;
  min-width: 300px;
  padding: 23px 0;
  border-radius: 4px;
  ${defaultBoxShadow};
  background-color: ${({ theme }) => theme.color.white};
`;

const wrapperCss = css`
  box-shadow: 0 5px 10px 0 ${({ theme }) => theme.color.shadow_2};
  border-radius: 10px;
`;

export const Wrapper = styled.div<{ bordered?: boolean }>`
  ${({ bordered }) => bordered && wrapperCss}
`;

export const SFilterPanel = styled(Panel)`
  background-color: ${({ theme }) => theme.color.white} !important;
  margin: 15px 0 !important;

  .ant-collapse-header {
    padding: 15px !important;
    background-color: ${({ theme }) => theme.color.white} !important;

    :hover {
      background-color: ${({ theme }) => theme.color.grey_very_light_2} !important;
    }
  }

  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-content-box {
    padding: 0 15px !important;
  }

  .ant-slider-track {
    background-color: ${({ theme }) => theme.color.grey_very_light_2} !important;
  }

  .ant-slider:hover .ant-slider-rail {
    background-color: ${({ theme }) => theme.color.grey_very_light_2} !important;
  }

  .ant-slider-handle {
    background-color: white !important;
    border-color: ${({ theme }) => theme.color.grey20} !important;

    :focus {
      box-shadow: none !important;
      border-color: ${({ theme }) => theme.color.clear_blue} !important;
    }
  }
`;

export const TagWrapper = styled.div`
  border: solid 1px ${({ theme }) => theme.color.grey30};
  padding: 0 10px 0 10px;
  height: 30px;
  border-radius: 16px;
`;

export const FuelEfficiencyTag = styled.div<{
  size: number;
  color: string;
}>`
  width: ${({ size }) => `${size}px`};
  position: relative;
  height: 20px;
  align-content: center;
  justify-content: flex-start;
  padding: 0 10px 5px 5px;
  background: ${({ color }) => color};
  color: white;
  user-select: none;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);

  &::after {
    content: '';
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 1px 10px 10px;
    user-select: none;
    border-color: transparent transparent transparent ${({ color }) => color};
  }
`;
