/* eslint-disable max-len */
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { ROUTER_CATALOG_DH, ROUTER_CATALOG_VEHICLE, ROUTER_PRODUCT } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { calculateDiscountPrice, DiscountCorner, getDiscountTextStyle } from 'components/Discount';
import { useFetchSingleReference } from 'domains/catalog/Catalog.requests';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { DHReferenceLocal, getDiscount, ReferencePriceType } from 'domains/references';
import { useFetchFullTradingData } from 'domains/references/References.requests';
import { ClientView, GarageView, SparePartsViewType } from 'domains/user';
import { Flex, Text } from 'UI';
import { textFormatter } from 'utils/format';

export function hasPrice(
  price: ReferencePriceType | undefined,
  sparePartsView: SparePartsViewType,
): price is ReferencePriceType {
  return (
    (sparePartsView === GarageView && price?.garageView?.vatExcludedPrice !== undefined) ||
    (sparePartsView === ClientView && price?.clientView?.recommendedPriceVatExcluded !== undefined)
  );
}

export const getProductLink = (query: string | undefined, refNumber: string) => {
  const carPath = query ? `${ROUTER_CATALOG_DH}/${query}${ROUTER_CATALOG_VEHICLE}` : '';
  return `${carPath}${ROUTER_PRODUCT}/${refNumber}`;
};

export const renderPromoCorner = (referenceNumber: string, sparePartsView: SparePartsViewType) => {
  if (sparePartsView !== GarageView) {
    return null;
  }

  return (
    <div style={{ position: 'relative' }}>
      <DiscountCorner reference={referenceNumber} type={'productList'} />
    </div>
  );
};

export const PriceField = ({
  inline = false,
  price,
  sparePartsView,
  referenceNumber,
}: {
  inline?: boolean;
  price: ReferencePriceType;
  sparePartsView: SparePartsViewType;
  referenceNumber: string;
}) => {
  const discount = useSelector((state: RootState) => getDiscount(state, referenceNumber));
  const { t } = useTranslation();
  const isGarageView = sparePartsView === GarageView;

  const getFirstPriceLine = () => {
    return (
      <Text
        cursor={'pointer'}
        disableGutter
        type={'section_bold'}
        displayStyle={sparePartsView === GarageView && getDiscountTextStyle(discount)}
      >
        {t('common.price.vat_exclude_price', '{{vat_exclude_price}} VAT. Excl', {
          vat_exclude_price: textFormatter.formatCurrency(
            isGarageView
              ? Number(calculateDiscountPrice(discount, price, false))
              : Number(price?.clientView?.recommendedPriceVatExcluded ?? 0),
            price?.currency,
          ),
        })}
      </Text>
    );
  };

  const getSecondPriceLine = () => {
    return (
      <>
        {sparePartsView === GarageView ? (
          discount ? (
            <Text type={'light_12_black_65'} decoration={'line-through'}>
              {t('common.price.vat_exclude_price', '{{vat_exclude_price}} VAT. Excl', {
                vat_exclude_price: textFormatter.formatCurrency(
                  Number(price.garageView?.vatExcludedPrice),
                  price.currency,
                ),
              })}
            </Text>
          ) : (
            <Text type={'light_12_black_65'}>
              {t('common.price.vat_include_price', '{{vat_include_price}} VAT. Incl', {
                vat_include_price: textFormatter.formatCurrency(
                  Number(discount ? calculateDiscountPrice(discount, price, true) : price.garageView?.vatIncludedPrice),
                  price.currency,
                ),
              })}
            </Text>
          )
        ) : (
          <Text cursor={'pointer'} type={'light_12_black_65'}>
            {t('common.price.vat_include_price', '{{vat_include_price}} VAT. Incl', {
              vat_include_price: textFormatter.formatCurrency(
                Number(price?.clientView?.recommendedPriceVatIncluded ?? 0),
                price?.currency,
              ),
            })}
          </Text>
        )}
      </>
    );
  };
  return (
    <Flex
      size={0}
      minWidth={130}
      direction={inline ? 'row' : 'column'}
      align={inline ? 'flex-end' : 'inherit'}
      gap={inline ? 15 : 0}
    >
      {getFirstPriceLine()}
      {getSecondPriceLine()}
    </Flex>
  );
};

export const getReferenceNumberText = (t: TFunction, referenceNumber: string) =>
  `${t('catalog.reference_card.reference_number', 'Ref:')} ${referenceNumber}`;

export interface ProductMenuItemProps {
  parentRef: DHReferenceLocal;
  sparePartsView: SparePartsViewType;
  referenceNumber: string;
}

export function ProductMenuList({
  parentRef,
  referenceNumbers,
  sparePartsView,
  ProductMenuItem,
}: {
  parentRef: DHReferenceLocal;
  referenceNumbers: string[];
  sparePartsView: SparePartsViewType;
  ProductMenuItem: FunctionComponent<ProductMenuItemProps>;
}) {
  return (
    <>
      {referenceNumbers.map((r, key) => (
        <ProductMenuItemWrapper
          key={key}
          referenceNumber={r}
          sparePartsView={sparePartsView}
          parentRef={parentRef}
          ProductMenuItem={ProductMenuItem}
        />
      ))}
    </>
  );
}

export function ProductMenuItemWrapper({
  parentRef,
  referenceNumber,
  sparePartsView,
  ProductMenuItem,
}: {
  parentRef: DHReferenceLocal;
  referenceNumber: string;
  sparePartsView: SparePartsViewType;
  ProductMenuItem: FunctionComponent<ProductMenuItemProps>;
}) {
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  useFetchSingleReference(referenceNumber, null, vehicleKey);
  useFetchFullTradingData(referenceNumber, vehicleKey);
  return <ProductMenuItem referenceNumber={referenceNumber} sparePartsView={sparePartsView} parentRef={parentRef} />;
}
