import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { AngleDownIcon, AngleUpIcon } from 'assets/icons';
import { REFERENCE_QUANTITY_LIMIT } from 'domains/basket/Basket.types';
import { Icon } from 'UI';
import { ONCHANGE_DEBOUNCE_INTERVAL } from 'utils/hooks/useInput';
import { InputArrowsWrapper, InputWrapper, SInputNumber } from './QuantityBox.styled';

interface QuantityBoxProps {
  value: number;
  onChange: (quantity: number | string) => void;
  mini?: boolean;
  medium?: boolean;
  maxValue?: number;
  minValue?: number;
  onMaxValueOverflow?: () => void;
  chunk?: number;
  isMin750Chunk?: boolean;
}

const QuantityBoxLocal = ({
  value,
  onChange,
  mini = false,
  medium = false,
  maxValue,
  minValue = 0,
  onMaxValueOverflow,
  chunk = 1,
  isMin750Chunk = false,
}: QuantityBoxProps) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState(value);
  const [inputKey, setInputKey] = useState(0);
  const debouncedOnChange = useDebouncedCallback(onChange, ONCHANGE_DEBOUNCE_INTERVAL);
  const maxValueLocal = maxValue ?? REFERENCE_QUANTITY_LIMIT;
  const getChunk = () => (isMin750Chunk ? 750 : chunk);
  const [isInteracting, setIsInteracting] = useState(false); // Track if the user is interacting
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Reference to the timeout

  const clearUpdateTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  // Schedule the update after user interaction
  const scheduleUpdate = useCallback(() => {
    clearUpdateTimeout(); // Clear any previous timeout

    timeoutRef.current = setTimeout(() => {
      if (!isInteracting) {
        setInputValue(value);
      }
      setIsInteracting(false); // Reset interaction state
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!isInteracting) {
      setInputValue(value);
    } else {
      scheduleUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInteracting]);

  // Handle immediate updates from external value changes
  useEffect(() => {
    const isMaxValueOverflow = value > maxValueLocal;

    if (!isInteracting) {
      setInputValue(isMaxValueOverflow ? maxValueLocal : value);
    } else {
      scheduleUpdate();
    }

    isMaxValueOverflow && setInputValue(isMaxValueOverflow ? maxValueLocal : value);
    if (isMaxValueOverflow && onMaxValueOverflow) {
      onMaxValueOverflow();
    }
    return () => clearUpdateTimeout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, maxValueLocal, onMaxValueOverflow]);

  const handleChange = useCallback(
    (val: number) => {
      if (val < minValue) return;
      setInputValue(val);
      debouncedOnChange(val);
      setIsInteracting(true);
      scheduleUpdate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, debouncedOnChange],
  );

  // Cleanup the timeout on component unmount
  useEffect(() => {
    return () => {
      clearUpdateTimeout(); // Clear the timeout when the component unmounts
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputWrapper>
      <SInputNumber
        key={`input-key-${inputKey}`}
        mini={mini}
        medium={medium}
        bordered
        value={inputValue}
        onBlur={(val) => {
          if (val === undefined || val === inputValue) return;
          const chunkModifier = isMin750Chunk ? 750 : chunk;
          const newChunkValue = Math.floor(val / chunkModifier) * chunkModifier || chunkModifier;
          if (newChunkValue === inputValue) {
            setInputKey((prev) => prev + 1);
          }
          handleChange(newChunkValue);
        }}
        min={minValue}
        max={maxValueLocal}
      />
      <InputArrowsWrapper>
        <Icon
          IconComponent={AngleUpIcon}
          size={15}
          color={theme.color.grey55}
          hoverFill={theme.color.brand_black}
          display={'block'}
          onClick={() => {
            inputValue < maxValueLocal - chunk + 1 && handleChange(inputValue + chunk);
          }}
          disabled={inputValue === REFERENCE_QUANTITY_LIMIT}
        />
        <Icon
          IconComponent={AngleDownIcon}
          size={15}
          color={theme.color.grey55}
          hoverFill={theme.color.brand_black}
          display={'block'}
          onClick={() => {
            handleChange(inputValue - chunk);
          }}
          disabled={inputValue <= Math.max(minValue, getChunk())}
        />
      </InputArrowsWrapper>
    </InputWrapper>
  );
};

export const QuantityBox = React.memo(QuantityBoxLocal);
