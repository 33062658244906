import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Reference } from '@1po/1po-bff-fe-spec/generated/basket/response/model/OtherSection';
import { Popover } from 'antd';
import { TFunction } from 'i18next';
import { useDebouncedCallback } from 'use-debounce';
import { ArrowUpRightIcon, CarIcon, CreditCardIcon, ShoppingCartIcon, TruckIcon } from 'assets/icons';
import { ExternalBasketSectionLocal, VehicleLocal } from 'domains/basket/Basket.types';
import {
  resetValidationDeliveryAddressSaga,
  resetValidationPaymentMethodSaga,
  setValidationDeliveryAddressSaga,
  setValidationInstructionsSaga,
  setValidationPaymentMethodSaga,
} from 'domains/orderValidation/OrderValidation.store';
import {
  ValidationItemsExternal,
  ValidationItemsOthers,
  ValidationItemsVehicles,
} from 'domains/orderValidation/OrderValidation.types';
import { theme } from 'styles';
import { Box, CenterFlex, Flex, Icon, Image, Input, MarginBox, Select, SelectOptionSingle, Text, URL } from 'UI';
import { NO_DATA } from 'utils';
import {
  ArticleCard,
  ArticlesWrapper,
  CarPicBackground,
  IconsBackground,
  SelectedItems,
} from './VerificationCard.styled';

interface VerificationCardProps {
  vehicle?: VehicleLocal;
  externalBasket?: ExternalBasketSectionLocal;
  references?: Reference[];
  billingOptions?: SelectOptionSingle[];
  deliveryOptions?: SelectOptionSingle[];
  handlePaymentMethodUpdate: (vehicle: VehicleLocal | undefined, externalBasketId: string | undefined) => void;
  handleDeliveryAddressUpdate: (vehicle: VehicleLocal | undefined, externalBasketId: string | undefined) => void;
  allowPaymentChanges: boolean;
  allowAddressChanges: boolean;
  validation: ValidationItemsVehicles | ValidationItemsOthers | ValidationItemsExternal | undefined;
}

const renderCarPic = (
  vehicle: VehicleLocal | undefined,
  imageUrl: string | NO_DATA,
  externalBasketId: string | undefined,
) => {
  if (vehicle) {
    return (
      <Image
        src={imageUrl}
        type={URL}
        alt={`carPic_${vehicle?.vehicleDetail.vehicleKey}`}
        catalogSource={vehicle.vehicleDetail.catalogSource}
        fallbackComponent={<Icon IconComponent={CarIcon} size={100} color={theme.color.grey75} noPointer />}
      />
    );
  } else if (externalBasketId) {
    return <Icon IconComponent={ArrowUpRightIcon} size={100} color={theme.color.grey75} noPointer />;
  }
  return <Icon IconComponent={ShoppingCartIcon} size={100} color={theme.color.grey75} noPointer />;
};

const renderVehicleInfoBlock = (
  vehicle: VehicleLocal | undefined,
  externalBasket: ExternalBasketSectionLocal | undefined,
  t: TFunction,
) => {
  const vinField = (
    <Text type={'light_14_black_85'}>{`${t('catalog.vehicle.vin', 'VIN')}: ${vehicle?.vehicleDetail.vin}`}</Text>
  );

  const externalFiled = (
    <Text type={'light_14_black_85'}>
      {t('cart.external_basket.transferred_sia', '{{sia}} transferred basket', { sia: externalBasket?.sia ?? '' })}
    </Text>
  );

  const otherRefsField = (
    <Text type={'light_14_black_85'}>
      {`${t('order.verification.other_articles.description', 'Not attached to a vehicle')}`}
    </Text>
  );

  const bmmField = (
    <>
      <Text type={'text_dim'}>
        {vehicle?.vehicleDetail.dataHubVehicle?.name ??
          `${vehicle?.vehicleDetail.vehicleBrand ?? ''} ${vehicle?.vehicleDetail.model ?? ''} ${
            vehicle?.vehicleDetail.iamVehicle?.phase ?? ''
          }`}
      </Text>
      <Text type={'light_12_black_65'}>{`${
        vehicle?.vehicleDetail.dataHubVehicle?.modelType ?? vehicle?.vehicleDetail.iamVehicle?.bodyType ?? ''
      }`}</Text>
    </>
  );
  if (!vehicle && externalBasket) return externalFiled;
  if (!vehicle) return otherRefsField;
  if (vehicle && vehicle.vehicleDetail.vin) return vinField;
  else return bmmField;
};

const VerificationCard = ({
  vehicle,
  externalBasket,
  references,
  billingOptions,
  deliveryOptions,
  handlePaymentMethodUpdate,
  handleDeliveryAddressUpdate,
  allowPaymentChanges,
  allowAddressChanges,
  validation,
}: VerificationCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const refArray = vehicle ? vehicle?.references : references;
  const vehicleKey = vehicle?.vehicleDetail.vehicleKey;
  const externalBasketId = externalBasket?.externalBasketId;
  const referencesCount = refArray?.length;
  const selectedBillingAccount = validation?.paymentMethod;
  const selectedAddressKey = validation?.deliveryAddress;
  const selectedAddress = deliveryOptions?.find((option) => option.value === selectedAddressKey)?.title;
  const [addInstructionActive, setAddInstructionsActive] = useState(Boolean(validation?.instructions));
  const showPaymentButton = allowPaymentChanges && billingOptions && billingOptions.length > 0;
  const showAddressButton = allowAddressChanges && deliveryOptions && deliveryOptions.length > 0;
  const vehicleInfoBlock = renderVehicleInfoBlock(vehicle, externalBasket, t);
  const vehicleImageUrl = vehicle?.vehicleDetail?.imageUrl;

  const handleSetInstructions = useDebouncedCallback((value) => {
    dispatch(
      setValidationInstructionsSaga({
        vehicleKey,
        externalBasketId,
        instructions: value,
      }),
    );
  }, 1000);

  return (
    <ArticlesWrapper>
      <ArticleCard>
        <Flex direction={'row'}>
          <CarPicBackground>
            <CenterFlex>{renderCarPic(vehicle, vehicleImageUrl, externalBasketId)}</CenterFlex>
          </CarPicBackground>
          <Box width={15} />
          <Flex direction={'column'}>
            <Text type={'h2'} disableGutter>{`${referencesCount} ${
              vehicle
                ? `${t('order.verification.vin_articles', 'Articles for vehicle')}:`
                : externalBasketId
                ? `${t('order.verification.external_articles', 'External Articles')}`
                : `${t('order.verification.other_articles', 'Other Articles')}`
            }`}</Text>
            <Box height={5} />
            {vehicleInfoBlock}
            <Box height={5} />
            <SelectedItems>
              <Flex>
                {selectedAddress && (
                  <>
                    <Icon IconComponent={TruckIcon} size={20} color={theme.color.selected} />
                    <Box width={7} />
                    {selectedAddress}
                  </>
                )}
              </Flex>
              <Flex>
                {selectedBillingAccount && (
                  <>
                    <Icon IconComponent={CreditCardIcon} size={20} color={theme.color.selected} />
                    <Box width={7} />
                    {selectedBillingAccount}
                  </>
                )}
              </Flex>
            </SelectedItems>
            <Flex direction={'row'}>
              <Flex direction={'column-reverse'} size={2}>
                <Text
                  type={'text'}
                  displayStyle={'link'}
                  cursor={'pointer'}
                  onClick={() => setAddInstructionsActive(!addInstructionActive)}
                >
                  {t('order.verification.add_instruction', 'Add instructions')}
                </Text>
              </Flex>
              <Flex direction={'column-reverse'}>
                <MarginBox mt={-12}>
                  <Flex direction={'row-reverse'}>
                    {showAddressButton && (
                      <IconsBackground selected={Boolean(selectedAddress)}>
                        <MarginBox mt={10} ml={7}>
                          <Popover
                            content={
                              <Box width={270}>
                                <Select
                                  value={selectedAddress}
                                  onChange={(value, option) => {
                                    if (value)
                                      dispatch(
                                        setValidationDeliveryAddressSaga({
                                          vehicleKey,
                                          externalBasketId,
                                          deliveryAddress: option.value,
                                        }),
                                      );
                                    handleDeliveryAddressUpdate(vehicle, externalBasketId);
                                  }}
                                  onClear={() => {
                                    dispatch(
                                      resetValidationDeliveryAddressSaga({
                                        vehicleKey,
                                        externalBasketId,
                                      }),
                                    );
                                  }}
                                  placeholder={t(
                                    'order.validation.address.options.placeholder',
                                    'Select your delivery address',
                                  )}
                                  options={deliveryOptions}
                                  bordered
                                  allowClear
                                />
                              </Box>
                            }
                            trigger={'click'}
                            placement={'bottomRight'}
                          >
                            <Icon IconComponent={TruckIcon} size={20} color={selectedAddress ? 'white' : 'black'} />
                          </Popover>
                        </MarginBox>
                      </IconsBackground>
                    )}
                    <Box width={15} />
                    {showPaymentButton && (
                      <IconsBackground selected={Boolean(selectedBillingAccount)}>
                        <MarginBox mt={10} ml={10}>
                          <Popover
                            content={
                              <Box width={270}>
                                <Select
                                  value={selectedBillingAccount}
                                  onChange={(value) => {
                                    if (value)
                                      dispatch(
                                        setValidationPaymentMethodSaga({
                                          vehicleKey,
                                          externalBasketId,
                                          paymentMethod: String(value),
                                        }),
                                      );
                                    handlePaymentMethodUpdate(vehicle, externalBasketId);
                                  }}
                                  onClear={() => {
                                    dispatch(resetValidationPaymentMethodSaga({ vehicleKey, externalBasketId }));
                                  }}
                                  placeholder={t(
                                    'order.validation.payment.options.placeholder',
                                    'Select billing account',
                                  )}
                                  options={billingOptions}
                                  bordered
                                  allowClear
                                />
                              </Box>
                            }
                            trigger={'click'}
                            placement={'bottomRight'}
                          >
                            <Icon
                              IconComponent={CreditCardIcon}
                              size={20}
                              color={selectedBillingAccount ? 'white' : 'black'}
                            />
                          </Popover>
                        </MarginBox>
                      </IconsBackground>
                    )}
                  </Flex>
                </MarginBox>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </ArticleCard>
      <Box height={15} />
      {addInstructionActive && (
        <Input
          initialValue={validation?.instructions}
          maxLength={100}
          onChange={(value) => {
            handleSetInstructions(value);
          }}
          bordered
          placeholder={t('order.add_instruction', 'Add your instruction here...')}
        />
      )}
      <Box height={15} />
    </ArticlesWrapper>
  );
};

export default VerificationCard;
