import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ExternalBasketSectionLocal, ReferenceLocal, VehicleLocal } from 'domains/basket/Basket.types';
import {
  getExternalSectionValidation,
  getOtherSectionValidation,
  getVehiclesValidation,
  resetValidationDeliveryAddressSaga,
  resetValidationPaymentMethodSaga,
} from 'domains/orderValidation';
import VerificationCard from 'pages/CartPage/ValidationStep/VerificationCard';
import { Box, Flex, Grid, SelectOptionSingle, Text } from 'UI';
import { NotificationLink, notifyTop } from 'UI/Notification/notification';

interface VerificationSectionProps {
  vehicles: VehicleLocal[];
  billingOptions?: SelectOptionSingle[];
  deliveryOptions?: SelectOptionSingle[];
  otherReferences: ReferenceLocal[];
  externalBaskets: ExternalBasketSectionLocal[];
  allowPaymentChanges: boolean;
  allowAddressChanges: boolean;
}

const VerificationSection = ({
  vehicles,
  billingOptions,
  deliveryOptions,
  otherReferences,
  externalBaskets,
  allowPaymentChanges,
  allowAddressChanges,
}: VerificationSectionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vehiclesValidation = useSelector(getVehiclesValidation);
  const otherSectionValidation = useSelector(getOtherSectionValidation);
  const externalSectionValidation = useSelector(getExternalSectionValidation);

  const getPaymentAlertMessage = (vehicle: VehicleLocal | undefined) => {
    if (!vehicle) {
      return t(
        'order.verification.paymentMethodUpdate.otherArticles',
        'Your billing account has been updated for other articles',
      );
    }
    if (vehicle.vehicleDetail.vin) {
      return t(
        'order.verification.paymentMethodUpdate.vin',
        'Your billing account has been updated for vehicle VIN: {{updatedVehicle}}',
        { updatedVehicle: vehicle.vehicleDetail.vin },
      );
    }
    if (vehicle.vehicleDetail.dataHubVehicle) {
      return t(
        'order.verification.paymentMethodUpdate.bmm',
        'Your billing account has been updated for vehicle: {{updatedVehicle}}',
        {
          updatedVehicle: vehicle.vehicleDetail.dataHubVehicle.name + vehicle.vehicleDetail.dataHubVehicle.modelType,
        },
      );
    }
    return '';
  };

  const getAddressAlertMessage = (vehicle: VehicleLocal | undefined) => {
    if (!vehicle) {
      return t(
        'order.verification.deliveryAddressUpdate.otherArticles',
        'Your delivery address has been updated for other articles',
      );
    }
    if (vehicle.vehicleDetail.vin) {
      return t(
        'order.verification.deliveryAddressUpdate.vin',
        'Your delivery address has been updated for vehicle VIN: {{updatedVehicle}}',
        { updatedVehicle: vehicle.vehicleDetail.vin },
      );
    }
    if (vehicle.vehicleDetail.dataHubVehicle) {
      return t(
        'order.verification.deliveryAddressUpdate.bmm',
        'Your delivery address has been updated for vehicle: {{updatedVehicle}}',
        {
          updatedVehicle: vehicle.vehicleDetail.dataHubVehicle.name + vehicle.vehicleDetail.dataHubVehicle.modelType,
        },
      );
    }
    return '';
  };

  const handlePaymentMethodUpdate = (vehicle: VehicleLocal | undefined, externalBasketId: string | undefined) => {
    notifyTop(
      'success',
      getPaymentAlertMessage(vehicle),
      undefined,
      <NotificationLink
        onClick={() => {
          dispatch(
            resetValidationPaymentMethodSaga({
              vehicleKey: vehicle ? vehicle.vehicleDetail.vehicleKey : undefined,
              externalBasketId,
            }),
          );
        }}
      >
        {t('common.action.cancel', 'Cancel')}
      </NotificationLink>,
    );
  };
  const handleDeliveryAddressUpdate = (vehicle: VehicleLocal | undefined, externalBasketId: string | undefined) => {
    notifyTop(
      'success',
      getAddressAlertMessage(vehicle),
      undefined,
      <NotificationLink
        onClick={() => {
          dispatch(
            resetValidationDeliveryAddressSaga({
              vehicleKey: vehicle ? vehicle.vehicleDetail.vehicleKey : undefined,
              externalBasketId,
            }),
          );
        }}
      >
        {t('common.action.cancel', 'Cancel')}
      </NotificationLink>,
    );
  };

  return (
    <Flex direction={'column'} dataCy={'section-verification-order-validation'}>
      <Text type={'h1_banner_light'} dataCy={'title-verification-order-validation'}>
        {`3. ${t('order.verification', 'Verification and order validation')}`}
      </Text>
      <Box height={20} />
      <Text type={'section'}>
        {t(
          'order.verification.description',
          'Customize the delivery and payment options for your baskets and add ordering instructions.',
        )}
      </Text>
      <Box height={30} />
      <Grid columns={[1, 2]} gutter={2}>
        {vehicles?.map((vehicle: VehicleLocal) => (
          <VerificationCard
            key={vehicle.vehicleDetail.vehicleKey}
            vehicle={vehicle}
            billingOptions={billingOptions}
            deliveryOptions={deliveryOptions}
            handlePaymentMethodUpdate={handlePaymentMethodUpdate}
            handleDeliveryAddressUpdate={handleDeliveryAddressUpdate}
            allowPaymentChanges={allowPaymentChanges}
            allowAddressChanges={allowAddressChanges}
            validation={vehiclesValidation.find(
              (validationVehicle) => vehicle.vehicleDetail.vehicleKey === validationVehicle.vehicleKey,
            )}
          />
        ))}
        {externalBaskets.map((ext) => (
          <VerificationCard
            key={ext.externalBasketId}
            externalBasket={ext}
            references={ext.references}
            billingOptions={billingOptions}
            deliveryOptions={deliveryOptions}
            handlePaymentMethodUpdate={handlePaymentMethodUpdate}
            handleDeliveryAddressUpdate={handleDeliveryAddressUpdate}
            allowPaymentChanges={allowPaymentChanges}
            allowAddressChanges={allowAddressChanges}
            validation={externalSectionValidation.find(
              (validationExternalBasket) => ext.externalBasketId === validationExternalBasket.externalBasketId,
            )}
          />
        ))}
        {otherReferences && otherReferences.length > 0 && (
          <VerificationCard
            references={otherReferences}
            billingOptions={billingOptions}
            deliveryOptions={deliveryOptions}
            handlePaymentMethodUpdate={handlePaymentMethodUpdate}
            handleDeliveryAddressUpdate={handleDeliveryAddressUpdate}
            allowPaymentChanges={allowPaymentChanges}
            allowAddressChanges={allowAddressChanges}
            validation={otherSectionValidation}
          />
        )}
      </Grid>
    </Flex>
  );
};

export default VerificationSection;
