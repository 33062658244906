import React from 'react';
import { useDispatch } from 'react-redux';
import { Season } from '@1po/1po-bff-fe-spec/generated/tire/model/Season';
import styled from 'styled-components';
import { TireSeasonIcon } from 'components/TireIcons/TireSeasonIcon';
import TireReferencesPopup from 'components/TireReferencesPopup';
import { convertIAMTireSizeToSearchQuery, mapIAMTireUsageToSeason, toSearchParams } from 'domains/tires/Tire.mapper';
import { tireProductsSearchRequestSaga } from 'domains/tires/Tire.store';
import { SUMMER_WINTER } from 'domains/tires/Tire.types';
import { CatalogTireItemContainer } from 'pages/CatalogPage/DH/Tires/Tires.styled';
import { tireBrandsData } from 'pages/TiresPage/TireSearch/TiresSearchbar/TireBrandsData';
import { theme } from 'styles';
import { Box, Flex, MarginBox, Text } from 'UI';

const getTireInfo = (rimSize: string, tireSize: string) => {
  if (rimSize !== '*') {
    return `${tireSize} - ${rimSize}`;
  } else {
    return tireSize;
  }
};

const TextContainer = styled(Flex)<{
  sameSizeSection: boolean;
}>`
  ${({ sameSizeSection }) => sameSizeSection && `border-bottom: ${theme.color.grey_light} 1px solid`};
`;

interface TireItemProps {
  id: string;
  rimSize: string;
  tireSize: string;
  usage: string;
  isSelected: boolean;
  setSelectedTire: (tire: string | undefined) => void;
  sameSizeSection?: boolean;
}

export const IAMTireItem = ({
  id,
  rimSize,
  tireSize,
  usage,
  isSelected,
  setSelectedTire,
  sameSizeSection = false,
}: TireItemProps) => {
  const dispatch = useDispatch();
  const query = convertIAMTireSizeToSearchQuery(tireSize);
  const brands = tireBrandsData.map((brand) => brand.brandId);
  const season = mapIAMTireUsageToSeason(usage);
  const searchParamsBase64 = query ? toSearchParams(query, 'ALL' as Season, brands, '/').searchParamsBase64 : '';
  const shouldRenderIcon = season !== SUMMER_WINTER;

  return (
    <TireReferencesPopup
      id={id}
      searchParamsBase64={searchParamsBase64}
      handleSearch={() =>
        query &&
        dispatch(
          tireProductsSearchRequestSaga({
            query,
            season: 'ALL' as Season,
            brands,
            chargeIndicatorSeparator: '/',
          }),
        )
      }
      isSelected={isSelected}
      setSelectedTire={setSelectedTire}
      catalogSource={'IAM'}
    >
      <CatalogTireItemContainer
        direction={'row'}
        justify={'center'}
        align={'center'}
        minWidth={325}
        minHeight={55}
        maxHeight={55}
        selected={isSelected}
        sameSizeSection={sameSizeSection}
      >
        <Flex justify={'center'} maxWidth={50}>
          <MarginBox mx={15} mt={5}>
            {shouldRenderIcon ? <TireSeasonIcon season={season} /> : <Box width={50} />}
          </MarginBox>
        </Flex>
        <TextContainer
          align={'center'}
          justify={sameSizeSection ? 'center' : 'flex-start'}
          sameSizeSection={sameSizeSection}
          minHeight={55}
          maxHeight={55}
        >
          <Text type={'text'} displayStyle={isSelected && 'link'}>
            {getTireInfo(rimSize, tireSize)}
          </Text>
        </TextContainer>
        <MarginBox ml={15} />
      </CatalogTireItemContainer>
    </TireReferencesPopup>
  );
};
