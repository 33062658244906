/* eslint-disable max-len */
import React from 'react';
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/ReferenceBrandType';
import { VehicleBrandType } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { useTheme } from 'styled-components';
import { ReactComponent as AlpineLogo } from 'assets/CarBrand/brand_alpine.svg';
import { ReactComponent as ComingSoonLogo } from 'assets/CarBrand/brand_coming_soon.svg';
import { ReactComponent as DaciaLogo } from 'assets/CarBrand/brand_dacia.svg';
import { ReactComponent as MotrioLogo } from 'assets/CarBrand/brand_motrio.svg';
import { ReactComponent as RenaultLogo } from 'assets/CarBrand/brand_renault.svg';
import { ReactComponent as StandardExchangeLogo } from 'assets/CarBrand/brand_standard_exchange.svg';
import { ReactComponent as ValuePlusLogo } from 'assets/CarBrand/brand_value_plus.svg';
import { ReactComponent as IxellLogo } from 'assets/logos/brand_ixell.svg';
import { ReactComponent as IxtarLogo } from 'assets/logos/brand_ixtar.svg';
import { Icon } from 'UI';

export const BrandImage = ({
  referenceBrand,
  vehicleBrand,
  size = 121,
}: {
  referenceBrand?: ReferenceBrandType;
  vehicleBrand?: VehicleBrandType;
  size?: number;
}) => {
  const { color } = useTheme();

  const getVehicleBrandLogo = () => {
    switch (vehicleBrand) {
      case 'DACIA':
        return DaciaLogo;
      case 'RENAULT':
        return RenaultLogo;
      case 'ALPINE':
        return AlpineLogo;
      default:
        return ComingSoonLogo;
    }
  };

  const getIcon = () => {
    switch (referenceBrand) {
      case 'IAM':
      case 'CENTRAL': {
        return getVehicleBrandLogo();
      }
      case 'STANDARD_EXCHANGE': {
        return StandardExchangeLogo;
      }
      case 'VALUE_PLUS': {
        return ValuePlusLogo;
      }
      case 'MOTRIO': {
        return MotrioLogo;
      }
      case 'IXELL':
        return IxellLogo;
      case 'IXTAR':
        return IxtarLogo;
      default: {
        return getVehicleBrandLogo();
      }
    }
  };

  return <Icon IconComponent={getIcon()} background={color.shadow_7} size={size} dataCy={'reference-image'} />;
};
