import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FamilyReferences } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMFamilyReferencesResponse';
import { RootState } from 'app/AppStore';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { getIAMReferences, getPricesMap, ReferencePriceType } from 'domains/references';
import { useFetchPrices } from 'domains/references/References.requests';
import { getCurrency } from 'domains/user';
import { Box, capitalize, Flex, Text, WithTooltip } from 'UI';
import { getData, textFormatter } from 'utils';

export const ReferencePriceInfo = ({
  family,
  selectedReferences,
}: {
  family: FamilyReferences;
  selectedReferences: Map<string, string[]>;
}) => {
  const { t } = useTranslation();
  const refs = selectedReferences.get(family.id) ?? [];
  const prices = useSelector((state: RootState) => getPricesMap(state, refs));
  const pricesForRef = refs.map((r) => prices.get(r)).filter((r) => getData(r)) as ReferencePriceType[];
  const pricesTotal = pricesForRef.reduce((acc: number, next: ReferencePriceType) => {
    return acc + Number(next?.clientView?.recommendedPriceVatExcluded);
  }, 0);
  //use selector or get from prices?
  const currency = useSelector(getCurrency);
  const priceLabel = textFormatter.formatCurrency(pricesTotal, currency);
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const referencesRaw = useSelector((state: RootState) =>
    getIAMReferences(state, {
      referenceNumbers: refs,
      vehicleKey,
    }),
  );
  const references = getData(referencesRaw);
  useFetchPrices(refs, vehicleKey);

  return (
    <Box width={70}>
      {refs.length > 0 && (
        <Flex justify={'flex-end'}>
          <WithTooltip
            title={refs
              .map((ref) => {
                const refCatalog = references?.find((item) => item.referenceNumber === ref);
                return `${capitalize(
                  refCatalog?.brand?.toLowerCase() ?? refCatalog?.supplier?.toLowerCase() ?? '',
                )} - ${t('catalog.reference_card.reference_number', 'Ref:')} ${ref}`;
              })
              .join(',')}
          >
            <Text type={'link_bold'}>{priceLabel}</Text>
          </WithTooltip>
        </Flex>
      )}
    </Box>
  );
};
