import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import {
  fetchIAMAvailableModulesRequestSaga,
  fetchIAMServiceProposalsRequestSaga,
  setInitialState as setMaintenancePlanInitialState,
  setServiceProposalsStatus,
} from 'domains/maintenancePlan/MaintenancePlan.store';
import { getUserRights, UserRole } from 'domains/user';
import { getData, hasUserAnyRight } from 'utils';

export const useFetchIAMMaintenancePlan = (catalogVehicle?: VehicleDetail) => {
  const dispatch = useDispatch();
  const userRights = getData(useSelector(getUserRights));

  useEffect(() => {
    if (
      hasUserAnyRight([UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS], userRights)
    ) {
      dispatch(setMaintenancePlanInitialState());
    }
  }, [dispatch, catalogVehicle, userRights]);
};

export const useFetchIAMAvailableModules = (catalogVehicle?: VehicleDetail) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchIAMAvailableModulesRequestSaga());
  }, [dispatch, catalogVehicle]);
};

export const useFetchIAMServiceProposals = (
  catalogVehicle: VehicleDetail | undefined,
  registrationDate: string | undefined,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!registrationDate) {
      dispatch(
        setServiceProposalsStatus({
          status: undefined,
          vehicleKey: catalogVehicle?.vehicleKey,
          versionCode: catalogVehicle?.iamVehicle?.versionCode,
        }),
      );
      return;
    }
    dispatch(fetchIAMServiceProposalsRequestSaga({ registrationDate }));
  }, [dispatch, catalogVehicle, registrationDate]);
};
