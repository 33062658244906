/* eslint-disable max-len */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'app/AppStore';
import { useFetchIAMReferences } from 'domains/catalog/Catalog.requests';
import { getNodeIAMReferencesWrapperForVehicle, getSearchVehicleResult } from 'domains/catalog/Catalog.store';
import {
  IamCatalogBrandsType,
  IAMCatalogSourceParam,
  IAMLaborTimesWrapper,
  OEM_BRAND_KEY,
  OTHER_BRAND_KEY,
} from 'domains/catalog/Catalog.types';
import { getBannersCount } from 'domains/information/Information.store';
import { getUserCommercialLink, getUserCountry, getUserRights } from 'domains/user';
import OemBrandSection from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/OemBrandSection';
import OtherBrandSection from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/OtherBrandSection/OtherBrandSection';
import { BREADCRUMB_HEIGHT, WideTabs } from 'UI';
import { getCondArrayItem, getData, hasData, isOEMAllowed, NO_DATA } from 'utils';
import { OFFSET_LINK_TABS, OFFSET_VEHICLE_INFO } from 'utils/hooks/useOffsetTop';
import { scrollToElementSmooth } from 'utils/hooks/useResetScroll';

export interface SparePartsSectionProps {
  selectedIamCatalogView: IamCatalogBrandsType;
  setSelectedIamCatalogView: (type: IamCatalogBrandsType) => void;
  query: string;
  carPartGroupId: string;
  categoryId: string;
  subcategoryId: string;
  laborTimesWrapper: NO_DATA | IAMLaborTimesWrapper;
}

const SparePartsSection = ({
  selectedIamCatalogView,
  setSelectedIamCatalogView,
  query,
  subcategoryId: nodeId,
  laborTimesWrapper,
}: SparePartsSectionProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isBanner = useSelector((state: RootState) => getBannersCount(state, 'RENAULT_CATALOG')) > 0;
  const params = useMemo(() => new URLSearchParams(location.search), []);

  const vehicleResult = useSelector((state: RootState) => getSearchVehicleResult(state, query));
  const vehicleKey = vehicleResult?.vehicleDetail?.vehicleKey;
  const versionCode = vehicleResult?.vehicleDetail?.iamVehicle?.versionCode;

  const userCountry = useSelector(getUserCountry);
  const commercialLink = useSelector(getUserCommercialLink);
  const userRights = useSelector(getUserRights);
  const isOEMBrandTabAllowed = isOEMAllowed(commercialLink, userCountry, getData(userRights));

  const referencesWrapper = useSelector((state: RootState) =>
    getNodeIAMReferencesWrapperForVehicle(state, { nodeId, vehicleKey }),
  );

  useEffect(() => {
    const subcategoryElement = document.getElementById('subcategory_section_container');
    if (subcategoryElement) {
      scrollToElementSmooth(subcategoryElement, isBanner, BREADCRUMB_HEIGHT + OFFSET_LINK_TABS + OFFSET_VEHICLE_INFO);
    }
  }, [history.location.pathname, history, params, isBanner]);

  useFetchIAMReferences(
    versionCode,
    vehicleKey,
    nodeId,
    referencesWrapper?.oemReferences,
    referencesWrapper?.otherBrandReferences,
  );

  const tabData = [
    {
      title: t('catalog.parts.category.other_brand', 'Other Brand'),
      key: OTHER_BRAND_KEY,
      component: (
        <OtherBrandSection
          referencesWrapper={referencesWrapper?.otherBrandReferences}
          nodeId={nodeId}
          laborTimesWrapper={laborTimesWrapper}
        />
      ),
      disabled: selectedIamCatalogView !== OTHER_BRAND_KEY && !hasData(referencesWrapper?.otherBrandReferences),
    },
    ...getCondArrayItem(
      isOEMBrandTabAllowed && {
        title: t('catalog.parts.category.oem_brands', 'OEM brands'),
        key: OEM_BRAND_KEY,
        component: (
          <OemBrandSection
            referencesWrapper={referencesWrapper?.oemReferences}
            nodeId={nodeId}
            laborTimesWrapper={laborTimesWrapper}
          />
        ),
        disabled: selectedIamCatalogView !== OEM_BRAND_KEY && !hasData(referencesWrapper?.oemReferences),
      },
    ),
  ];

  return (
    <WideTabs
      activeKey={selectedIamCatalogView}
      tabData={tabData}
      onChange={(tabKey) => {
        setSelectedIamCatalogView(tabKey as IamCatalogBrandsType);
        params.set(IAMCatalogSourceParam, tabKey);
        history.push(`${location.pathname}?${params}`);
      }}
      isActiveBlue
    />
  );
};

export default SparePartsSection;
