import { Card } from 'antd';
import styled from 'styled-components';

export const ArticleCard = styled(Card)`
  width: 450px;
  min-height: 175px;
  border: solid 1px ${({ theme }) => theme.color.grey85};
  border-radius: unset;

  .ant-card-body {
    padding: 15px 15px 15px 15px;
  }
`;

export const IconsBackground = styled.div<{ selected: boolean }>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${({ selected, theme }) => (selected ? theme.color.green_dark : theme.color.grey95)};
  cursor: pointer;

  :hover {
    transform: scale(1.2);
  }
`;

export const CarPicBackground = styled.div`
  width: 145px;
  height: 145px;
  background-color: ${({ theme }) => theme.color.grey95};
`;

export const SelectedItems = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${({ theme }) => theme.color.selected_dark};
`;

export const ArticlesWrapper = styled.div`
  width: 450px;
`;
