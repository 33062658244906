import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { RootState } from 'app/AppStore';
import { InfoIcon } from 'assets/icons';
import { InfoDialog, useDisclosure } from 'components/Dialog';
import { get750ChunkReferences, getChunkReferences, getOilLitersPerRef } from 'domains/appContext/AppContext.store';
import { ExternalBasketSectionLocal, ReferenceLocal, VehicleLocal } from 'domains/basket/Basket.types';
import { getStocksMap } from 'domains/references';
import { Box, CenteredSpin, DarkCard, Divider, Flex, MarginBox, Text, YellowButton } from 'UI';
import { FOUND, hasData, SearchData } from 'utils';
import { textFormatter } from 'utils/format';
import { CheckoutCardWrapper } from './CheckoutCardSection.styled';

const MINIMUM_CHECKOUT_OIL_QUANTITY = 200;

interface CheckoutCardProps {
  totalPriceVatIncluded: number | string;
  totalPriceVatExcluded: number | string;
  totalDiscount: number;
  totalVat: number | string;
  vehicles: VehicleLocal[];
  externalBaskets: ExternalBasketSectionLocal[];
  otherReferences?: ReferenceLocal[];
  currency: string;
  onCheckout: () => void;
  width?: number;
  loading?: boolean;
  disabledCheckout?: boolean;
  validationStep?: boolean;
}

function allReferencesAreInR1Stock(
  refs: ReferenceLocal[],
  stocks: Map<string, SearchData<ReferenceStock> | undefined>,
) {
  return refs.every((ref) => {
    const referenceStock = stocks.get(ref.referenceNumber);
    if (!hasData(referenceStock) || referenceStock.searchStatus !== FOUND || !referenceStock.data) {
      return false;
    }
    return referenceStock.data.warehouses.some((wh) => {
      return wh.type === 'LOCAL' && wh.confirmedQuantity >= ref.quantity;
    });
  });
}

const CheckoutCardSection = ({
  totalPriceVatIncluded,
  totalPriceVatExcluded,
  totalVat,
  vehicles,
  externalBaskets,
  otherReferences,
  currency,
  onCheckout,
  loading = false,
  disabledCheckout = false,
  validationStep,
  totalDiscount,
}: CheckoutCardProps) => {
  const { t } = useTranslation();
  const disclosure = useDisclosure();
  const chunkReferences = useSelector(getChunkReferences);
  const chunk750References = useSelector(get750ChunkReferences);
  const oilLiters = useSelector(getOilLitersPerRef);
  const { onOpen, onClose } = disclosure;
  const oilReferences =
    otherReferences?.filter(
      (ref) => chunkReferences.get(ref.referenceNumber) || chunk750References.includes(ref.referenceNumber),
    ) ?? [];
  const oilReferenceNumbers = oilReferences.map((ref) => ref.referenceNumber);

  const oilStocks = useSelector((state: RootState) => getStocksMap(state, oilReferenceNumbers ?? []));

  const itemsCount =
    vehicles?.reduce(
      (acc: number, next: VehicleLocal) => (next && next.references ? acc + next.references.length : acc),
      0,
    ) +
    externalBaskets?.reduce(
      (acc: number, next: ExternalBasketSectionLocal) => (next && next.references ? acc + next.references.length : acc),
      0,
    ) +
    (otherReferences?.length || 0);

  const isCheckoutForOilsAllowed = () => {
    if (oilReferences.length === 0 || allReferencesAreInR1Stock(oilReferences, oilStocks)) {
      return true;
    }

    const oilRefQtyTotal =
      oilReferences.reduce((total, ref) => {
        const literPerRef = oilLiters.get(ref.referenceNumber) ?? 1;
        return total + ref.quantity * literPerRef;
      }, 0) ?? 0;

    return oilRefQtyTotal >= MINIMUM_CHECKOUT_OIL_QUANTITY;
  };

  const checkoutTitleLabel = `${t('common.price.total', 'Total')} : (${itemsCount} ${t(
    'catalog.checkout_card.total.item',
    {
      defaultValue: 'items',
      count: itemsCount,
    },
  )})`;

  const handleCheckOut = () => {
    if (isCheckoutForOilsAllowed()) {
      onCheckout();
    } else {
      onOpen();
    }
  };

  return (
    <CheckoutCardWrapper>
      <InfoDialog
        title={'Action required'}
        handleConfirm={() => undefined}
        disclosure={disclosure}
        icon={InfoIcon}
        description={[
          t(
            'catalog.universal_products.basket.minimum_quantity.notification.first_line',
            'Minimum order quantity of 200L is not reached for Castrol Oil reference(s).',
          ),
          t(
            'catalog.universal_products.basket.minimum_quantity.notification.second_line',
            'Please adjust quantities and try again.',
          ),
        ]}
        content={
          <Flex minWidth={400} direction={'row-reverse'}>
            <YellowButton onClick={onClose}>
              <Flex minWidth={80} justify={'center'}>
                {t('common.action.confirm', 'Confirm')}
              </Flex>
            </YellowButton>
          </Flex>
        }
      />
      <DarkCard title={checkoutTitleLabel} dataCy={'table-total'}>
        <MarginBox mx={20}>
          <Flex direction={'row'}>
            <Text type={'section'} dataCy={'total-vat-excl'}>{`${t(
              'common.price.total_VAT_excl',
              'Total VAT. Excl',
            )}:`}</Text>
            <Flex />
            <Text type={'section'}>{textFormatter.formatCurrency(totalPriceVatExcluded, currency)}</Text>
          </Flex>
          {totalDiscount > 0 && (
            <Flex direction={'row'}>
              <Text type={'light_14_bold_red'}>
                {`${t('common.price.total_discount_VAT_excl', 'Total discount VAT. Excl')}:`}
              </Text>
              <Flex />
              <Text type={'light_14_bold_red'}>{textFormatter.formatCurrency(totalDiscount, currency)}</Text>
            </Flex>
          )}
          <Box height={7} />
          <Flex direction={'row'}>
            <Text type={'section'} dataCy={'vat'}>
              {t('common.price.vat', 'VAT:')}
            </Text>
            <Flex />
            <Text type={'section'}>{textFormatter.formatCurrency(totalVat, currency)}</Text>
          </Flex>
          <Divider />
          <Flex direction={'row'} dataCy={'total-vat-incl'}>
            <Text type={'price_tag'}>{`${t('common.price.total_vat_incl', 'Total VAT. Incl')}:`}</Text>
            <Flex />
            <Text type={'price_tag'}>{textFormatter.formatCurrency(totalPriceVatIncluded, currency)}</Text>
          </Flex>
          {!validationStep && (
            <>
              <MarginBox mt={15} />
              <Flex direction={'row'} justify={'center'}>
                {loading ? (
                  <CenteredSpin />
                ) : (
                  <YellowButton onClick={handleCheckOut} disabled={disabledCheckout} dataCy={'button-checkout'} stretch>
                    {t('cart.action.checkout', 'Check-out')}
                  </YellowButton>
                )}
              </Flex>
            </>
          )}
        </MarginBox>
      </DarkCard>
      {validationStep && (
        <>
          <Box height={30} />
          <Flex direction={'row'} justify={'center'}>
            {loading ? (
              <CenteredSpin />
            ) : (
              <YellowButton onClick={handleCheckOut} disabled={disabledCheckout} dataCy={'button-buy'} stretch>
                {t('cart.action.buy', 'Buy')}
              </YellowButton>
            )}
          </Flex>
        </>
      )}
    </CheckoutCardWrapper>
  );
};

export default CheckoutCardSection;
