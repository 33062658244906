import { CommercialLink, DealerType } from '@1po/1po-bff-fe-spec/generated/user/model/CommercialLink';
import { UserRole } from 'domains/user';
import { Country } from 'utils/i18n/Country';

export const hasUserAnyRight = (possibleRights: UserRole[], userRights?: UserRole[]): boolean => {
  if (possibleRights.length === 0) {
    return true;
  }
  return possibleRights.some((pr) => userRights?.includes(pr));
};

export const doesDealerTypeMatch = (possibleDealerTypes: DealerType[], dealerType?: DealerType): boolean => {
  if (possibleDealerTypes.length === 0) {
    return true;
  }
  return possibleDealerTypes.some((pd) => dealerType === pd);
};

export const doesUserBelongToCountry = (possibleCountries: Country[], userCountry?: Country): boolean => {
  if (possibleCountries.length === 0) {
    return true;
  }
  return possibleCountries.some((pc) => userCountry?.key === pc.key);
};

export const isOEMAllowed = (
  commercialLink: CommercialLink | null,
  userCountry: string | undefined,
  userRights?: UserRole[],
): boolean => {
  return (
    [Country.FR.key, Country.IT.key, Country.ES.key].includes(commercialLink?.countryCode ?? userCountry ?? '') &&
    hasUserAnyRight(
      [
        UserRole.IAM_LIGHT_ACCESS,
        UserRole.IAM_ACCESS,
        UserRole.IAM_FAST_ACCESS,
        UserRole.IAM_PRO_ACCESS,
        UserRole.IAM_PRO_PLUS_ACCESS,
      ],
      userRights,
    )
  );
};

export const hasUserR3WithSubscriptionL234 = (userRights?: UserRole[]): boolean => {
  return (
    hasUserAnyRight([UserRole.R3, UserRole.R3_MOTRIO], userRights) &&
    hasUserAnyRight([UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS], userRights)
  );
};

export const hasUserR3WithLimitedAccessSubscriptionL01 = (userRights?: UserRole[]): boolean => {
  return (
    hasUserAnyRight([UserRole.R3, UserRole.R3_MOTRIO], userRights) &&
    hasUserAnyRight([UserRole.NO_IAM_ACCESS, UserRole.IAM_LIGHT_ACCESS, UserRole.IAM_ACCESS], userRights)
  );
};
