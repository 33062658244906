import styled from 'styled-components';
import { theme } from 'styles';
import { Flex } from 'UI';

export const FamilyCard = styled(Flex)<{ active: boolean; data?: boolean }>`
  box-shadow: 0 5px 12px 4px rgba(0, 0, 0, 0.09), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 2px -2px rgba(0, 0, 0, 0.16);
  background-color: ${({ active }) => (active ? theme.color.info : theme.color.white)};
  padding: 0 15px 0 0;
  margin: 0 0 10px 0;

  :hover {
    cursor: ${({ data }) => (data ? 'initial' : 'pointer')};
  }
`;

export const LabelFamily = styled.div<{
  width?: number;
  height?: number;
  active?: boolean;
}>`
  display: inline-block;
  width: ${({ width }) => (width ? width : 200) + 'px'};
  height: 54px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 35px;
  padding-top: 15px;
  margin-left: 0;
  margin-right: 5px;
  background-color: ${({ theme, active }) => (active ? theme.color.ice_blue_light_110 : theme.color.white)};

  :hover {
    background-color: ${({ theme, active }) => (active ? theme.color.grey95 : theme.color.white)};
  }
`;

export const SelReferenceCard = styled(Flex)`
  width: 200px;
  max-width: 200px;
  height: 46px;
  border-radius: 5px;
  box-shadow: 0 5px 12px 4px rgba(0, 0, 0, 0.09), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 2px -2px rgba(0, 0, 0, 0.16);
  background-color: ${theme.color.grey65};
  padding: 10px 5px 5px 10px;
  margin: 0 0 10px 30px;

  :hover {
    cursor: pointer;
  }
`;

export const BlueTipCard = styled(Flex)`
  max-width: 210px;
  height: 46px;
  border-radius: 5px;
  box-shadow: 0 5px 12px 4px rgba(0, 0, 0, 0.09), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 2px -2px rgba(0, 0, 0, 0.16);
  background-color: ${theme.color.clear_blue};
  padding: 10px 5px 5px 10px;
  margin: 0 0 10px 0;

  :hover {
    cursor: pointer;
  }
`;

export const GreySpacer = styled(Flex)`
  background-color: ${theme.color.grey_04};
  min-height: 15px;
`;
